import React, { useState, useEffect } from "react";
import { options } from "../../Dashboard/DashboardHeader/DashboardHeader.component";
import DashboardInputComponent from "../../Dashboard/DashboardHeader/DashboardInput.component";
import FilterComponent from "../../Dashboard/DashboardHeader/Filter.component";
import InputComponent from "../../Input/Input.component";
import ActivityLogComponent from "../../MyOkr/MyOkrTab/ActivityLog.component";
import { Header } from "../../Settings/SettingsContent/SettingContent.style";
import { AllLogContainer } from "./AllLog.style";

// Logs page component

const AllLogsComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const [checkIn, setCheckInData] = useState([]); //store original data
  const [checkInTemp, setCheckInDataTemp] = useState([]); //store filtered data
  const [userFilter, setUserFilter] = useState({});
  const [deptFilter, setDeptFilter] = useState({});

  useEffect(() => {
    setCheckInData(props.criticalOkrData || []);
    setCheckInDataTemp(props.criticalOkrData || []);
    setLoading(false);
  }, []);

  const filterCheckIn = (event) => {
    const filteredCheckIn = checkIn.filter((check) => {
      return check?.title
        ?.toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setCheckInDataTemp(filteredCheckIn);
  };

  //filter logs
  useEffect(() => {
    console.log(userFilter);
    console.log(deptFilter);
  }, [userFilter, deptFilter]);

  return (
    <AllLogContainer>
      {/* Header */}
      <Header className="no-ms">
        {/*search logs*/}
        <InputComponent
          pLeft="15px"
          onChange={filterCheckIn}
          search
          placeholder="Search"
        />

        <Header className="no-ms">
          {/*<DashboardInputComponent
            name="dept"
            value={deptFilter.dept || "all"}
            placeholder="Select Dept"
          >
            <FilterComponent
              name="dept"
              options={[
                "All",
                ...props.aspirationalOkrData.map((item) => item.name),
              ]}
              id={["all", ...props.aspirationalOkrData.map((item) => item._id)]}
              formData={deptFilter}
              setFormData={setDeptFilter}
            />
          </DashboardInputComponent>*/}
          {/*<DashboardInputComponent name="team" value={filter.team || "all"} placeholder="Select Team">
            <FilterComponent  options={options} formData={filter} setFormData={setFilter} />
  </DashboardInputComponent>


          <DashboardInputComponent
            value={userFilter.user || "all"}
            placeholder="Select Users"
          >
            <FilterComponent
              name="user"
              options={["All", ...props.allUser.map((item) => item.name)]}
              id={["all", ...props.allUser.map((item) => item._id)]}
              formData={userFilter}
              setFormData={setUserFilter}
            />
</DashboardInputComponent>*/}
          {/** <DashboardInputComponent placeholder='View By' lg>
            <FilterComponent name='ViewBy' options={options} date />
          </DashboardInputComponent>
          */}
        </Header>
      </Header>

      {/* Component displays check in colloboration logs */}
      <ActivityLogComponent loading={loading} checkInData={checkInTemp} />
    </AllLogContainer>
  );
};

export default AllLogsComponent;
