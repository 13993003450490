import styled from 'styled-components';
export const SidebarContainer=styled.div`
// transition:0.3s;
${({show})=>show&&`

position:fixed;
top:50%;
transform:translateY(-50%);

width:362px;
left:70px;
background-color:#fff;
z-index:100;
box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
border-radius: 15px;
padding:15px;
max-height:584px;
.wraper{
    position:relative;
}
.dropdown button{
    border-radius:30px;
    border-color:#51E3D4;
}
.dropdown button:hover{
    border-color:#51E3D4;
}
.input{
    display:block;
    width:100%;

    border:none;
    border-bottom:1px solid grey;
    padding:5px 15px;
    font-size:14px;
}
.input:focus{
    outline:none;
}



`}


`
export const SidebarContent=styled.div`
display:flex;
justify-content:space-between;
margin-bottom:10px;




`
export const UserContainer=styled.div`
overflow:auto;


`
export const ToogleButton=styled.div`
position:absolute;

bottom:50%;
display:flex;
align-items:center;


height: 40px;
width: 80px;
border-radius: 60px 60px 0 0;
cursor:pointer;
background: #5CE0D2;
transform: rotate(90deg) translateY(40px) translateX(35%);
// transition:0.3s;

${({show})=>show&&`
left:100%;
bottom:50%;
transform: rotate(90deg) translateY(10px) translateX(35%);

`}







`

export const UserDetails=styled.div`
display:flex;
height:54px;

.ftDiv{
    flex:0.2;
    display:flex;
    align-items:center;
}
.stDiv{
    flex:0.6;
    display:flex;
    align-items:center;
}
.radioButton{
    height:18px;
    width:18px;
    border:2px solid #5CE0D2;
    border-radius:50%;
}
.thddiv{
    flex:0.2;
    display:flex;
    justify-content:center;
    align-items:center;
    
}

`

export const FilterDiv=styled.div`
width:auto;
max-width:150px;
.show>.btn-primary.dropdown-toggle{
    background-color:rgba(75, 223, 63, 0.51);
}
.dropdown button{
    background-color:rgba(75, 223, 63, 0.51);
    font-weight:600;
    border-radius:10px;
}

`


export const WrapperDiv=styled.div`

.sc-hYQoXb{
    width:100%;
    display:block;
}
button{
    width:100%;
    background-color:#fff;
    border:1px solid black;
    padding:5px 2px;
    border-radius:4px;
}
.dropdown{
    position:relative;
}
.dropdown-menu{
   
    background-color:#fff;
    box-shadow:1px 2px 5px grey;
    top: 200%;
    left: 0;
    right: 0;
    display: none;

    z-index:100;
    width:100%;
}
.show{
   
    display:block;
    top: 200%;
   width:100%;

}



`
export const Option=styled.div`

padding:3px 6px;
text-align:center;
cursor:pointer;
font-size:12px;
&:hover{
    // opacity:0.8;
    background-color:#dbdbdb;
}

`