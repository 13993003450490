import React, { useState } from 'react';
import { Text } from '../../../utils/shared.style';
import { Line } from '../../Dashboard/Objective/Objective.style';
import { Browse, Button } from '../../MyOkr/NewObjective/NewObjective.style';
import CloseReqComponent from '../../Svgs/CloseReq.component';
import * as XLSX from 'xlsx';
import UploadFileSvg from '../../Svgs/UploadFileSvg.component';
import { useAuth } from '../../../context/Auth';
import axios from 'axios';
import { User } from '../../../api/Api';
import "react-toastify/dist/ReactToastify.css";
import {
  ModalContainer,
  Modal,
  Header,
  Container,
} from './SettingContent.style';
import { toast } from 'react-toastify';
import Loader from '../../Loading/Loader';

const UploadComponent = ({ modal, onClick }) => {
  toast.configure();
  const { idToken, uid } = useAuth()
  const [file, setFile] = useState()
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const fileUploadHandler = (event) => {
    const file_t = event.target.files[0];

    var reader = new FileReader();
    setFileName(event.target.files[0].name);
    reader.onload = (e) => {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setFile(dataParse);
    };
    reader.readAsBinaryString(file_t);

  }

  const handleSubmit = async (event) => {
    // event.preventDefault();
    console.log(file);
    setLoading(true);

    for (let i = 2; i < file.length; i++) {
      await axios.post(
        User.registerUser, {
        name: file[i][0],
        email: file[i][1],
        reportingto: file[i][2],
        department: file[i][3],
        role: file[i][4],
      }, {
        headers: {
          Authorization: `${idToken}`,
          uuid: uid
        }


      }
      ).then((res) => {
        console.log(res);
        toast.success("user Uploaded", { autoClose: 3000 })
      }).catch(err => {
        if (err.response) {
          console.log(err.response);
          // alert(err.response.data.message);
          toast.error(err?.response?.data?.message, { autoClose: 3000 });
        }
      })
    }
    setLoading(false);
    onClick();
  }
  console.log(loading);
  return (
    <Modal modal={modal}>
      <ModalContainer>
        <Header className='mbb'>
          <Text bold>Upload From Files</Text>
          <div onClick={onClick}>
            <CloseReqComponent />
          </div>
        </Header>
        <Container className='mbb'>
          <UploadFileSvg />
          <Text bold>Drag and Drop here</Text>
          <Text light>OR</Text>
          <label className="upload" for="files">{fileName || "Browse Files"}</label>
          <input onChange={fileUploadHandler} id="files" style={{ visibility: "hidden" }} type="file" />
        </Container>
        <Line className='mbb' />
        <Browse>
          <Button onClick={onClick}>Cancel</Button>
          <Button onClick={handleSubmit} main>Upload</Button>
        </Browse>
      </ModalContainer>
      {loading && <Loader type="spin" color="cyan" />}
    </Modal>
  );
};

export default UploadComponent;
