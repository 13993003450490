import styled from 'styled-components';
import {
  myFlex,
  flexColumn,
  boxShadow,
  flexCenter,
  TextCss,
} from '../../../utils/shared.style';

export const DepartmentContainer = styled.section`
  ${myFlex};
  ${flexColumn};
  ${boxShadow};
  width: 20%;
  padding: 1rem;
  padding-left:0px;
  
  min-height: 80vh;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    height: fit-content;
    width: 90%;
    margin: 2rem 0.5rem;
  }
`;

export const Circle = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: ${(props) => props.bg};
  ${myFlex};
  ${flexCenter}
`;

export const Text = styled.p`
  ${TextCss};
  color: ${(props) => props?.color};
`;
