import React from 'react';
import { Line } from '../Dashboard/Objective/Objective.style';
import UserComponent from '../Svgs/User.component';
import { useAuth } from '../../context/Auth';
import {
  Circle,
  Meta,
  ProfileContainer,
  ProfileDetails,
  ProfileHeader,
  ProfileWrapper,
} from './Header.style';

/**
 * User Profile Popup
 *
 */

const ProfileComponent = (props) => {
  console.log(props);
  const {signOutUser}=useAuth();
  return (
    <ProfileContainer>
      <ProfileWrapper>
        <ProfileHeader>My Profile</ProfileHeader>
        {/* User Image  */}
        <ProfileDetails>
          <Circle>
            <UserComponent className='no-mr' />
          </Circle>
          {/* User Detail */}
          <div>
            <ProfileHeader>{props.user?props.user.name:"John"}</ProfileHeader>
            <Meta>Level</Meta>
            <Meta>{props.user?props.user.role:"level 1"}</Meta>
          </div>
        </ProfileDetails>
        <Line />
        <Meta>Change Password</Meta>
        <Meta style={{cursor:"pointer"}} onClick={()=>{signOutUser()}}>Logout</Meta>
      </ProfileWrapper>
    </ProfileContainer>
  );
};

export default ProfileComponent;
