import React,{useState} from 'react';
import { LogInFormContainer,FormLabel,InputField,LoginButton } from './Login.style';
import Register from './Register';
function LogInFormComponent(props) {
    const {formData,OnChange,OnClick}=props;
    const [register,setRegister]=useState(false);
    return (
        <>
        {!register?<div>
        <LogInFormContainer>
            <h1 style={{letterSpacing:".4rem"}}>S<i style={{marginLeft:"-0.4rem"}}>2</i>E PORTAL</h1>
         
                <FormLabel >
                haven't registered yet ? <span style={{color:"cyan",cursor:"pointer"}} onClick={()=>setRegister(true)} >Click here</span>
                </FormLabel>
           

        </LogInFormContainer>
            
        <LogInFormContainer>
            <h1>Log in</h1>
        </LogInFormContainer>
            
        <LogInFormContainer>
            <FormLabel>
                Username
            </FormLabel>
             <InputField value={formData.email} name="email" onChange={(e)=>OnChange(e)} placeholder="Email address"/>
             
            
        </LogInFormContainer>
            
        <LogInFormContainer>
        <FormLabel>
            Password
        </FormLabel>
            <InputField type="password" value={formData.password} name="password" onChange={(e)=>OnChange(e)}  placeholder="Password"/>


            

        </LogInFormContainer>
        <LogInFormContainer>
            <LoginButton onClick={()=>OnClick()}>
            Log In
            </LoginButton>
            
        </LogInFormContainer>
        </div> :<Register setRegister={setRegister}/>
                   }
            </>

    )
}

export default LogInFormComponent;
