import React, { useContext, useState,useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { ModalContext } from "../../../context/Modal.context";
import MenuComponent from "../../Svgs/Menu.component";
import navItems from "./helper";
import { Icon, MainNavbar, MainNavbarChild } from "./Navbar.style";
import BackArrowComponent from "../../Svgs/BackArrow.component";
import { Text } from "./Navbar.style";
import {useAuth} from '../../../context/Auth';

const NavbarComponent = ({page}) => {
  const {admin}=useAuth();
  
  const history = useHistory();
  const modal = useContext(ModalContext);
  const [showText, setShowText] = useState(false);
 

  return (
    <MainNavbar page={page} modal={modal.open}>
      <MainNavbarChild>
        <Icon>
          {!showText ? (
            <MenuComponent showText={showText} setShowText={setShowText} />
          ) : (
            <BackArrowComponent showText={showText} setShowText={setShowText} />
          )}
        </Icon>
        {navItems(admin).map(({ Component, path, name }, idx) => {
          
          return (admin||(idx!==2&&idx!==4))?<Link to={path} key={idx} style={{ textDecoration: "none" }}>
            <Icon
              active={history.location.pathname === path}
              onClick={modal.toggle}
            >
              <Component />
              
              <Text> {showText && name}</Text>
            </Icon>
          </Link>:""
        })}
      </MainNavbarChild>
    </MainNavbar>
  );
};

export default NavbarComponent;
