import React from 'react';
import OptionComponent from './Option.component';

const OptionGroupComponent = ({ id, name, data, handleOnChange }) => {
    console.log("Group Option");
    console.log(data);
    return (<div style={{borderBottom:"0.5px solid grey"}}>
        <span style={{ margin: "20px 0 0 10px", fontSize:"1.3rem" }}>{data.label}</span><br />
        {data.options.map((option, idx) => {
            return <><OptionComponent
            style={{fontSie:"1.2rem"}}
                handleOnChange={handleOnChange}
                id={id}
                name={name}
                option={option}
                key={idx}
            />
                <br />
            </>
        })}
    </div>);
};

export default OptionGroupComponent;