import React,{useEffect,useState} from 'react';
import { Text } from '../../../utils/shared.style';
import { Line } from '../../Dashboard/Objective/Objective.style';
import InputComponent from '../../Input/Input.component';
import {User} from '../../../api/Api';
import axios from 'axios';
import { useAuth } from '../../../context/Auth';
import  DashboardInputComponent from '../../Dashboard/DashboardHeader/DashboardInput.component';
import FilterComponent from '../../Dashboard/DashboardHeader/Filter.component';  
import {
  Browse,
  Button,
  InputContainer,
} from '../../MyOkr/NewObjective/NewObjective.style';
import TabComponent from '../../MyOkr/NewObjective/Tab.component';
import CloseReqComponent from '../../Svgs/CloseReq.component';
import {
  ModalContainer,
  Modal,
  Header,
  TableHeader,
  Icon,
} from './SettingContent.style';

export const SharedModal = ({
  modal,
  onClick,
  tab1,
  tab2,
  tab1Input,
  tab2Input,
  createNewDept,
  newDept,
  setNewDept,
  handleOnChange,
  allUser=[],
  

}) => (
  <Modal modal={modal}>
    <ModalContainer>
      <Header className='no-ms mbb'>
        <Text bold>{tab1}</Text>
        <div onClick={onClick}>
          <CloseReqComponent />
        </div>
      </Header>
      <TabComponent header='Details' browse={false}>
        <InputContainer lg>
          <Text light>{tab1Input}</Text>
          <InputComponent name="name" onChange={(e)=>handleOnChange(e)} width='90%' placeholder='eg' />
        </InputContainer>
        <Header className='mr-2'>
          {/* <InputContainer>
            <Text light>Owner</Text>
            <InputComponent name="owner" onChange={(e)=>handleOnChange(e)} width='90%' placeholder='eg' />
          </InputContainer> */}
          <InputContainer style={{width:"200px"}}>
          <DashboardInputComponent  placeholder="Select" value={newDept?.owner}>
            <FilterComponent name="owner" formData={newDept} setFormData={setNewDept} id={[...allUser?.map((item)=>item.uuid)]}  options={[...allUser?.map((item)=>item.name)]} />
          </DashboardInputComponent>
          </InputContainer>
          <InputContainer>
            <Text light>Type</Text>
            <InputComponent name="type" value="Department" onChange={(e)=>handleOnChange(e)}  width='90%' placeholder='eg' />
          </InputContainer>
        </Header>
      </TabComponent>
     
      <Browse style={{marginTop:"200px"}} className='mtt'>
        <Button className='mr-1' onClick={onClick}>Cancel</Button>
        <Button main onClick={()=>{createNewDept()}}>Create</Button>
      </Browse>
    </ModalContainer>
  </Modal>
);

const NewDeptComponent = ({ modal, onClick,createNewDept,newDept,setNewDept }) => {
  const {uid,idToken}=useAuth()
  const [allUser,setAllUser]=useState([]);
  useEffect(()=>{
    axios.get(User.getAllUser,{
      headers:{
        Authorization:`${idToken}`,
        uuid:`${uid}`
      }
    }).then(res=>{console.log(res);setAllUser(res.data.users)}).catch(err=>console.log(err))
  },[])
  
  const handleOnChange=(e)=>{
    let name=e.target.name;
    let val=e.target.value;
    setNewDept({...newDept,[name]:val});
  }
  
  return (
    <SharedModal
      tab1='Create New Department'
      tab2='Align Teams'
      tab1Input='New Department'
      modal={modal}
      onClick={onClick}
      createNewDept={createNewDept}
      newDept={newDept}
      setNewDept={setNewDept}
      allUser={allUser}
      handleOnChange={handleOnChange}
      
    />
  );
};

export default NewDeptComponent;
