import React from 'react';

const ExpandComponent = (props) => {
  return (
    <svg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.758 4.388H1V1h3.616v.748H1.758v2.64zM13.867 4.388h-.758v-2.64h-2.605V1h3.363v3.388zM4.616 13.869H1V10.48h.758v2.64h2.858v.748zM13.867 13.869h-3.363v-.749h2.605v-2.64h.758v3.389z'
        fill='#1F1F1F'
        stroke='#1F1F1F'
        strokeMiterlimit='10'
        {...props}
      />
    </svg>
  );
};

export default ExpandComponent;
