import React, { useState, useEffect } from "react";
import CommonHeaderComponent from "../Objective/CommonHeader.component";
import CheckInsComponent from "./CheckIns.component";
import CollaborationComponent from "./Collaboration.component";
import NewOkrComponent from "./NewOkr.component";
import { RequestContainer, TabPanel, Tab } from "./Request.style";
import { FloatingRequestComponent } from "./Request.style";
import RequestFloatingComponent from "../../Svgs/Request.component";
import RequestHoverComponent from "../../Svgs/RequestHover.component";
import { RequestWrapper } from "./Request.style";
import useFullScreen from "../../../hooks/useFullScreen";
import UnpinComponent from "../../Svgs/Unpin.component";
import { UnpinIcon } from "./Request.style";
import { useAuth } from "../../../context/Auth";
import { okr } from "../../../api/Api";
import axios from "axios";
import ApproveModalComponent from "./ApproveModal.component";

const tabs = ["Alignment", "Check Ins", "Collaboration"];
const tabPanels = [NewOkrComponent, CheckInsComponent, CollaborationComponent];

/**
 * Request Container containings tabs for NewOkr,Checks In,Colloboration
 *
 */

const RequestComponent = ({
  pin,
  setPin,
  departmentId,
  companyId,
  allCollab,
  alignments
}) => {
  const { idToken, uid } = useAuth();
  const [showApprove, setShowApprove] = useState(false);
  const [title, setTitle] = useState("");

  const [owner, setOwner] = useState("");
  const [status, setStatus] = useState("");
  const [approve, setApprove] = useState(false);
  const [header, setHeaders] = useState("New Checkin Request");

  const [departmentOkr, setDepartmentOkr] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);

  const [modal, setModal] = useFullScreen(false);

  function filterOkr(dept, okr) {
    let deptokr = [];

    okr.forEach((item) => {
      dept.forEach((dep) => {
        if (dep._id === item.departmentid) {
          let temp = { ...item, name: dep.name };
          deptokr.push(temp);
        }
      });
    });
    console.log(deptokr);
    setDepartmentOkr(deptokr);
  }

  useEffect(() => {
    axios
      .post(
        okr.viewAllOkr,
        { companyid: companyId, status: ["S", "R", "T", "C"] },
        {
          headers: {
            Authorization: `${idToken}`,
            uuid: uid,
          },
        }
      )
      .then((res) => {
        let dep = res.data.departments;
        let okr = res.data.okrs;
        filterOkr(dep, okr);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <RequestWrapper modal={modal} pin={pin}>
        {show ? (
          <RequestContainer modal={modal} pin={pin}>
            <CommonHeaderComponent
              headerText="Request"
              setModal={setModal}
              setHover={setHover}
              setPin={setPin}
              setShow={setShow}
              modal={modal}
            />
            {pin ? (
              <UnpinIcon>
                <UnpinComponent
                  setHover={setHover}
                  setShow={setShow}
                  setPin={setPin}
                />{" "}
                <span style={{ marginLeft: "7px" }}>Unpin </span>{" "}
              </UnpinIcon>
            ) : (
              ""
            )}

            {/* 3 Tab Name Mapper */}
            <TabPanel>
              {tabs.map((tab, idx) => (
                <Tab
                  key={idx}
                  onClick={() => setActiveTab(idx)}
                  active={idx === activeTab}
                >
                  {tab}
                </Tab>
              ))}
            </TabPanel>
            {/* Tab Mapper */}
            {tabPanels.map((Panel, idx) =>
              idx === activeTab ? (
                <Panel
                  approve={approve}
                  setApprove={setApprove}
                  setHeader={setHeaders}
                  status={status}
                  setStatus={setStatus}
                  setTitle={setTitle}
                  setOwner={setOwner}
                  showApprove={showApprove}
                  setShowApprove={setShowApprove}
                  allCollab={allCollab}
                  alignments={alignments}
                  departmentOkr={departmentOkr}
                  key={idx}
                />
              ) : (
                ""
              )
            )}
          </RequestContainer>
        ) : (
          <FloatingRequestComponent>
            <RequestFloatingComponent hover={hover} setHover={setHover} />

            <RequestHoverComponent
              OnClick={setModal}
              setShow={setShow}
              show={show}
              hover={hover}
              setHover={setHover}
            />
          </FloatingRequestComponent>
        )}
      </RequestWrapper>
      {showApprove && (
        <ApproveModalComponent
          name={title}
          buttonText={status === "A" ? "Approve" : "Rejected"}
          sendApproval={() => setApprove(true)}
          bgcolor={status === "A" ? "045680" : "F67575"}
          owner={owner}
          setModal={setShowApprove}
          title={header}
        />
      )}
    </>
  );
};

export default RequestComponent;
