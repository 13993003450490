import React, { useState } from "react";
import useTab from "../../../hooks/useTab";
import { Line } from "../../Dashboard/Objective/Objective.style";
import { Circle } from "../../Header/Header.style";

import TabComponent from "../../MyOkr/NewObjective/Tab.component";
import { Header } from "../../Settings/SettingsContent/SettingContent.style";
import UserComponent from "../../Svgs/User.component";
import { DepartmentContainer, Text } from "./Department.style";
import StatusIconComponent from "./StatusIcon.component";

const Department = ({ allDepartments, filterByDept, deptPercent }) => {
  const [clicked, setClicked] = useState("");
  const handleClicked = (id) => {
    if (clicked === id) {
      setClicked("");
      filterByDept("all");
    } else {
      setClicked(id);
      filterByDept(id);
    }
  };
  const getColor = (val) => {
    if (val <= 15 || val === undefined) return "#e95252";
    else if (val <= 45) return "#fcde1c"; //Yellow
    else if (val <= 65) return "#00b8e0";
    else return "#099E60";
  };

  return (
    <>
      {/* Tab Component with header HR */}
      {allDepartments.map((item) => {
        return (
          <div
            style={
              clicked === item._id
                ? {
                    cursor: "pointer",
                    borderLeft: "6px solid #045680",
                    backgroundColor: "#edebeb",
                    fontWeight: "bold",
                  }
                : { cursor: "pointer" }
            }
            onClick={() => {
              handleClicked(item._id);
            }}
          >
            <TabComponent
              key={item._id}
              header={item.name === "Company" ? "CEO" : item.name}
              browse={false}
              HeaderChild={() => (
                <Header style={{ width: "28%" }} className="no-ms mb">
                  <StatusIconComponent
                    bg={getColor(deptPercent[item._id])}
                    className="mr-1"
                    success
                  />
                  <Text light color={getColor(deptPercent[item._id])}>
                    {deptPercent[item._id] ? deptPercent[item._id] : "00.00"}%
                  </Text>
                </Header>
              )}
            ></TabComponent>
            <Line />
          </div>
        );
      })}

      {/* <TabComponent
      header='HR'
      browse={false}
      HeaderChild={() => (
        <Header className='no-ms mb'>
          <StatusIconComponent bg='#099E60' className='mr-1' success />
          <Text light color='#099E60'>
            100%
          </Text>
        </Header>
      )}
    >
      inner
    </TabComponent>
    <TabComponent
      header='Finance'
      browse={false}
      HeaderChild={() => (
        <Header className='no-ms mb'>
          <StatusIconComponent bg='#099E60' className='mr-1' success />
          <Text light color='#099E60'>
            85%
          </Text>
        </Header>
      )}
    >
      inner
    </TabComponent>
    <TabComponent
      header='Marketing'
      browse={false}
      HeaderChild={() => (
        <Header className='no-ms mb'>
          <StatusIconComponent bg='#099E60' className='mr-1' success />
          <Text light color='#099E60'>
            100%
          </Text>
        </Header>
      )}
    >
      inner
    </TabComponent>
    <TabComponent
      header='Sales'
      browse={false}
      HeaderChild={() => (
        <Header className='no-ms mb'>
          <StatusIconComponent bg='#099E60' className='mr-1' success />
          <Text light color='#099E60'>
            100%
          </Text>
        </Header>
      )}
    >
      inner
    </TabComponent>
    <TabComponent
      header='Business Operations'
      browse={false}
      HeaderChild={() => (
        <Header className='no-ms mb'>
          <StatusIconComponent bg='#099E60' className='mr-1' success />
          <Text light color='#099E60'>
            100%
          </Text>
        </Header>
      )}
    >
      inner
    </TabComponent> */}
      <Line />
    </>
  );
};

const User = ({ allUser, filterByUser }) => {
  console.log("Collaborators!");
  console.log(allUser.filter((user) => user.collaborations.length > 0));
  const [clicked, setClicked] = useState("");
  const handleClicked = (id, uuid) => {
    if (clicked === id) {
      setClicked("");
      filterByUser("all");
    } else {
      setClicked(id);
      filterByUser(uuid);
    }
  };

  return (
    <>
      {allUser.map((item) => {
        return (
          <div key={item._id}>
            <Header
              justifyContent="space-around"
              minHeight="60px"
              onClick={() => {
                handleClicked(item._id, item.uuid);
              }}
              style={
                clicked === item._id
                  ? {
                      cursor: "pointer",
                      margin: "0px",
                      borderLeft: "6px solid #045680",
                      backgroundColor: "#edebeb",
                      fontWeight: "bold",
                    }
                  : { cursor: "pointer", margin: "0px" }
              }
              className="no-ms mb"
            >
              {/* User Image  */}
              <div
                style={{
                  display: "flex",
                  flex: "0.6",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: "-5px",
                }}
              >
                <Circle sm className="no-ms">
                  <UserComponent />
                </Circle>
                {/* Username /type  */}
                <Text
                  style={
                    clicked === item._id
                      ? { fontWeight: "bold", marginLeft: "5px" }
                      : { marginLeft: "5px" }
                  }
                >
                  {item.name}
                </Text>
              </div>

              {/* <StatusIconComponent style={{ flex: "0.4" }} bg={filterByUser(item.uuid) ? "#099E60" : "#e95252"} success /> */}
              {/**success:bg='#099E60' */}
            </Header>
            <Line />
          </div>
        );
      })}
      {/* <Header justifyContent="space-around" minHeight="60px" onClick={()=>setClicked(!clicked)}  style={clicked?{cursor:"pointer",margin:"0px",borderLeft:"6px solid #045680",backgroundColor:"#edebeb",fontWeight:"bold"}:{cursor:"pointer",margin:"0px"}} className='no-ms mb'> */}
      {/* User Image  */}
      {/* <div style={{display:"flex" ,justifyContent:"flex-start",alignItems:"center",marginLeft:"-40px"}}> */}
      {/* <Circle sm className='no-ms'> */}
      {/* <UserComponent /> */}
      {/* </Circle> */}
      {/* Username /type  */}
      {/* <Text style={clicked?{fontWeight:"bold",marginLeft:"5px"}:{marginLeft:"5px"}}>Owner</Text> */}
      {/* </div> */}

      {/* <StatusIconComponent bg='#099E60' success /> */}
      {/* </Header> */}
      {/* <Line /> */}

      <Line />
    </>
  );
};

const tabs = ["Department", "Users"];
const tabContent = [Department, User];
// Department and User Tab
const DepartmentComponent = ({
  allUser,
  allDepartments,
  filterByUser,
  filterByDept,
  deptPercent,
}) => {
  const { showTabs } = useTab(0, tabs, tabContent, true);
  return (
    <DepartmentContainer>
      {showTabs(
        [],
        [],
        allUser,
        allDepartments,
        filterByDept,
        filterByUser,
        deptPercent
      )}
    </DepartmentContainer>
  );
};

export default DepartmentComponent;
