import React from 'react';
import ProgressBar from '../../Dashboard/Okr/Tabs/ProgressBar';
import { Text } from '../../../utils/shared.style';
import {
  LogBody,
  LogContainer,
  LogHeader,
  Objective,
  Progress,
} from './MyOkrTab.style';
import { Line } from '../../Dashboard/Objective/Objective.style';
import OkrCommentComponent from './OkrComment.component';

// Common log component for CheckIn, Objective and New Colloboration

const LogContainerComponent = ({
  comments,
  headerText,
  objectiveText,
  progressWidth,
  notes,
  assignment,
  IconComponent,
  time="2"
}) => {
  return (
    <LogContainer>
      <LogHeader>
        <IconComponent className='mr-2' />

        {/* Log heading  */}
        <LogBody>
          <Text bold color="gray">{headerText}</Text>
          <Objective>
            <LogBody width={true}>
              <Text bold>Objective</Text>
              <Text>{objectiveText || ''}</Text>
            </LogBody>

            {/* Progress bAr  */}
            {progressWidth ? (
              <LogBody width>
                <Text bold>% Complete</Text>
                <LogHeader>
                  <Progress>
                    <ProgressBar text={false} width={progressWidth || '80%'} />
                  </Progress>
                  <Text style={{ marginLeft: '1rem' }}>{progressWidth}</Text>
                </LogHeader>
              </LogBody>
            ) : (
              ''
            )}

            {/* Notes  */}
            {notes ? (
              <LogBody width>
                <Text bold>Notes</Text>
                <Text light>{notes}</Text>
              </LogBody>
            ) : (
              ''
            )}

            {/* Assignment  */}
            {assignment ? (
              <LogBody width>
                <Text bold>Assigne</Text>
                <Text light>{assignment}</Text>
              </LogBody>
            ) : (
              ''
            )}
          </Objective>
          {/* <Line /> */}
          {/* <OkrCommentComponent comments={comments} /> */}
        </LogBody>

        {/* Log time  */}
        <Text light>{time} days ago</Text>
      </LogHeader>
      <Line />
    </LogContainer>
  );
};

export default LogContainerComponent;
