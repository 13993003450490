import React from 'react';

const ObjectivesComponent = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='nav'
    >
      <path
        d='M9.20898 22.7893L18.665 18.6666L22.8143 9.2373L13.3583 13.36L9.20898 22.7893ZM14.8677 14.8693C15.0913 14.6453 15.3764 14.4928 15.6868 14.4309C15.9973 14.369 16.3191 14.4005 16.6116 14.5216C16.904 14.6426 17.154 14.8477 17.3299 15.1109C17.5058 15.374 17.5997 15.6834 17.5997 16C17.5997 16.3165 17.5058 16.6259 17.3299 16.8891C17.154 17.1523 16.904 17.3573 16.6116 17.4784C16.3191 17.5994 15.9973 17.631 15.6868 17.5691C15.3764 17.5072 15.0913 17.3546 14.8677 17.1306C14.568 16.8306 14.3997 16.424 14.3997 16C14.3997 15.576 14.568 15.1693 14.8677 14.8693Z'
        fill='white'
      />
      <path
        d='M30.9333 15.7333H32C31.9314 11.582 30.2517 7.61993 27.3159 4.68411C24.3801 1.74828 20.418 0.0686308 16.2667 0V1.06667C16.2667 1.13739 16.2386 1.20522 16.1886 1.25523C16.1386 1.30524 16.0707 1.33333 16 1.33333C15.9293 1.33333 15.8614 1.30524 15.8114 1.25523C15.7614 1.20522 15.7333 1.13739 15.7333 1.06667V0C11.582 0.0686308 7.61993 1.74828 4.68411 4.68411C1.74828 7.61993 0.0686308 11.582 0 15.7333H1.06667C1.13739 15.7333 1.20522 15.7614 1.25523 15.8114C1.30524 15.8614 1.33333 15.9293 1.33333 16C1.33333 16.0707 1.30524 16.1386 1.25523 16.1886C1.20522 16.2386 1.13739 16.2667 1.06667 16.2667H0C0.0686308 20.418 1.74828 24.3801 4.68411 27.3159C7.61993 30.2517 11.582 31.9314 15.7333 32V30.9333C15.7333 30.8626 15.7614 30.7948 15.8114 30.7448C15.8614 30.6948 15.9293 30.6667 16 30.6667C16.0707 30.6667 16.1386 30.6948 16.1886 30.7448C16.2386 30.7948 16.2667 30.8626 16.2667 30.9333V32C20.418 31.9314 24.3801 30.2517 27.3159 27.3159C30.2517 24.3801 31.9314 20.418 32 16.2667H30.9333C30.8626 16.2667 30.7948 16.2386 30.7448 16.1886C30.6948 16.1386 30.6667 16.0707 30.6667 16C30.6667 15.9293 30.6948 15.8614 30.7448 15.8114C30.7948 15.7614 30.8626 15.7333 30.9333 15.7333ZM16 29.3333C13.3629 29.3333 10.7851 28.5513 8.5924 27.0863C6.39974 25.6212 4.69077 23.5388 3.68161 21.1024C2.67244 18.6661 2.40839 15.9852 2.92286 13.3988C3.43733 10.8124 4.70721 8.43661 6.57191 6.57191C8.43661 4.70721 10.8124 3.43733 13.3988 2.92286C15.9852 2.40839 18.6661 2.67244 21.1024 3.68161C23.5388 4.69077 25.6212 6.39974 27.0863 8.5924C28.5513 10.7851 29.3333 13.3629 29.3333 16C29.3333 17.751 28.9885 19.4848 28.3184 21.1024C27.6483 22.7201 26.6662 24.19 25.4281 25.4281C24.19 26.6662 22.7201 27.6483 21.1024 28.3184C19.4848 28.9885 17.751 29.3333 16 29.3333Z'
        fill='white'
      />
    </svg>
  );
};

export default ObjectivesComponent;
