import React from 'react';

const ObjectiveComponent = (props) => {
  return (
    <svg width="27" height="27" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.20309e-05 15.4685C-0.00620161 18.5354 0.897556 21.5353 2.59692 24.0884C4.29629 26.6415 6.71488 28.6331 9.54657 29.8111C12.3783 30.9891 15.4958 31.3005 18.5046 30.706C21.5133 30.1115 24.2781 28.6377 26.449 26.4713C28.6199 24.3048 30.0992 21.5431 30.6999 18.5355C31.3005 15.5279 30.9954 12.4098 29.8231 9.5757C28.6509 6.74161 26.6643 4.31898 24.1146 2.61442C21.565 0.909866 18.567 6.335e-06 15.5 0C11.3969 0.00746154 7.46353 1.63857 4.55924 4.53696C1.65495 7.43535 0.0158417 11.3654 3.20309e-05 15.4685ZM29.7084 15.4685C29.7146 18.28 28.8866 21.0301 27.3292 23.3708C25.7717 25.7115 23.5549 27.5375 20.9592 28.6177C18.3635 29.6979 15.5057 29.9838 12.7475 29.4391C9.98926 28.8945 7.45464 27.5438 5.46441 25.5579C3.47419 23.5721 2.11785 21.0405 1.56706 18.2835C1.01627 15.5265 1.29579 12.6681 2.37026 10.07C3.44472 7.47194 5.26581 5.25104 7.60304 3.68841C9.94026 2.12578 12.6885 1.29167 15.5 1.29167C19.2626 1.29249 22.8713 2.78538 25.5348 5.44297C28.1983 8.10055 29.6992 11.7059 29.7084 15.4685Z" fill="#045680"/>
  <path d="M2.00005 15.4629C1.99271 18.1345 2.77823 20.7482 4.25716 22.9732C5.73609 25.1981 7.84193 26.9342 10.3081 27.9617C12.7742 28.9892 15.4898 29.2618 18.111 28.7451C20.7321 28.2284 23.1411 26.9456 25.0328 25.0591C26.9245 23.1725 28.214 20.7671 28.7379 18.1474C29.2618 15.5276 28.9966 12.8113 27.9759 10.3424C26.9552 7.87341 25.2249 5.76281 23.0041 4.27776C20.7832 2.79272 18.1717 2.00001 15.5 2C11.926 1.99999 8.49787 3.41721 5.9672 5.94094C3.43652 8.46466 2.00987 11.8889 2.00005 15.4629ZM27.4794 15.4629C27.4868 17.8338 26.7904 20.1535 25.4786 22.1284C24.1668 24.1033 22.2984 25.6445 20.1101 26.5568C17.9218 27.4692 15.512 27.7117 13.1858 27.2537C10.8596 26.7957 8.7216 25.6577 7.04254 23.9838C5.36348 22.31 4.21886 20.1755 3.75362 17.8507C3.28838 15.526 3.52344 13.1154 4.42903 10.9243C5.33462 8.73318 6.87002 6.86006 8.84081 5.5421C10.8116 4.22414 13.1292 3.52062 15.5 3.5206C18.6693 3.52547 21.7078 4.78406 23.9523 7.02156C26.1967 9.25907 27.4648 12.2937 27.4794 15.4629Z" fill="#045680"/>
  <path d="M15.5005 17.9573C16.8751 17.9573 17.9894 16.843 17.9894 15.4685C17.9894 14.094 16.8751 12.9797 15.5005 12.9797C14.126 12.9797 13.0117 14.094 13.0117 15.4685C13.0117 16.843 14.126 17.9573 15.5005 17.9573Z" fill="#045680"/>
  <path d="M14.8066 8.50611C13.1948 8.67094 11.6897 9.38855 10.5469 10.5371C9.40413 11.6856 8.69408 13.1943 8.53733 14.8069H7.05664V16.0986H8.53733C8.69248 17.7308 9.41362 19.2574 10.5758 20.4138C11.7381 21.5703 13.2682 22.2839 14.9012 22.4309V23.8171H16.1928V22.4309C17.8227 22.2715 19.3444 21.5431 20.4908 20.3737C21.6372 19.2042 22.3352 17.6683 22.4621 16.0356H23.9428V14.7439H22.4621C22.2875 13.1282 21.5576 11.6232 20.3971 10.4856C19.2365 9.34804 17.7172 8.64841 16.0983 8.50611V6.93091H14.8066V8.50611ZM21.202 15.4685C21.202 16.5963 20.8675 17.6988 20.241 18.6365C19.6144 19.5742 18.7238 20.3051 17.6819 20.7367C16.6399 21.1683 15.4934 21.2812 14.3873 21.0612C13.2812 20.8412 12.2651 20.2981 11.4676 19.5006C10.6702 18.7031 10.1271 17.6871 9.90706 16.581C9.68704 15.4748 9.79996 14.3283 10.2315 13.2864C10.6631 12.2444 11.394 11.3538 12.3317 10.7273C13.2695 10.1007 14.3719 9.76627 15.4997 9.76627C17.0121 9.76627 18.4624 10.367 19.5318 11.4364C20.6012 12.5058 21.202 13.9562 21.202 15.4685Z" fill="#045680"/>
</svg>

  );
};

export default ObjectiveComponent;
