import React from 'react';

const DashboardComponent = () => {
  return (
    <svg
      className='nav'
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1.92773'
        y='1'
        width='7.28552'
        height='14.7139'
        stroke='white'
        strokeWidth='2'
      />
      <rect
        x='1.92773'
        y='19.5715'
        width='7.28552'
        height='5.42842'
        stroke='white'
        strokeWidth='2'
      />
      <rect
        x='13.0703'
        y='1.00024'
        width='10.9997'
        height='5.42842'
        stroke='white'
        strokeWidth='2'
      />
      <rect
        x='13.0703'
        y='10.2859'
        width='10.9997'
        height='14.7139'
        stroke='white'
        strokeWidth='2'
      />
    </svg>
  );
};

export default DashboardComponent;
