import { createGlobalStyle } from 'styled-components';

const GlobalCss = createGlobalStyle`
html {
  font-size:62.5%;
}
  body {
    padding:0;
    margin:0;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
    background-color: ${(props) => props.theme.greyLight1};
    position: relative;
  }

  h1,p,h2 {
    margin: 0;
    padding: 0;
  }
  .mr-2 {
    margin-right: 2rem;
  }
  .mr-1 {
    margin-right: 1rem;
  }
.underline{
  text-decoration: underline;
  cursor: pointer;
}
  svg,.cursor {
    /* margin-right: 2rem; */
    cursor: pointer;
  }
  .nav {
    margin-right: 0 !important;
    height:23px;
  }
  .no-mr {
    margin-right: 0;
  }

 .my-node-enter {
  opacity: 0;
  transform: scale(0.9);
}
.my-node-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cccc;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .react-calendar__navigation button {
    min-width:20px !important;
  }

 .react-calendar__tile--active{
    background: #003550 !important;
  }

.react-calendar {
  border: none !important;
}
.no-ms{
  margin:0;
}
.mb{
  margin: 1rem;
}
.mbb {
  margin-bottom: 1rem;
} 
.mtt {
  margin-top:1rem;
}
`;

export default GlobalCss;
