import React, { useEffect, useState } from "react";
import NewRequestComponent from "./NewRequest.component";
import RequestHeaderComponent from "./RequestHeader.component";

import { useAuth } from "../../../context/Auth";
import { checkInHistory } from "../../../api/Api";
import { ApproveCheckin } from "../../../api/Api";
import { useHistory } from "react-router-dom";
import Loader from "../../Loading/Loader";

import axios from "axios";
import ApproveModalComponent from "./ApproveModal.component";

const CheckInsComponent = ({
  departmentOkr,
  showApprove,
  setShowApprove,
  setTitle,
  setOwner,
  setStatus,
  status,
  approve,
  setApprove,
}) => {
  const { uid, idToken } = useAuth();
  const [checkin, setCheckIn] = useState([]);
  // const [status,setStatus]=useState("")
  // const [showApprove,setShowApprove]=useState(false);
  // const [title,setTitle]=useState("");
  // const [owner,setOwner]=useState("");
  const [loading, setLoading] = useState(false);
  const [okr, setOkr] = useState([]);
  const [data, setData] = useState([]);
  const [checkinId, setCheckinId] = useState("");
  const history = useHistory();
  const [sortBy, setSortBy] = useState("");
  const [progressBarData, setProgressBarData] = useState({
    request: 0,
    approved: 0,
    rejected: 0,
  });

  // console.log(email);
  // const [sortBy,setSort]=useState("");
  useEffect(() => {
    if (approve) {
      axios
        .post(
          ApproveCheckin,
          { checkinID: checkinId, status: status },
          {
            headers: {
              Authorization: `${idToken}`,
            },
          }
        )
        .then((res) => {
          setCheckIn(
            checkin.map((item) =>
              item._id === checkinId ? { ...item, status: status } : item
            )
          );
          setApprove(false);
        })
        .catch((err) => console.log(err));
    }
  }, [approve]);

  useEffect(() => {
    let temp1 = [];

    departmentOkr &&
      checkin &&
      departmentOkr?.forEach((item) => {
        checkin?.forEach((check) => {
          let temp = check.checkin_data || check["checkin-data"];

          console.log(temp);
          for (const [key, value] of Object.entries(temp)) {
            if (key === item._id)
              temp1.push({
                ...item,
                okrID: item._id,
                ...check,
                checkInStatus: check.status,
                ...value,
              });
          }
        });
      });

    setData(temp1);
  }, [checkin]);

  const handleOnClick = (id, status) => {
    console.log(status);
    setShowApprove(true);
    setStatus(status);

    let tl = data.find((item) => item._id === id);
    console.log(tl);
    setTitle(tl.title);
    setOwner(tl.username);
    setCheckinId(tl._id);
  };
  
  useEffect(() => {
    const getCheckInData = async () => {
      setLoading(true);
      await axios
        .post(
          checkInHistory,
          { name: "Rahul" },
          {
            headers: {
              Authorization: `${idToken}`,
              uuid: uid,
            },
          }
        )
        .then((res) => {
          console.log(res.data.data);
          setCheckIn(res.data.data.filter((item) => item.approval === uid));
          setOkr(res.data.okrs);
        })
        .catch((err) => console.log(err));
      setLoading(false);
    };

    getCheckInData();
  }, []);

  console.log(checkin);

  function func(temp) {
    if (temp === "S") return "not Started";
    else if (temp === "T") return "on Track";
    else if (temp === "C") return "Completed";
    else return "at Risk";
  }
  console.log(data);
  const showDetails = (id) => {
    history.push(`/okr/${id}`);
  };
  // console.log(data);
  useEffect(() => {
    let temp = { request: 0, approved: 0, rejected: 0 };
    if (data.length) {
      data.forEach((item) => {
        item.checkInStatus === "NA"
          ? (temp.request += 1)
          : item.checkInStatus === "A"
          ? (temp.approved += 1)
          : (temp.rejected += 1);
      });
      setProgressBarData(() => {
        return { ...temp };
      });
    }
  }, [data]);
  console.log(data.length);
  console.log(progressBarData);
  useEffect(() => {
    let temp = data;
    if (sortBy === "latest") {
      temp.sort((a, b) => {
        let name1, name2;

        name1 = new Date(a.timestamp);
        name2 = new Date(b.timestamp);

        if (name1 > name2) return -1;
        if (name1 < name2) return 1;

        return 0;
      });

      console.log(temp);
      setData([...temp]);
    }
  }, [sortBy]);
  console.log(data);
  const getData = (cnt) => {
    const { approved, rejected, request } = progressBarData;
    let total = approved + rejected + request;
    return (cnt / total) * 100;
  };

  return (
    <>
      {loading ? (
        <Loader type="spin" color="cyan" />
      ) : (
        <>
          <RequestHeaderComponent
            index={`${progressBarData.approved}/${
              progressBarData.approved +
              progressBarData.rejected +
              progressBarData.request
            }`}
            checkin
            request={getData(progressBarData?.request)}
            approved={getData(progressBarData?.approved)}
            rejected={getData(progressBarData?.rejected)}
            setSortBy={setSortBy}
            sortBy={sortBy}
            headerText="Check In"
          />
          {data.map((item) => {
            return (
              <NewRequestComponent
                showDetails={showDetails}
                okrID={item.okrID}
                handleOnClick={handleOnClick}
                _id={item._id}
                IconName="ObjectiveComponent"
                title={item.title}
                name={item.name}
                owner={item.username}
                isRequestNew={item.checkInStatus === "NA"}
                time={func(item.progress_status)}
                progress={`${parseFloat(item.progress).toFixed(2)}%`}
                approved={item.checkInStatus === "A"}
              />
            );
          })}
        </>
      )}
      {/* {showApprove&&<ApproveModalComponent name={title} owner={owner} sendApproval={sendCheckinApproval} title="New Checkin Request" buttonText={status==="A"?"Approve":"Rejected"} bgcolor={status==="A"?"045680":"F67575"} setModal={setShowApprove} />} */}
      {/* <NewRequestComponent IconName="CheckInsComponentSvg" approved />
      <NewRequestComponent IconName="CheckInsComponentSvg" isRequestNew />
      <NewRequestComponent IconName="CheckInsComponentSvg" /> */}
    </>
  );
};

export default CheckInsComponent;
