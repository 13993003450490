import React, { useState, useEffect } from "react";
import useTab from "../../../hooks/useTab";
import DashboardInputComponent from "../../Dashboard/DashboardHeader/DashboardInput.component";
import { Line } from "../../Dashboard/Objective/Objective.style";
import InputComponent from "../../Input/Input.component";
import FilterComponent from "../../Dashboard/DashboardHeader/Filter.component";
import { options } from "../../Dashboard/DashboardHeader/DashboardHeader.component";
import { Button } from "../../MyOkr/NewObjective/NewObjective.style";
import UploadComponent from "../../Svgs/Upload.component";
import InvitePeople from "./InvitePeople.component";
import NewDeptComponent, { SharedModal } from "./NewDept.component";
import SettingComponent from "./Setting.component";
import { Header, SettingContent, TableHeader } from "./SettingContent.style";
import TabComponent from "./Tab.component";
import UserComponent from "./User.component";
import UploadFile from "./Upload.component";
import { department } from "../../../api/Api";
import { useAuth } from "../../../context/Auth";
import axios from "axios";
import Loader from "../../Loading/Loader";
import { async } from "@firebase/util";
// import UserSvg from '../../Svgs/User.component';

const Department = ({ allDepartment, filterDept, filterByMembers }) => {

  const [modal, setModal] = useState(false);
  const handleOnChange = (val) => {
    filterDept(val)
  }
  const [formData, setFormData] = useState({
    ViewBy: ""
  })
  useEffect(() => {
    if (formData.ViewBy) {
      filterByMembers(formData.ViewBy);
    }
  }, [formData])

  return (
    <>
      <Header>
        <InputComponent lg placeholder="Search" onChange={(e) => handleOnChange(e.target.value)} pLeft="18px" search />
        <Header className="no-ms">

          <DashboardInputComponent value={formData.ViewBy ? formData.ViewBy : "Members"} placeholder="Sort By">
            <FilterComponent formData={formData} setFormData={setFormData} name="ViewBy" options={["Increasing", "Decreasing"]} />
          </DashboardInputComponent>
        </Header>
      </Header>

      <Header>
        <TableHeader>Department</TableHeader>
        {/*<TableHeader>Type</TableHeader>*/}
        <TableHeader>Owner</TableHeader>
        <TableHeader>Members</TableHeader>
        <TableHeader></TableHeader>
      </Header>
      <Line />
      {allDepartment?.length && (
        allDepartment.map((item, id) => (
          <TabComponent
            department={item.name}
            type={item.type}
            member={item.currentusers}
            owner={item.owner}
            onClick={() => setModal((state) => !state)}
          />
        )))
      }
      <SharedModal
        tab1="Create New Team"
        tab1Input="New Team"
        tab2="Add Members"
        modal={modal}
        onClick={() => setModal((state) => !state)}
      />
    </>
  );
};

const tabContent = ["Department", "Users"]; //removed: "Settings"
const tabs = [Department, UserComponent]; //removed: , SettingComponent

export const SettingContentContainer = ({ allUsers, searchUser, filterByStatus, sortByField }) => {
  const { uid, idToken } = useAuth();
  const [modal, setModal] = useState(false);
  const [showPeople, setShowPeople] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [newDept, setNewDept] = useState({ name: "", owner: "", type: "Department" });
  const [allDepartment, setAllDeparment] = useState([]);
  const [filterDepartment, setFilterDepartment] = useState([]);
  const [allUser, setAllUser] = useState([]);
  // const [showTab,setShowTab]=useState("")
  const [loading, setLoading] = useState(false);

  //add new department
  const createNewDept = () => {
    const data = {
      department: newDept.name,
      uuid: uid,
      owner: newDept.id,
      type: newDept.type,
    };
    const { name, owner, type, currentusers } = newDept;
    if (name === "" || owner === "" || type === "") return;

    axios
      .post(department.postDepartment, data, {
        headers: { Authorization: `${idToken}` },
      })
      .then((res) => {
        setAllDeparment([
          ...allDepartment,
          { name, owner: res.owner, type, currentusers: currentusers ? currentusers : 0 },
        ]);
        setModal(false);
      })
      .catch((err) => console.log(err));
  };

  //fetch all departments
  const getData = async () => {
    setLoading(true);

    //find All Departments
    const res = await axios.get(department.getDepartment, {
      headers: {
        Authorization: `${idToken}`
      }
    });

    //map owner name with departments

    //find owenr of all department to filter head of departments
    const owner = allUsers.find((user) => user.accessright === "superAdmin");
    //find department heads with onwer name and user reporting to
    const departmentHeads = allUsers.filter(user => owner.name === user.name || user.reportingto === owner.name);

    const departments = departmentHeads.map(head => {

      const department = res.data.departments.find(dept => dept._id === head.departmentid);

      return {
        ownerId: head.uuid,
        owner: head.name,
        _id: head.departmentid,
        companyid: head.companyid,
        type: department.type,
        name: department.name,
        currentusers: department.currentusers
      }
    });

    //map user with department name and update state
    setAllUser(() => allUsers.map(user => {
      const department = res.data.departments.find(dept => dept._id === user.departmentid);
      return {
        ...user,
        department: department.name
      }
    }));

    //update department state
    setAllDeparment(departments);
    setFilterDepartment(departments);

    //stop loading
    setLoading(false);
  }

  useEffect(async () => {
    await getData();
  }, [allUsers]);

  const TabChild = (active) => {

    if (!active) {
      return (
        <Button onClick={() => setModal((state) => !state)}>
          + New Department
        </Button>
      );
    }
    if (active === 1) {
      return (
        <Header>
          <Button onClick={() => setShowFile((state) => !state)}>
            <UploadComponent className="mr-1" />
            Upload File
          </Button>
          <Button onClick={() => setShowPeople((state) => !state)}>
            {/* <UserSvg className='mr-1' /> */}
            Invite User
          </Button>
        </Header>
      );
    }
  };

  const filterDept = (val) => {
    setFilterDepartment(allDepartment.filter((item) => {
      return item.name.toLowerCase().includes(val.toLowerCase());
    })
    )

  }
  const filterByMembers = (val) => {
    console.log(val);
    let temp = [...filterDepartment];
    if (val === "Increasing") {
      temp.sort(function (a, b) {
        if (a.currentusers > b.currentusers) return 1;
        else return -1;
      });
      setFilterDepartment([...temp])
    }

    else {
      temp.sort(function (a, b) {
        if (a.currentusers < b.currentusers) return 1;
        else return -1;
      });
      setFilterDepartment([...temp])
    }
  }

  const { showTabs } = useTab(
    0,
    tabContent,
    tabs,
    false,
    TabChild,
    filterDepartment,
    allUsers,
    filterDept,
    searchUser,
    filterByMembers,
    filterByStatus,
    sortByField
  );
  console.log(sortByField);
  return (
    <div>
      {/* {loading?<Loader type="spin" color="cyan"/>:<> */}
      <SettingContent>{showTabs([], [], allUser)}</SettingContent>
      <NewDeptComponent
        modal={modal}
        onClick={() => setModal((state) => !state)}
        createNewDept={createNewDept}
        newDept={newDept}
        setNewDept={setNewDept}
      />
      <InvitePeople
        modal={showPeople}
        onClick={() => setShowPeople((state) => !state)}
      />
      <UploadFile
        modal={showFile}

        onClick={() => setShowFile((state) => !state)}
      />
      {/* </>} */}
    </div>

  );
};
