import { useEffect, useState } from 'react';
import { Text } from '../../../utils/shared.style';
import InputComponent from '../../Input/Input.component';
import SelectComponent from '../../Input/Select.component';

import {
    Browse,
    Button,
    InputContainer
} from '../../MyOkr/NewObjective/NewObjective.style';

import {
    ModalContainer,
    Modal,
    Header,
} from './SettingContent.style';

import CloseReqComponent from '../../Svgs/CloseReq.component';

export const UserEditModal = ({
    user,
    modal,
    closeModal,
    handleOnChange,
    updateUser,
    departments,
    roles
}) => {

    return (
        <Modal modal={modal}>
            <ModalContainer style={{ overflow: "hidden" }}>
                <Header className='no-ms mbb'>
                    <Text bold>Update user</Text>
                    <div onClick={closeModal}>
                        <CloseReqComponent />
                    </div>
                </Header>

                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <InputContainer width={"50%"} lg>
                        <Text light>Department</Text>
                        <SelectComponent
                            name={user?.department}
                            selectedValue={{ name: "department", label: user?.department, value: user?.departmentid, extraValue: user?.managerid }}
                            datalists={departments}
                            selectValue={handleOnChange}
                        />
                    </InputContainer>
                    <InputContainer width={"50%"} lg>
                        <Text light>Roles</Text>
                        <SelectComponent
                            name="role"
                            selectedValue={{ name: "role", label: user?.role, value: user?.role }}
                            datalists={roles}
                            selectValue={handleOnChange}
                        />
                    </InputContainer>
                </div>

                <InputContainer lg>
                    <Text light>Name</Text>
                    <InputComponent name="name" value={user?.name} onChange={(e) => handleOnChange(e)} width='91%' placeholder='eg' />
                </InputContainer>

                <InputContainer lg>
                    <Text light>Email</Text>
                    <InputComponent name="email" value={user?.email} onChange={(e) => handleOnChange(e)} width='91%' placeholder='eg' />
                </InputContainer>

                <Browse style={{ marginTop: "50px" }} className='mtt'>
                    <Button onClick={closeModal} className='mr-1'>Cancel</Button>
                    <Button onClick={updateUser} main>Update</Button>
                </Browse>
            </ModalContainer>
        </Modal>
    );
}