import React from 'react';

const UploadFileSvg = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='44'
      height='37'
      viewBox='0 0 44 37'
      fill='none'
      {...props}
    >
      <path
        fillule='evenodd'
        clipRule='evenodd'
        d='M33.7169 9.7583C32.0938 4.53959 27.4941 0.750732 22 0.750732C16.0232 0.750732 11.0355 5.20878 9.88083 11.14C4.24005 12.3628 0 17.6104 0 23.9027C0 31.0953 5.54141 36.9254 12.3747 36.9254H15.8907C16.2531 36.9045 16.5939 36.7431 16.843 36.4745C17.092 36.2059 17.2305 35.8504 17.2299 35.4811C17.2305 35.1119 17.092 34.7564 16.843 34.4877C16.5939 34.2191 16.2531 34.0578 15.8907 34.0368H12.3747C7.06044 34.0368 2.75414 29.5018 2.75414 23.9268C2.75414 18.3518 7.05571 13.8168 12.37 13.8168C12.37 8.22254 16.681 3.70672 21.9953 3.70672C26.8552 3.70672 30.835 7.51483 31.4597 12.4447C36.9254 12.695 41.2459 17.3986 41.2459 23.1758C41.2836 25.8783 40.3112 28.4947 38.5252 30.4957C36.7393 32.4968 34.2731 33.7332 31.6253 33.955L30.8965 33.9935C30.5536 33.9613 30.212 34.0681 29.9461 34.2908C29.6803 34.5135 29.5116 34.8341 29.4769 35.1827C29.4675 35.2771 29.4675 35.3723 29.4769 35.4667C29.4769 35.8497 29.6264 36.2171 29.8927 36.488C30.1589 36.7588 30.52 36.911 30.8965 36.911C31.371 36.9216 31.8455 36.8958 32.3162 36.834C38.8798 36.1214 44 30.2769 44 23.1758C44.0325 20.0852 43.033 17.0749 41.1649 14.6375C39.2969 12.2001 36.671 10.4798 33.7169 9.7583Z'
        fill='#045680'
      />
    </svg>
  );
};

export default UploadFileSvg;
