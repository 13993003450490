import React from 'react';
import { Text } from '../../../utils/shared.style';
import { Line } from '../../Dashboard/Objective/Objective.style';
import InputComponent from '../../Input/Input.component';
import EditComponent from '../../Svgs/Edit.component';
import KeyRes from '../../Svgs/KeyRes.component';


import {
  Browse,
  Button,
  ButtonContainer,
  InputContainer,
  Search,
} from './NewObjective.style';

import TabComponent from './Tab.component';

const KeyResultComponent = ({ setComp, createObjectiveOkr, formData, setFormData, totalKeyResult, editKeyResult = () => { }, alignment }) => {


  return (
    <>
      <TabComponent
        header='Alignment'
        browse={true}
        setComp={() => setComp("Alignment")}

      >
        <InputContainer lg>

          <InputComponent disabled={true} name="title" value={alignment ? alignment.name : ""} style={{ color: "black", fontWeight: "500" }} width='90%' placeholder='Eg Increase' />
        </InputContainer>
      </TabComponent>

      <TabComponent
        browse={false}
        header={`Key Results (${totalKeyResult.length})`}
        HeaderChild={() => (
          <Button onClick={() => setComp('newKey')}>+ New Key Result</Button>
        )}
      >
        {
          totalKeyResult.map((item, idx) => <Search style={{ justifyContent: "space-between " }}>
            <div style={{ display: "flex" }}>
              <KeyRes className='mr-2' />


              <Text light>{item.title}</Text>
            </div>
            <div style={{ alignSelf: "self-end" }}><EditComponent onClick={() => { editKeyResult(item, idx) }} /></div>

          </Search>)
        }
        {/* <Search>
          <KeyRes className='mr-2' />

          {
            totalKeyResult.map((item)=><Text light>{item.title}</Text>)
          }
          
        </Search> */}
        <Line />

      </TabComponent>
      <ButtonContainer>
        <Browse>
          <Button onClick={() => { createObjectiveOkr() }} main>Create Okr</Button>
        </Browse>
      </ButtonContainer>
    </>
  );
};

export default KeyResultComponent;
