export const User = {
  getAllUser: `https://s2e.onrender.com/api/v1/users`,
  postUser: `https://s2e.onrender.com/api/v1/users`,
  getUserById: (id) => `https://s2e.onrender.com/api/v1/users/${id}`,
  updateUser: (idToken, id) => {
    return (`https://s2e.onrender.com/api/v1/users`, {
      headers: {
        Authorization: `${idToken}`
      }
    })
  },
  deleteUser: (idToken, id) => {
    return (`https://s2e.onrender.com/api/v1/users`, {
      headers: {
        Authorization: `${idToken}`
      }
    })
  },
  registerUser: `https://s2e.onrender.com/api/v1/register_excel`
}

export const company = {
  getAllCompany: (idToken) => {
    return (`https://s2e.onrender.com/api/v1/company/all`, {
      headers: {
        Authorization: `${idToken}`
      }
    })
  },
  getCompany: `https://s2e.onrender.com/api/v1/company`,
  postCompany: `https://s2e.onrender.com/api/v1/company`,
  updateCompany: (idToken) => { return (`https://s2e.onrender.com/api/v1/company`, { headers: { Authorization: `${idToken}` } }) },
  deleteCompany: (idToken) => { return (`https://s2e.onrender.com/api/v1/company`, { headers: { Authorization: `${idToken}` } }) },
}

export const department = {
  getDepartment: `https://s2e.onrender.com/api/v1/department`,
  // getDepartmentV2: (idToken)=>{return(``https://s2e.onrender.com/api/v2/department`,{headers:{Authorization:`${idToken}`}})},
  getDepartmentById: (idToken, id) => { return (`https://s2e.onrender.com/api/v1/department/${id}`, { headers: { Authorization: `${idToken}` } }) },
  postDepartment: `https://s2e.onrender.com/api/v1/department`,
  updateDepartment: (idToken) => { return (`https://s2e.onrender.com/api/v1/department`, { headers: { Authorization: `${idToken}` } }) },
  deleteDepartment: (idToken) => { return (`https://s2e.onrender.com/api/v1/department`, { headers: { Authorization: `${idToken}` } }) },
}

export const okr = {
  addOkr: `https://s2e.onrender.com/api/v1/add_okr`,
  viewAllOkr: `https://s2e.onrender.com/api/v1/okr`,
  singleOkr: `https://s2e.onrender.com/api/v1/single_okr`,
}

export const collabortion = {
  getCollaborations: `https://s2e.onrender.com/api/v1/collaborations`,
  postCollaborations: `https://s2e.onrender.com/api/v1/collaboration_request`,
  viewcollabReq: `https://s2e.onrender.com/api/v1/collaboration_list`,
  approveCollab: `https://s2e.onrender.com/api/v1/collaboration_approval`,
  postAlignments: `https://s2e.onrender.com/api/v1/alignment_request`,
  viewalignmentReq: `https://s2e.onrender.com/api/v1/alignment_list`,
  approveAlignment: `https://s2e.onrender.com/api/v1/alignment_approval`
}

export const checkIn = `https://s2e.onrender.com/api/v1/check_in`;
export const checkInHistory = `https://s2e.onrender.com/api/v1/checkin_history`;
export const ApproveCheckin = `https://s2e.onrender.com/api/v1/okr_approval`;

export const notifications = `https://s2e.onrender.com/api/v1/notifications`;

export const updateOkr = `https://s2e.onrender.com/api/v1/update_okr`;