import React, { useState, useEffect } from 'react';
import DashboardInputComponent from '../../Dashboard/DashboardHeader/DashboardInput.component';
import TabComponent from '../../Dashboard/Okr/Tabs/Tab.component';
import InnerTabComponent from '../../Dashboard/Okr/Tabs/InnerTab.component';
import InputComponent from '../../Input/Input.component';
import { Header } from '../../Settings/SettingsContent/SettingContent.style';
import { AnalyticContainer } from './Analytics.style';
import CheckInComponent from '../CheckIn/CheckIn.component';

import { dummyOkrAnalyticData } from '../../Dashboard/Okr/Tabs/helpers';
import FilterComponent from '../../Dashboard/DashboardHeader/Filter.component';
import { options } from '../../Dashboard/DashboardHeader/DashboardHeader.component';
import { Line } from '../../Dashboard/Objective/Objective.style';
import CheckinModelComponent from '../../Dashboard/Okr/CheckinModel.component';

const AnalyticsComponent = ({ filterBySearch, aspirationalOkrData, criticalOkrData, filterByStatus }) => {
  const [modal, setModal] = useState(false);
  const [isCheckInModel, setIsCheckInModel] = useState(false)
  const [checkInModelData, setCheckinModelData] = useState({})
  const [formData, setFormData] = useState({ ViewBy: "" });

  const DisplayCheckInModel = (type, id) => {
    setIsCheckInModel(true);
    const okrData = type === "critical" ? criticalOkrData : aspirationalOkrData;
    const okr = okrData.find((item, i) => item._id === id);
    console.log("clicked okr");
    console.log(okr);
    setCheckinModelData(okr);
  }
  useEffect(() => {
    if (formData.ViewBy === "All") {
      filterByStatus("All");
    }
    else if (formData.ViewBy === "on Track") {
      filterByStatus("T")
    }
    else if (formData.ViewBy === "Completed") {
      filterByStatus("C")
    }
    else if (formData.ViewBy === "at Risk") {
      filterByStatus("R")
    }
    else if (formData.ViewBy === "not Started") {
      filterByStatus("S")
    }
  }, [formData])
  return (
    <>
      <AnalyticContainer>
        <Header>
          <InputComponent placeholder='Search' onChange={(e) => filterBySearch(e.target.value)} search pLeft="8px" lg />
          <DashboardInputComponent placeholder='Sort By' value='All' >
            <FilterComponent name='ViewBy'
              formData={formData}
              setFormData={setFormData}

              options={[
                "All",
                "on Track",
                "Completed",
                "at Risk",
                "not Started"
              ]} />
          </DashboardInputComponent>
        </Header>
        <Line marginTop="8px" />

        <TabComponent headerText='Critical Okrs'>
          <InnerTabComponent
            onClicked={(type, id) => DisplayCheckInModel(type, id)}
            type="critical"
            status={true}
            okrs={dummyOkrAnalyticData}
            okrData={criticalOkrData}
            onClick={() => setModal((state) => !state)}
          />
        </TabComponent>
        <TabComponent headerText='Aspirational Okrs'>
          <InnerTabComponent
            onClicked={(type, id) => DisplayCheckInModel(type, id)}
            type="aspirational"
            status={true}
            okrs={dummyOkrAnalyticData}
            okrData={aspirationalOkrData}
            onClick={() => setModal((state) => !state)}
          />
        </TabComponent>
      </AnalyticContainer>
      <CheckInComponent
        modal={modal}
        onClick={() => setModal((state) => !state)}
      />
      {
        isCheckInModel && <CheckinModelComponent setIsCheckInModel={setIsCheckInModel} okr={checkInModelData} />
      }
    </>
  );
};

export default AnalyticsComponent;
