import React from "react";
import { Wrapper } from "../Objective/Objective.style";
import { OkrContainer } from "../Okr/Okr.style";
import CommonHeaderComponent from "../Objective/CommonHeader.component";
import InnerTabComponent from "./CollaborationTab"

function PopUpCollaboration(props) {
 
  return (
    <Wrapper modal>
      <OkrContainer maxWidth="1079px" modal>
        <CommonHeaderComponent
          setModal={props.setPopUp}
          isExpand={false}
          headerText={`Objective by Department ${props.department}`}
          placeholder="View by"
          info="OKR List..."
          filter={false}
        />
        <InnerTabComponent collaborators={props.collaborations || []} />
      </OkrContainer>
    </Wrapper>
  );
}

PopUpCollaboration.defaultProps = {
  objectiveData: {},
};
export default PopUpCollaboration;
