import React from 'react'

function DateComponent(props) {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M5.15289 12.627H3.0484C2.73371 12.627 2.47803 12.8826 2.47803 13.1973C2.47803 13.512 2.73371 13.7677 3.0484 13.7677H5.15289C5.46758 13.7677 5.72327 13.512 5.72327 13.1973C5.72327 12.8826 5.46758 12.627 5.15289 12.627Z" fill="#003D67"/>
        <path d="M9.20479 12.627H7.08063C6.76594 12.627 6.51025 12.8826 6.51025 13.1973C6.51025 13.512 6.76594 13.7677 7.08063 13.7677H9.18512C9.49981 13.7677 9.7555 13.512 9.7555 13.1973C9.7555 12.8826 9.51948 12.627 9.20479 12.627Z" fill="#003D67"/>
        <path d="M11.1319 13.7677H13.2364C13.5511 13.7677 13.8068 13.512 13.8068 13.1973C13.8068 12.8826 13.5511 12.627 13.2364 12.627H11.1319C10.8172 12.627 10.5615 12.8826 10.5615 13.1973C10.5615 13.512 10.8172 13.7677 11.1319 13.7677Z" fill="#003D67"/>
        <path d="M5.48743 4.13031V0.570376C5.48743 0.255686 5.23175 0 4.91706 0C4.60237 0 4.34668 0.255686 4.34668 0.570376V4.13031C4.34668 4.445 4.60237 4.70069 4.91706 4.70069C5.23175 4.70069 5.48743 4.445 5.48743 4.13031Z" fill="#003D67"/>
        <path d="M11.9386 4.13031V0.570376C11.9386 0.255686 11.6829 0 11.3682 0C11.0535 0 10.7979 0.255686 10.7979 0.570376V4.13031C10.7979 4.445 11.0535 4.70069 11.3682 4.70069C11.6829 4.70069 11.9386 4.445 11.9386 4.13031Z" fill="#003D67"/>
        <path d="M9.20479 7.31653H7.08063C6.76594 7.31653 6.51025 7.57221 6.51025 7.8869C6.51025 8.20159 6.76594 8.45728 7.08063 8.45728H9.18512C9.49981 8.45728 9.7555 8.20159 9.7555 7.8869C9.7555 7.57221 9.51948 7.31653 9.20479 7.31653Z" fill="#003D67"/>
        <path d="M10.5615 7.8869C10.5615 8.20159 10.8172 8.45728 11.1319 8.45728H13.2364C13.5511 8.45728 13.8068 8.20159 13.8068 7.8869C13.8068 7.57221 13.5511 7.31653 13.2364 7.31653H11.1319C10.8172 7.31653 10.5615 7.57221 10.5615 7.8869Z" fill="#003D67"/>
        <path d="M11.1319 11.1125H13.2364C13.5511 11.1125 13.8068 10.8568 13.8068 10.5421C13.8068 10.2274 13.5511 9.97174 13.2364 9.97174H11.1319C10.8172 9.97174 10.5615 10.2274 10.5615 10.5421C10.5615 10.8568 10.8172 11.1125 11.1319 11.1125Z" fill="#003D67"/>
        <path d="M7.0611 11.1125H9.16559C9.48028 11.1125 9.73597 10.8568 9.73597 10.5421C9.73597 10.2274 9.48028 9.97174 9.16559 9.97174H7.0611C6.74641 9.97174 6.49072 10.2274 6.49072 10.5421C6.49072 10.8568 6.74641 11.1125 7.0611 11.1125Z" fill="#003D67"/>
        <path d="M2.98981 11.1125H5.0943C5.40899 11.1125 5.66468 10.8568 5.66468 10.5421C5.66468 10.2274 5.40899 9.97174 5.0943 9.97174H2.98981C2.67512 9.97174 2.41943 10.2274 2.41943 10.5421C2.41943 10.8568 2.67512 11.1125 2.98981 11.1125Z" fill="#003D67"/>
        <path d="M14.1414 2.20282H13.1186C12.804 2.20282 12.5483 2.45851 12.5483 2.7732C12.5483 3.08789 12.804 3.34357 13.1186 3.34357H14.1414C14.6921 3.34357 15.1445 3.79594 15.1445 4.34665V14.4561C15.1445 15.0068 14.6921 15.4591 14.1414 15.4591H2.14383C1.59312 15.4591 1.14075 15.0068 1.14075 14.4561V4.34665C1.14075 3.79594 1.59312 3.34357 2.14383 3.34357H3.16657C3.48126 3.34357 3.73695 3.08789 3.73695 2.7732C3.73695 2.45851 3.48126 2.20282 3.16657 2.20282H2.14383C0.963739 2.20282 0 3.16656 0 4.34665V14.4561C0 15.6362 0.963739 16.5999 2.14383 16.5999H14.1414C15.3215 16.5999 16.2852 15.6362 16.2852 14.4561V4.34665C16.2852 3.16656 15.3215 2.20282 14.1414 2.20282Z" fill="#003D67"/>
        <path d="M10.1881 2.7732C10.1881 2.45851 9.93246 2.20282 9.61776 2.20282H6.66754C6.35285 2.20282 6.09717 2.45851 6.09717 2.7732C6.09717 3.08789 6.35285 3.34357 6.66754 3.34357H9.5981C9.93246 3.34357 10.1881 3.08789 10.1881 2.7732Z" fill="#003D67"/>
        </svg>
        
    )
}

export default DateComponent;
