import styled from 'styled-components';
import {
  boxShadow,
  flexCenter,
  flexColumn,
  myFlex,
} from '../../../utils/shared.style';

export const MyOkrHeader = styled.div`
  ${flexColumn};
  padding: 1rem;
  background: #fff;
  ${boxShadow};
  margin-bottom: 1.5rem;
  // overflow-y: scroll;
`;

export const User = styled.div`
  ${myFlex};
  ${flexCenter};
  flex-wrap: wrap;
  justify-content: space-between;
`;
