import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/Auth';
import { UserLevelScreenContainer } from './Objective.style';
import CommonHeaderComponent from './CommonHeader.component';
import { Wrapper, ObjectiveContainer, OkrStatus } from './Objective.style';

import { PieChart, Pie, Cell, Label } from 'recharts';

function UserLevelScreen(props) {
    console.log("User level screen");
    console.log(props);
    const { uid, filterByDepartment, filterByUser, manager, departmentId } = useAuth();

    console.log(filterByDepartment);

    const [onTrack, setOnTrack] = useState(0);
    const [atRisk, setAtRisk] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [notStarted, setNotStarted] = useState(0);

    const { okrData, handleOnClick } = props;

    const data = [
        { name: 'on Track', students: onTrack, status: 'T' },
        { name: 'not Started', students: notStarted, status: 'S' },
        { name: 'Completed', students: completed, status: 'C' },
        { name: 'at Risk', students: atRisk, status: 'R' }
    ];

    const COLORS = ["#099e60", "#b7b7b7", "#00b8e0", "#e95252"];

    useEffect(() => {
        let s = 0
        let t = 0
        let r = 0
        let c = 0
        if (manager && filterByUser.id && filterByUser.id !== 'alluser') {
            let data = okrData?.okrs.filter((item) => item.createdby === filterByUser.id);
            data.forEach((item) => {
                let status = item.status;
                if (status === "S")
                    s++;
                else if (status === "T")
                    t++;
                else if (status === "R")
                    r++;
                else
                    c++;
            })
            setCompleted(c);
            setAtRisk(r);
            setNotStarted(s);
            setOnTrack(t);

        }
        else if ((filterByDepartment.id && okrData.okrs) || manager) {
            let fit = filterByDepartment.id || departmentId;
            let data = okrData?.okrs.filter((item) => item.departmentid === fit);
            data.forEach((item) => {
                let status = item.status;
                if (status === "S")
                    s++;
                else if (status === "T")
                    t++;
                else if (status === "R")
                    r++;
                else
                    c++;
            })
            setCompleted(c);
            setAtRisk(r);
            setNotStarted(s);
            setOnTrack(t);

        }
        else if (okrData.okrs) {
            let data
            if (filterByUser.id)
                data = okrData?.okrs.filter((item) => item.createdby === filterByUser.id)
            else
                data = okrData?.okrs.filter((item) => item.createdby === uid);

            data.forEach((item) => {
                let status = item.status;
                if (status === "S")
                    s++;
                else if (status === "T")
                    t++;
                else if (status === "R")
                    r++;
                else
                    c++;
            })
            setCompleted(c);
            setAtRisk(r);
            setNotStarted(s);
            setOnTrack(t);
        }
    }, [okrData, filterByDepartment])

    return (
        <Wrapper flex="0.5">
            <ObjectiveContainer>

                <CommonHeaderComponent

                    headerText='Objective Status'

                />
                <div style={{ margin: "0 auto" }}>

                    <PieChart cx="0%" margin={{ top: 0, left: 0, right: 0, bottom: 0 }} cy="0%" width={200} height={150}>
                        <Pie data={data} dataKey="students" outerRadius="80%" innerRadius="60%" fill="grenn">
                            {data.map((entry, index) => <Cell onClick={() => handleOnClick(departmentId, entry.status, filterByDepartment.department )} fill={COLORS[index % COLORS.length]} />)}
                            <Label width={50} style={{ fontWeight: "500", fontSize: "1.5rem" }} position="center">
                                {`${notStarted + atRisk + completed + onTrack} ${`okr`}`}
                            </Label>
                        </Pie>
                    </PieChart>

                </div>


                <UserLevelScreenContainer>
                    <OkrStatus border="8px solid #099e60" first>
                        <div className="bodr"></div>
                        <div>{onTrack}</div>
                        <div >on Track</div>
                    </OkrStatus>
                    <OkrStatus border="8px solid #b7b7b7">
                        <div className="bodr"></div>
                        <div>{notStarted}</div>
                        <div >Not Started</div>
                    </OkrStatus>
                    <OkrStatus border="8px solid #00b8e0">
                        <div className="bodr"></div>
                        <div>{completed}</div>
                        <div >Completed </div>
                    </OkrStatus>
                    <OkrStatus border="8px solid #e95252">
                        <div className="bodr"></div>
                        <div> {atRisk}</div>
                        <div >At Risk</div>
                    </OkrStatus>
                </UserLevelScreenContainer>
            </ObjectiveContainer>


        </Wrapper>
    )
}

export default UserLevelScreen
