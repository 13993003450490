import React, { useState, useEffect } from "react";
import {
  DashboardHeader,
  DashboardText,
} from "../components/Dashboard/DashboardHeader/DashboardHeader.style";
import HeaderComponent from "../components/Header/Header.component";
import MainContainerComponent from "../components/Layouts/MainContainer/MainContainer.component";
import SettingsContainerComponent from "../components/Settings/SettingsContainer/SettingsContainer.component";
import { User } from "../api/Api";
import { useAuth } from "../context/Auth";
import axios from "axios";
import Loader from "../components/Loading/Loader";

const Settings = () => {
  const [allUser, setAllUser] = useState([]);
  const [filterUser, setFilterUser] = useState([]);
  const { idToken, uid } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    /*axios.get(User.getAllUser, {
      headers: {
        Authorization: `${idToken}`,
        uuid: `${uid}`
      }
    }).then(res => {
      let data = res.data.users;
      let temp = [];
      data.forEach((user) => {
        let reporting = user.reportingto;
        if (!user.reportingto) {
          temp.push({ ...user, reportingto: "" })
        }
        else {
          data.forEach(item => {
            if (item.uuid === reporting) { temp.push({ ...user, reportingto: item?.name }) }
          })
        }
      });
    setAllUser([...temp])
    setFilterUser([...temp]);
    setLoading(false);
  }

  ).catch(err => { console.log(err); setLoading(false) });*/
    const res = await axios.get(User.getAllUser, {
      headers: {
        Authorization: `${idToken}`,
        uuid: `${uid}`,
      },
    });

    const data = res.data.users;
    const temp = [];
    data.forEach((user) => {
      let reporting = user.reportingto;
      //if owner push data with empty reporting id
      if (!user.reportingto) {
        temp.push({ ...user, reportingto: "" });
      } else {
        data.forEach((item) => {
          if (item.uuid === reporting) {
            temp.push({
              ...user,
              reportingto: item?.name,
              managerid: reporting,
            });
          }
        });
      }
    });

    setAllUser([...temp]);
    setFilterUser([...temp]);
    setLoading(false);
  }, []);

  const searchUser = (val) => {
    setFilterUser(
      allUser.filter((item) => {
        return item.name.toLowerCase().includes(val.toLowerCase());
      })
    );
  };

  const filterByStatus = (val) => {
    let temp = [];
    allUser.forEach((item) => {
      if (item.status) {
        if (item.status === val) {
          temp.push(item);
        }
      } else {
        temp.push(item);
      }
    });
    setFilterUser([...temp]);
  };
  const sortByField = (val) => {
    allUser.sort((a, b) => {
      if (a.val > b.val) return 1;
      return -1;
    });
    setFilterUser([...allUser]);
  };
  return (
    <>
      <main>
        <HeaderComponent />
        <MainContainerComponent>
          <DashboardHeader>
            <DashboardText>Admin</DashboardText>
          </DashboardHeader>
          {loading ? (
            <Loader type="spin" color="cyan" />
          ) : (
            <SettingsContainerComponent
              filterByStatus={filterByStatus}
              sortByField={sortByField}
              setAllUser={setFilterUser}
              allUser={filterUser}
              searchUser={searchUser}
            />
          )}
        </MainContainerComponent>
      </main>
    </>
  );
};

export default Settings;
