import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ListDropdownComponent from '../../../Svgs/ListDropdown.component';
import { listStyle } from './helpers';
import {
  DropDownContainer,
  TabHeader,
  TabHeaderText,
  TabChildren,
} from './Tab.style';

/**
 *
 * @param {JSX.Element} props.children Dropdown Component
 * @param {string} props.headerText Header Text for Dropdown
 */

const TabComponent = ({ children, headerText = 'Critical OKR' }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TabHeader>
        {/* Dropdoen Icon */}
        <DropDownContainer onClick={() => setOpen((state) => !state)}>
          <ListDropdownComponent style={listStyle(open)} />
        </DropDownContainer>

        {/* Dropdown Header */}
        <TabHeaderText>{headerText}</TabHeaderText>
      </TabHeader>

      {/* Inner Dropdown Component  */}
      <CSSTransition in={open} classNames='node' timeout={300} unmountOnExit>
        <TabChildren >{children}</TabChildren>
      </CSSTransition>
    </>
  );
};

export default TabComponent;
