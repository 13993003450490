import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SingleOkrContainer, SingleOKrHeader, SingleOkrContent, Heading } from './SingleOkr.style';
import axios from 'axios';
import { useAuth } from '../../../context/Auth';
import ObjectiveComponent from '../../Svgs/Objective.component';
import { Button } from '../NewObjective/NewObjective.style';
import { Text } from '../../Objective/Department/Department.style';
import EditComponent from '../../Svgs/Edit.component';
import WarningComponent from '../../Svgs/Warning.component';
import { WarningContainer, } from '../../Dashboard/Okr/Tabs/Tab.style';
import SingleOkrTabComponent from './SingleOkrTabComponent';
import { okr, User, checkInHistory, updateOkr, department } from '../../../api/Api';
import CheckinModelComponent from '../../Dashboard/Okr/CheckinModel.component';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import NewKeyResult from '../NewObjective/NewKeyResult';
import CloseReqComponent from '../../Svgs/CloseReq.component';
import { toast } from 'react-toastify';
import Loader from '../../Loading/Loader';
import "react-toastify/dist/ReactToastify.css";
import NewObjectiveComponent from '../NewObjective/NewObjective.component';
import {
    NewObjective,
    // ObjectiveHeader,
    ObjectiveModal,
    // TabContainer,
} from "../NewObjective/NewObjective.style"
// import CheckInComponent from '../../Objective/CheckIn/CheckIn.component';
function getStatus(n) {
    if (n === "C")
        return "Completed"
    else if (n === "S")
        return "Not Started"
    else if (n === "T")
        return "on Track"
    else
        return "At Risk"
}

function getColor(n) {
    if (n === "C")
        return "#00B8E0"
    else if (n === "S")
        return "#B7B7B7"
    else if (n === "T")
        return "#099E60"
    else
        return "#E95252"
}

function SingleOkrComponent() {
    const { id } = useParams();
    const { uid, idToken } = useAuth()
    const [okrData, setOkr] = useState({});

    const [allOkrsInfo, setAllOkrsInfo] = useState(null); //store all okrs, collabs, departments
    const [allUsers, setUsers] = useState([]); //store all users

    const [allKeyResult, setKeyResult] = useState([]);
    const [loading, setLoading] = useState(true);
    const [checkin, setCheckin] = useState(false);
    const [checkIns, setCheckIns] = useState([]);
    const [lastUpdated, setLastUpdated] = useState("");
    const [createKey, setCreateKey] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [renderd, setRenderd] = useState(false);
    const [okrEditModal, setOkrEditModal] = useState(false); //toggle okr edit form
    const [keyResultData, setKeyResultData] = useState({
        title: "",
        type: "select",
        owner: "",
        weight: "",
        startValue: "",
        endValue: "",
        mileStone: [],
    });
    const [totalMileStone, setTotalMileStone] = useState([]);
    const [keyResultValidation, setKeyResultValidation] = useState({ title: false, type: false, weight: false, startValue: false, endValue: false });

    //fetch okr
    useEffect(() => {
        setLoading(true);
        axios.post(okr.singleOkr, { _id: id }, {
            headers: {
                Authorization: `${idToken}`,
            }
        }).then(res1 => {
            console.log("Log OKR");
            setOkr(res1.data.okr);
            //fetch all checkin history of okr
            axios.post(checkInHistory, { name: "Rahul" }, {
                headers: {
                    Authorization: `${idToken}`,
                    uuid: uid
                }
            }).then(res => {
                let temp = [];
                let updt = new Date(2030, 11, 24, 10, 33, 30, 0);
                let lst = false;

                res.data.data.forEach((item, idx) => {
                    let ti = item.checkin_data || item["checkin-data"];
                    for (const [key, value] of Object.entries(ti)) {
                        //if checking belongs to okr
                        if (key === id) {
                            temp.push({ ...item }); //store current okr checkin data 

                            //if time remaining between upd time and checkin time
                            if (updt.getTime() - (new Date(item.timestamp)).getTime()) {
                                updt = new Date(item.timestamp); //assign new time to update time
                                lst = true;
                            }
                        }
                    }
                    setCheckIns({ data: temp, okrs: [{ ...res1.data.okr, id: res1.data.okr._id }] });
                    if (lst)
                        setLastUpdated(new Date(updt));
                })
            }).catch(err => console.log(err));

        }).catch(err => console.log(err))

    }, [renderd]);

    //fetch users
    useEffect(async () => {
        //prevent looping
        if (allUsers.length) return;
        const res = await axios.get(User.getAllUser, {
            headers: {
                Authorization: `${idToken}`,
                uuid: uid
            }
        });
        setUsers(res.data.users);
    }, [allUsers]);

    //fetch users
    useEffect(async () => {
        //prevent looping
        if (allOkrsInfo) return;

        const res = await axios.post(okr.viewAllOkr, {
            status: ["S", "R", "T", "C"],
        }, {
            headers: {
                Authorization: `${idToken}`,
                uuid: uid
            }
        });
        setAllOkrsInfo(res.data);
        //setDepartments(res.data.departments);
    }, [allOkrsInfo]);


    const createKeyResult = async () => {
        if (!keyResultData.title || !keyResultData.type || keyResultData.startValue === "" || !keyResultData.endValue || !keyResultData.weight || keyResultData.type === "select") {
            toast.error("Some fields are empty", { autoClose: 3000 });
            let temp = keyResultValidation;
            if (!keyResultData.title)
                temp.title = true;
            if (!keyResultData.type || keyResultData.type === "select")
                temp.type = true;
            if (keyResultData.startValue === "")
                temp.startValue = true;
            if (!keyResultData.endValue)
                temp.endValue = true;
            if (!keyResultData.weight)
                temp.weight = true;
            setKeyResultValidation({ ...temp });

            return;
        }

        setLoadingUpdate(true);
        let keyrs = {
            title: keyResultData.title,
            measureType: keyResultData.type,
            startValue: parseFloat(keyResultData.startValue),
            targetValue: parseFloat(keyResultData.endValue),
            weight: parseInt(keyResultData.weight),
            // timeframe:keyResultData.timeframe?keyResultData.timeframe:[],
            milestones: [...totalMileStone],
            status: 'S'
        }
        // keyrs.milestones=totalMileStone;
        // keyrs.status="S";
        let prevKey;
        if (!okrData?.keyresults)
            prevKey = [];
        else {
            prevKey = okrData?.keyresults
        }

        const data = {
            objectives: {
                objective: okrData?.title,
                type: okrData?.type,
                timeframe: okrData?.timeframe,
                status: okrData?.status,
            },
            keyResults: [...prevKey, keyrs],
            UUID: okrData.owners[0],
            owners: okrData?.owners,
            linkedWith: okrData?.linkedWith,
            okrID: okrData?._id
        };

        // const data={keyResults:["temp1","temp2","temp3",],milestones:["temp1","temp2","temp3"],UUID:uid,owners:formData.owner,linkedWith:"user1",okrID:"123"}
        let objID;
        await axios
            .post(updateOkr, data, {
                headers: { Authorization: `${idToken}`, "Content-Type": "application/json;charset=UTF-8", },
            })
            .then((res) => {
                toast.success("Okr Updated", { autoClose: 3000 })
                setRenderd(!renderd);
            })
            .catch((err) => console.log(err));
        setLoadingUpdate(false);
        setCreateKey(false);
    };

    const [linked, setLinked] = useState("");
    const [owner, setOwner] = useState("");

    useEffect(async () => {

        if (okrData.type) {
            let owner = okrData?.owners[0];
            let own;

            await axios.get(User.getUserById(owner), {
                headers: {
                    Authorization: `${idToken}`,
                }
            }).then(res => {
                own = res.data.user.name
                let type = okrData.type;
                setOwner(own);
                let objId = okrData._id
                let timeframe = okrData.timeframe;
                let link = ""
                if (okrData.linkedwith) {
                    axios.post(okr.singleOkr, {
                        _id: okrData.linkedwith
                    }, {
                        headers: {
                            Authorization: `${idToken}`,
                        }
                    }).then(res => { setLinked(res.data.okr.title) }).catch(err => console.log(err))
                }
                let temp = okrData.keyresults.map(item => { return { _id: objId, progress: item.progress, timeframe: timeframe, owner: own, status: item.status || okrData.status, type: type, title: item.title, mileStones: item?.milestones } })
                setKeyResult(temp);
            }).catch(err => console.log(err));
            setLoading(false);
        }
    }, [okrData]);

    //update okr data
    const updateOkr = (updatedOkr) => {
        setOkr({
            ...okrData,
            title: updatedOkr?.objectives?.objective,
            type: updatedOkr?.objectives?.type,
            timeframe: updatedOkr?.objectives?.timeframe,
            status: updatedOkr?.objectives?.status,
            keyresults: updatedOkr?.keyResults
        });

        //setKeyResult(updatedOkr.keyResults);
    };

    return (
        <>
            {!loading ?
                <SingleOkrContainer>

                    <SingleOKrHeader>
                        <div className='firstDiv'>
                            <div className="firstDivChild">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <ObjectiveComponent />
                                    <h2 style={{ marginLeft: "7px" }}>{okrData?.title}</h2>
                                </div>
                                <div>
                                    <Button onClick={() => setCheckin(true)} main>
                                        Checkin
                                    </Button>
                                </div>
                            </div>
                            <div className="objInfo" style={{ marginTop: "15px" }}>
                                <Heading progress>
                                    <Text style={{ color: "grey", marginBottom: "10px" }} bold> Progress</Text>
                                    <div style={{ height: "150px", position: "relative", width: "150px" }}>
                                        <span style={{ position: "absolute", top: "50%", fontSize: "25px", color: `#000`, fontWeight: "600", left: "50%", transform: "translateX(-50%) translateY(-50%)" }}>{okrData.progress ? okrData.progress : "00.00"}%</span>
                                        <CircularProgressbar styles={buildStyles({
                                            // Rotation of path and trail, in number of turns (0-1)
                                            rotation: 0.25,

                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: 'butt',

                                            // Text size
                                            textSize: '22px',

                                            // How long animation takes to go from one percentage to another, in seconds
                                            pathTransitionDuration: 0.5,

                                            // Can specify path transition in more detail, or remove it entirely
                                            // pathTransition: 'none',

                                            // Colors
                                            pathColor: `#00B8E0`,
                                            textColor: '#f88',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#3e98c7',
                                        })}
                                            value={okrData.progress ? okrData.progress : 0} />
                                    </div >
                                </Heading>
                                {/* <Heading>
            <Text style={{color:"grey"}} bold> Timeline</Text>
            </Heading> */}
                                <Heading>
                                    <Text style={{ color: "grey" }} bold> Status</Text>
                                    <Button style={{ color: `${getColor(okrData.status)}`, borderColor: `${getColor(okrData.status)}`, borderRadius: "17px", marginTop: "10px", padding: "3px 10px" }}>{getStatus(okrData.status)}</Button>
                                </Heading>

                                <Heading start>
                                    <Text style={{ color: "grey", marginBottom: "10px" }} bold> Alerts</Text>
                                    <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", fontSize: "1.2rem" }}>
                                        <div style={{ display: "flex", }}>
                                            {/* {new Date().getTime() > new Date(new Date(lastUpdated).getMonth() + 1).getTime() ?
                                                (<><WarningContainer>
                                                    <WarningComponent />
                                                </WarningContainer>
                                                    Missed Check in</>) : ""
                                            } */}
                                            {""}
                                        </div>
                                        <div style={{ fontSize: "1.2rem", alignSelf: "end" }}>
                                            {/* 1 day ago */}
                                            {lastUpdated ? `${Math.ceil((new Date().getTime() - new Date(lastUpdated).getTime()) / (1000 * 60 * 60 * 24))} days ago` : ""}
                                        </div>
                                    </div>
                                    {/* <div></div> */}
                                </Heading>

                            </div>
                        </div>
                        <div className='secondDiv'>
                            <div style={{ flex: "1" }}>
                                <Heading start width>
                                    <h2>Owner</h2>
                                    <div style={{ fontSize: "1.5rem" }}>{owner}</div>
                                </Heading>
                            </div>
                            <div style={{ flex: "1" }}>
                                <Heading startwidth>
                                    <h2>Type</h2>
                                    <div style={{ fontSize: "1.5rem" }}>{okrData?.type}</div>
                                </Heading>
                            </div>
                            <div style={{ flex: "1" }}>
                                <Heading start width>
                                    <h2>Time Period</h2>
                                    <div style={{ fontSize: "1.5rem" }}>{(new Date(okrData?.timeframe[0])).toLocaleDateString()}  to  {(new Date(okrData?.timeframe[1])).toLocaleDateString()}</div>
                                </Heading>
                            </div>
                            <div style={{ flex: "1" }}>
                                <Heading start width>
                                    <h2>Last Updated</h2>
                                    <div style={{ fontSize: "1.5rem" }}>{lastUpdated ? `${Math.ceil((new Date().getTime() - new Date(lastUpdated).getTime()) / (1000 * 60 * 60 * 24))} days ago` : ""}</div>
                                </Heading>
                            </div>
                            {/**
            <div  style={{flex:"1"}}>
                <Heading  start width>
                <h2>Alignment</h2>
                <div style={{fontSize:"1.5rem"}}>{linked}</div>
                </Heading>
            </div> */}
                        </div>
                    </SingleOKrHeader>
                    {loadingUpdate && <Loader type="spin" color="cyan" />}
                    <SingleOkrContent >
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "-35px", position: "relative" }}>
                            <EditComponent onClick={() => setOkrEditModal(true)} style={{ margin: "auto 50px auto auto" }} />

                            {/* style={{ position: "absolute", bottom: "-200%", transform: "translateY(120%)" }}*/}
                            {/* <Button onClick={() => setCreateKey(true)} > + New Key Result</Button> */}
                        </div>
                        <SingleOkrTabComponent setCheckin={setCheckin} checkIns={checkIns} allKrs={allKeyResult} editable={false} />
                    </SingleOkrContent>

                    {checkin && <CheckinModelComponent setIsCheckInModel={setCheckin} okr={okrData} />}
                    {createKey &&
                        <ObjectiveModal>
                            <NewObjective overFlow={true}>
                                <div style={{ display: "flex", justifyContent: "flex-end" }} onClick={() => setCreateKey(false)}>
                                    <CloseReqComponent />
                                </div>
                                <NewKeyResult
                                    formData={keyResultData}
                                    setFormData={setKeyResultData}
                                    setTotalMileStone={setTotalMileStone}
                                    totalMileStone={totalMileStone}
                                    //    createKeyResult={createKeyResult}
                                    setModalComponent={setCreateKey}
                                    validation={keyResultValidation}
                                    setValidation={setKeyResultValidation}
                                    createKeyResult={createKeyResult}
                                />
                            </NewObjective>
                        </ObjectiveModal>
                    }
                    {
                        okrEditModal && (<NewObjectiveComponent
                            allUser={allUsers}
                            //filterUser={filterUser}
                            departments={allOkrsInfo ? allOkrsInfo.departments : []} //departments to display in collaboration department
                            setModal={() => setOkrEditModal(false)} //close update okr form
                            okrToEdit={okrData} //okr data to update
                            okrData={allOkrsInfo} //contains okrs to display in alignment collection
                            updateOkrState={updateOkr}
                        //render={render}
                        //setRender={setRender}
                        //trigger={trigger}
                        />)
                    }
                </SingleOkrContainer> : <Loader type="spin" color="cyan" />}

        </>)
}

export default SingleOkrComponent