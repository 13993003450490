import React,{useState} from 'react';
import ListDropdownComponent from '../../Svgs/ListDropdown.component';
import { IndexText } from '../Collaboration/Collaboration.style';
import { Line } from '../Objective/Objective.style';
import {
  ProgressContainer,
  RequestHeader,
  SortContainer,
  Progress,
} from './Request.style';
import { List } from './Request.style';

/**
 *
 * @param {string} props.headerText - Heading for Requests Tab
 * @param {string} props.index - New Request approved status
 * @param {string} props.sortBy - Sort request by
 * @param {string} props.width - Progress indicator
 * @returns {JSX.Element}
 */

const RequestHeaderComponent = ({
  headerText = 'Request',
  index = '28/29',
  sortBy = '',
  width = '90%',
  setSortBy=()=>{},
  checkin=false,
  request,
  approved,
  rejected,
  approv,
  total
}) => {
  const [display,setDisplay]=useState("none")
  console.log(approved,request,rejected);
  return (
    <>
      <RequestHeader>
        <h1>{headerText}</h1>
        <IndexText>{index}</IndexText>
        {checkin?<div style={{width:"30%",height:"5px",borderRadius:"1px",display:"flex"}} className="progresscheckin">
            <div  style={{width:`${approved}%`,backgroundColor:"#099E60",height:"100%"}}>
              
            </div>
            <div  style={{width:`${request}%`,backgroundColor:"#FFB61A",height:"100%"}}>
              
            </div>
            <div  style={{width:`${rejected}%`,backgroundColor:"#E95252",height:"100%"}}>
              
            </div>
          </div>:
        <ProgressContainer>
           <Progress className='progress' width={width} />
         
        </ProgressContainer>}
        <SortContainer onMouseOver={()=>setDisplay("block")} onMouseOut={()=>setDisplay("none")} >
          <b>Sort by: </b> {sortBy}
          <List display={display}>
            <div onClick={()=>{setSortBy("latest");setDisplay("none")}} className='listItem'>Latest</div>
            <Line/>
            {/* <div onClick={()=>{setSortBy("Approved");setDisplay("none")}} className='listItem'>Approved</div>
            <Line/>
            <div onClick={()=>{setSortBy("Rejected");setDisplay("none")}} className='listItem'>Rejected</div> */}
          </List>
          <ListDropdownComponent style={{ margin: 0 }} />
          
        </SortContainer>
      </RequestHeader>
    </>
  );
};

export default RequestHeaderComponent;
