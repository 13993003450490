import React from 'react';
import { SettingContentContainer } from '../SettingsContent/SettingsContent.component';
import { SettingContainer } from './Setting.style';

const SettingsContainerComponent = ({allUser,searchUser,filterByStatus,sortByField,setAllUser}) => {
  // console.log(allUser);
  return (
    <SettingContainer>
      <SettingContentContainer allUsers={allUser} setAllUser={setAllUser} searchUser={searchUser} filterByStatus={filterByStatus} sortByField={sortByField} />
    </SettingContainer>
  );
};

export default SettingsContainerComponent;
