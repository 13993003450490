import React, { useState, useContext } from 'react';
import { ModalContext } from '../../../context/Modal.context';
import Calendar from 'react-calendar';
import SearchComponent from '../../Svgs/Search.component';
import {
  Dates,
  DateContainer,
  FilterContainer,
  Input,
  InputContainer,
  InputPlaceholder,
  SearchContainer,
} from './DashboardHeader.style';
import DateComponent from '../../Svgs/Date.component'
import OptionComponent from './Option.component';
import OptionGroupComponent from './OptionGroup.component';
import 'react-calendar/dist/Calendar.css';
/**
 *
 * @param {array} props.options array of options
 * @param {string} props.name Name of option
 * @param {boolean} props.date shows calendar to choose start and end date
 */


const FilterComponent = ({ groupOption, name, minHeight = "", options, lg = true, date = false, validation = {}, setValidation = () => { }, search, setFormData = () => { }, formData = {}, component = "", height = "", special = false, id = [] }) => {
  
  console.log(`Name Received!, ${name}`);
  
  const [showCalender, setShowCalender] = useState('');
  const { selectedDate, setDates } = useContext(ModalContext);

  const getDate = () => {
    // console.log(data);
    const dt = new Date();
    let dat = dt.getDate();
    let mon = dt.getMonth() + 1;
    let year = dt.getFullYear();

    return `${dat}/${mon}/${year}`
    // showCalender==="start"?temp.date.startDate=dt:temp.date.endDate=dt;

  }
  const handleOnChange = (e, idd) => {
    let name = e.target.name;
    let val = e.target.value;

    console.log(`Name:${name} Value:${val}`);

    setValidation({ ...validation, [name]: false });

    if (idd)
      setFormData({ ...formData, [name]: val, id: idd });
    else
      setFormData({ ...formData, [name]: val })
  }
  return (
    <FilterContainer lg={lg} style={minHeight ? { minHeight: minHeight } : {}}>
      {
        search &&
        <InputContainer>

          <Input placeholder='Search' />
          <SearchContainer>
            <SearchComponent />
          </SearchContainer>
        </InputContainer>
      }

      {/* Replace idx with _id or unique name  */}
      {options?.length
        ? options.map((option, idx) => {
          if (groupOption) {
            return <OptionGroupComponent id={id.length ? id[idx] : ""} name={name} handleOnChange={handleOnChange} data={option} />
          } else {
            return <OptionComponent handleOnChange={handleOnChange} id={id.length ? id[idx] : ""} name={name} option={option} key={idx} />
          }
        })
        : ''}


      {date ? (
        <div style={{ maxHeight: `${component === "newObjective" ? "280px" : ""}` }}>
          <DateContainer>
            <Dates
              active={showCalender === 'start'}
              onClick={() => setShowCalender('start')}
            >
              <InputPlaceholder>Start Dates</InputPlaceholder>
              {component === "newObjective" ? <p style={{ fontWeight: "700", display: "flex" }}>{formData?.date?.startDate ? formData?.date?.startDate : getDate()}<span><DateComponent style={{ marginLeft: "8px" }} /></span></p> :
                <p style={{ fontWeight: "700", display: "flex" }}>{`${selectedDate.startDate[0]}/${selectedDate.startDate[1]}/${selectedDate.startDate[2]}`}  <span><DateComponent style={{ marginLeft: "8px" }} /> </span></p>
              }
            </Dates>
            <Dates
              active={showCalender === 'end'}
              onClick={() => setShowCalender('end')}
            >
              <InputPlaceholder>End Dates</InputPlaceholder>
              {component === "newObjective" ? <p style={{ fontWeight: "700", display: "flex" }}>{formData?.date?.endDate ? formData?.date?.endDate : getDate()}<span><DateComponent style={{ marginLeft: "8px" }} /></span></p> :
                <p style={{ fontWeight: "700", display: "flex" }}>{`${selectedDate.endDate[0]}/${selectedDate.endDate[1]}/${selectedDate.endDate[2]}`} <span><DateComponent style={{ marginLeft: "8px" }} /></span></p>
              }
            </Dates>
          </DateContainer>
          {(showCalender && component !== "newObjective") ? <Calendar onChange={(e) => setDates(e, showCalender === "start" ? "startDate" : "endDate")} /> : (showCalender && component === "newObjective") ? <Calendar onChange={(e) => {
            let temp = formData;
            setValidation({ ...validation, timeframe: false })
            temp.timeframe[showCalender === "start" ? 0 : 1] = e;

            let dat = e.getDate();
            let mon = e.getMonth() + 1;
            let year = e.getFullYear();

            let dt = `${dat}/${mon}/${year}`
            showCalender === "start" ? temp.date.startDate = dt : temp.date.endDate = dt;
            console.log(temp);
            setFormData({ ...temp });
          }} /> : ''}
        </div>
      ) : (
        ''
      )}

      
    </FilterContainer>
  );
};

export default FilterComponent;
