import React, { useState } from 'react';
import { Text } from '../../../utils/shared.style';
import ListDropdownComponent from '../../Svgs/ListDropdown.component';
import { Browse, InnerTab, ObjectiveHeader, Tab } from './NewObjective.style';
// import { ObjectiveHeader } from './NewObjective.style'

// Tab Component

const TabComponent = ({
  header,
  children,
  browse = true,
  setComp = () => { },
  HeaderChild,
  isOpen
}) => {
  const [open, setOpen] = useState(isOpen || false);
  return (
    <>
      <Tab>
        <ObjectiveHeader style={{ width: "80%", flexWrap: "nowrap", justifyContent: "start" }} onClick={() => setOpen((open) => !open)}>
          <ListDropdownComponent
            className='mr-2'
            style={{
              width: "23px",
              transform: `rotate(${open ? '0deg' : '-90deg'})`,
            }}
          />
          <Text style={{ whiteSpace: "wrap" }} bold>{header}</Text>
        </ObjectiveHeader>
        {browse ? (
          <Browse style={{ cursor: "pointer" }} onClick={() => setComp()}>
            <Text blue>
              <em>Browse Collections</em>
            </Text>
          </Browse>
        ) : (
          ''
        )}
        {HeaderChild ? <HeaderChild /> : ''}
      </Tab>
      <InnerTab>{open ? children : ''}</InnerTab>
    </>
  );
};

export default TabComponent;
