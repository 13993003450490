import React from 'react';
import { DashboardContent } from '../../Dashboard/DashboardContent/DashboardContent.style';
import AnalyticsComponent from '../Analytics/Analytics.component';
import ChartViewComponent from '../ChartView/ChartView.component';
import DepartmentComponent from '../Department/Department.component';

const ObjectiveContainerComponent = ({filterBySearch=()=>{},filterByStatus=()=>{},filterByDept=()=>{},filterByUser=()=>{}, chart,aspirationalOkrData,criticalOkrData,allUser=[],allDepartments=[],deptPercent={} }) => {
  
  return (
    <DashboardContent style={{maxWidth:"93vw",minHeight:"80vh" ,overflowX:"scroll"}}>
      {chart ? (
        <ChartViewComponent />
      ) : (
        <>
          <DepartmentComponent deptPercent={deptPercent} filterByDept={filterByDept}  filterByUser={filterByUser} allUser={allUser} allDepartments={allDepartments} />
          <AnalyticsComponent filterBySearch={filterBySearch} filterByStatus={filterByStatus} aspirationalOkrData={aspirationalOkrData} criticalOkrData={criticalOkrData} />
        </>
      )}
    </DashboardContent>
  );
};

export default ObjectiveContainerComponent;
