import React, { useEffect, useState } from 'react';
import InnerTabComponent from './Tabs/InnerTab.component';
import TabComponent from './Tabs/Tab.component';
import CommonHeaderComponent from '../Objective/CommonHeader.component';
import { Line, OkrContainer, OkrHeader, WrapperOkrContainer } from './Okr.style';
import { dummyOkrData } from './Tabs/helpers';
import { Wrapper } from '../Objective/Objective.style';
import useFullScreen from '../../../hooks/useFullScreen';

import { useAuth } from '../../../context/Auth';
import CheckinModelComponent from './CheckinModel.component';

import NewObjectiveComponent from "../../MyOkr/NewObjective/NewObjective.component";
/**
 * OKR Component containing tabs
 *
 */

const OkrComponent = ({ render = false, setRender = () => { }, okrData, startDate, endDate, allUser, filterUser }) => {


  const { uid, admin, filterByDepartment, filterByUser, manager, departmentId } = useAuth();

  const [modal, setModal] = useFullScreen();
  //store { okrs, departments } to send inside new objective form
  const [data, setData] = useState(okrData);
  const [criticalOkrData, setCriticalOkrData] = useState([]);
  const [aspirationalOkrData, setAspirationalOkrData] = useState([]);
  const [isCheckInModel, setIsCheckInModel] = useState(false)
  const [checkInModelData, setCheckinModelData] = useState({})
  const [formData, setFormData] = useState({ viewByOkr: "" });

  //edit okr
  const [editOkr, setEditOkr] = useState(null);
  const [okr, setOkr] = useState(null);
  const [openEditOkr, setOpenEditOkr] = useState(false);

  //insert the updated okr in okrs
  const updateOkr = (updatedOkr) => {

    const okrs = editOkr.type === "aspirational" ? aspirationalOkrData : criticalOkrData;
    const okrIndex = okrs.findIndex(okr => okr._id === updatedOkr.okrID);

    if (okrIndex > -1) {
      okrs[okrIndex] = {
        ...okrs[okrIndex],
        title: updatedOkr?.objectives?.objective,
        type: updatedOkr?.objectives?.type,
        timeframe: updatedOkr?.objectives?.timeframe,
        status: updatedOkr?.objectives?.status,
        keyresults: updatedOkr?.keyResults
      };

      (editOkr.type === "aspirational") ? setAspirationalOkrData(okrs) : criticalOkrData(okrs);
      console.log("updated data");
      console.log(data);
    }
  }

  //find okr to edit
  useEffect(() => {

    if (!editOkr) {
      setOpenEditOkr(false);
      //reset filter
      filterUser("all");
      return;
    };

    const okrs = editOkr.type === "aspirational" ? aspirationalOkrData : criticalOkrData;
    const okrToEdit = okrs.filter((okr) => okr._id === editOkr.id);
    if (okrToEdit && okrToEdit[0]) {
      setOkr(okrToEdit[0]);
      setOpenEditOkr(true);
    }

  }, [editOkr]);

  //Handle Checkin Model
  const DisplayCheckInModel = (type, id) => {
    setCheckinModelData(type === "critical" ? criticalOkrData.find((item, i) => item._id === id) : aspirationalOkrData.filter((item, i) => item._id === id));
    setIsCheckInModel(true);
  }

  useEffect(() => {
    const okrData = data;

    let critical;
    let aspirational;

    if (filterByDepartment.id && filterByDepartment.id !== "all" && okrData.okrs) {
      critical = okrData.okrs.filter(item => item.type === "Critical" && item.departmentid === filterByDepartment.id);
      aspirational = okrData.okrs.filter(item => item.type === "Aspirational" && item.departmentid === filterByDepartment.id);

      setCriticalOkrData([...critical]);
      setAspirationalOkrData([...aspirational]);
    }
    else if (okrData.okrs) {

      if (!admin && !manager) {
        critical = okrData.okrs.filter(item => item.type === "Critical" && item.createdby === uid);
        aspirational = okrData.okrs.filter(item => item.type === "Aspirational" && item.createdby === uid)
      }
      else if (manager) {
        if (filterByUser.id !== "alluser") {
          critical = okrData.okrs.filter((item) => item.type === "Critical" && item.createdby === filterByUser.id)
          aspirational = okrData.okrs.filter((item) => item.type === "Aspirational" && item.createdby === filterByUser.id)
        }
        else {
          critical = okrData.okrs.filter(item => item.type === "Critical" && item.departmentid === departmentId);

          aspirational = okrData.okrs.filter(item => item.type === "Aspirational" && item.departmentid === departmentId);
        }


      }
      else {
        if (filterByUser.id && filterByUser.id !== "alluser") {
          critical = okrData.okrs.filter((item) => item.type === "Critical" && item.createdby === filterByUser.id)
          aspirational = okrData.okrs.filter((item) => item.type === "Aspirational" && item.createdby === filterByUser.id)
        }
        else {
          critical = okrData.okrs.filter(item => item.type === "Critical");

          aspirational = okrData.okrs.filter(item => item.type === "Aspirational");
        }
      }

      //setData(okrData);
      setCriticalOkrData([...critical]);
      setAspirationalOkrData([...aspirational]);
      setData(data);
    }

  }, [data, filterByDepartment, filterByUser]);

  // useEffect(()=>{
  //   if(startDate&&endDate&&criticalOkrData&&aspirationalOkrData)
  //   {
  //   let critical=[];
  //   let aspirational=[];


  //   critical=okrData?.okrs?.filter(item=>item.type==="Critical");

  //       aspirational=okrData?.okrs?.filter(item=>item.type==="Aspirational");


  //       critical=critical?.filter((item)=>startDate<=(new Date(item.createdon))&&endDate>=(new Date(item.createdon)));
  //       aspirational=aspirational?.filter((item)=>startDate<=(new Date(item.createdon))&&endDate>=(new Date(item.createdon)));

  //           setCriticalOkrData([...critical]);
  //           setAspirationalOkrData([...aspirational])
  //   }


  // },[startDate,endDate])

  useEffect(() => {
    if (formData.viewByOkr) {
      if (formData.viewByOkr === "All Okr") {
        let critical = okrData.okrs.filter(item => item.type === "Critical");

        let aspirational = okrData.okrs.filter(item => item.type === "Aspirational");

        setCriticalOkrData([...critical]);
        setAspirationalOkrData([...aspirational]);
      }
      else {
        setCriticalOkrData(criticalOkrData.filter((item) => item.createdby === uid));
        setAspirationalOkrData(aspirationalOkrData.filter((item) => item.createdby === uid));
      }
    }
  }, [formData])



  return (
    <>
      <WrapperOkrContainer>
        <Wrapper flex="1" modal={modal}>

          <OkrContainer modal={modal}>
            {/*Header*/}
            <CommonHeaderComponent
              headerText='OKRs'
              placeholder='View by'
              info='OKR List...'
              onClick={setModal}
              modal={modal}
              setFormData={setFormData}
              formData={formData}
              name="viewByOkr"
              options={["All Okr", "MyOkr"]}
              filter={false}
            />
            <OkrHeader>OKRs</OkrHeader>
            {/* Line-Seperator */}
            <Line />

            {/* Main Tab  */}

            <TabComponent>
              {/* Inner Tab  */}
              <InnerTabComponent onClicked={DisplayCheckInModel} editable={true} onEdit={(id) => setEditOkr({ id, type: "critical" })} type="critical" okrData={criticalOkrData} okrs={dummyOkrData} />
            </TabComponent>
            <TabComponent headerText='Aspirational OKR'>
              <InnerTabComponent onClicked={DisplayCheckInModel} editable={true} onEdit={(id) => setEditOkr({ id, type: 'aspirational' })} type="aspirational" okrData={aspirationalOkrData} okrs={dummyOkrData} />
            </TabComponent>
          </OkrContainer>

        </Wrapper>
      </WrapperOkrContainer>
      {
        isCheckInModel && <CheckinModelComponent Render={render} SetRender={setRender} setIsCheckInModel={setIsCheckInModel} okr={checkInModelData} />
      }
      {
        openEditOkr && (<NewObjectiveComponent
          allUser={allUser}
          filterUser={filterUser}
          departments={data.departments} //departments to display in collaboration department
          setModal={() => setEditOkr(null)} //close update okr form
          okrToEdit={okr} //okr data to update
          okrData={data} //contains okrs to display in alignment collection
          updateOkrState={updateOkr}
        //render={render}
        //setRender={setRender}
        //trigger={trigger}
        />)
      }
    </>
  );
};

export default OkrComponent;