import React, { useState,useEffect } from 'react';
import DashboardHeaderComponent from '../components/Dashboard/DashboardHeader/DashboardHeader.component';
import HeaderComponent from '../components/Header/Header.component';
import MainContainerComponent from '../components/Layouts/MainContainer/MainContainer.component';
import MyOkrContainerComponent from '../components/MyOkr/MyOkrContainer/MyOkr.component';
import NewObjectiveComponent from '../components/MyOkr/NewObjective/NewObjective.component';
import { useLocation } from 'react-router-dom';
import { okr,User,checkInHistory } from '../api/Api';
import { useAuth } from '../context/Auth';
import Loader from '../components/Loading/Loader';
import axios from 'axios';
const MyOkr = () => {
  const {idToken,uid}=useAuth();
  const [allOkr,setAllOkr]=useState([]);
  const [criticalOkrData,setCriticalOkrData]=useState([]);
  const [aspirationalOkrData,setAspirationalOkrData]=useState([]);
  const [modal, setModal] = useState(false);
  const [userData,setUserData]=useState({});
  const {state}=useLocation();
  const [checkIn,setCheckIn]=useState({});

  const [loading,setLoading]=useState(false);

  const filterByTime=(val)=>{
   let st=new Date(val[0]);
   let ed=new Date(val[1]);

   let critic=allOkr.filter((item)=>item.type==="Critical")
   let aspi=allOkr.filter((item)=>item.type==="Aspirational")
   setCriticalOkrData(critic.filter((item)=>st<=(new Date(item.createdon))&&ed>=(new Date(item.createdon))))
   setAspirationalOkrData(aspi.filter((item)=>st<=(new Date(item.createdon))&&ed>=(new Date(item.createdon))))

  }
const [render,setRender]=useState(true)

  useEffect(()=>{
    const getData=async()=>{
      setLoading(true);
      await axios.post(okr.viewAllOkr,{createdby:state?.id?state.id:uid,
        status: ["S", "R", "T", "C"]},{headers:{authorization:`${idToken}`}}).then(res=>{
          setAllOkr(res.data.okrs);
          let critical=res.data.okrs.filter(item=>item.type==="Critical");
          
          let aspirational=res.data.okrs.filter(item=>item.type==="Aspirational");
          
          setCriticalOkrData([...critical]);
          setAspirationalOkrData([...aspirational]);
          }).catch(err=>console.log(err));
  
          axios.get(User.getUserById(state?.id?state.id:uid),{headers:{Authorization:`${idToken}`}}).then(res=>{
            setUserData({...res.data.user,reportingto:res.data.approval.name});
            
           
      
            
          }).catch(err=>console.log(err));
          axios.post(checkInHistory,{name:"rahul"},{
            headers:{
              Authorization:`${idToken}`
            }
          }).then(res=>{
            let temp;
            console.log(res);
            temp=res.data.data.filter(item=>item?.userdetails[0].uuid===(state?.id?state.id:uid));
            setCheckIn({...res.data,data:temp});
          
          }
            
            ).catch(err=>console.log(err));
            setLoading(false);
      
    }
          getData();
  },[render])
  console.log(checkIn);
  console.log(userData);
  return (
    <>
      <main>
        <HeaderComponent />
        <MainContainerComponent>
          <DashboardHeaderComponent
            input={false}
            showObjectiveModal={() => setModal((state) => !state)}
            headerText="My OKRs"
          
          />
          {loading?<Loader type="spin" color="cyan" />:
          <MyOkrContainerComponent checkIn={checkIn} render={render} setRender={setRender} filterByTime={filterByTime} userData={userData} criticalOkrData={criticalOkrData} aspirationalOkrData={aspirationalOkrData} />
          }
          </MainContainerComponent>
      </main>
      {modal ? (
        <NewObjectiveComponent setModal={() => setModal((state) => !state)} />
      ) : (
        ''
      )}
    </>
  );
};

export default MyOkr;
