import styled from 'styled-components';
import { boxShadow, flexColumn, myFlex } from '../../../utils/shared.style';
import AlignmentComponent from './Alignment.component';

export const ObjectiveModal = styled.section`
  position:absolute;
  top: 0;
  left: 0;
  // min-height: 100%;
  right:0;
  // width: 100vw;
  // bottom:0;
  height:100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
`;

export const NewObjective = styled.div`
  // margin: 25vh auto;
  position:absolute;
  top:50vh;
  left:50%;
  transform:translateX(-50%) translateY(-50%);
  background: white;
  border-radius: 5px;
  max-height:95vh;
  
  // min-height:70vh;
  // overflow:auto;
  ${({overFlow})=>overFlow&&`overflow-Y:auto;`}
  
  ${boxShadow};
  padding: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 85%;
  }
`;

export const ObjectiveHeader = styled.div`
  ${myFlex};
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Tab = styled.div`
  ${myFlex};
  align-items: center;
  line-height: 2;
  justify-content: space-between;
`;

export const Browse = styled.div`
  ${myFlex};
  justify-content: flex-end;
`;

export const TabContainer = styled.div`
  ${flexColumn};
`;

export const InputContainer = styled.div`
  width: ${(props) => (props?.lg ? '100%' : '30%')};
  margin: 1rem 0.5rem 1rem 0;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const InnerTab = styled.div`
  padding: 0 2rem;
  line-height: 2;
`;

export const Button = styled.button`
  padding: 5px;
  min-width: 8rem;
  margin-right: ${({marginRight})=>marginRight?"0px":"0.5rem"};
  cursor: pointer;
  color: ${(props) =>
    !props?.main ? props.theme?.blueDarkSecondary : 'white'};
  border: ${(props) => `1px solid ${props.theme.blueDarkSecondary}`};
  border-radius:${({borderRadius})=>borderRadius?borderRadius:"5px"} ;
  background: ${(props) =>
    props?.main ? props.theme?.blueDarkSecondary : 'white'};
  outline: none;
  ${myFlex};
  align-items: center;
  ${({bg})=>console.log(bg)}
  background-color:${({bg})=>bg?bg:""};
  justify-content: center;
`;
export const AlignmentButton=styled.div`

display:flex; 
flex-direction:row;
justify-content:flex-end;
margin-top:1rem;




`
export const MileStones=styled.div`
    font-size:1.2rem;
    position:relative;

    border:1px solid #d1cdcd;
    margin-bottom:5px ;
    padding:3px 10px;
    div{
      position:absolute;
      top:50%;
      right:10px;
      transform:translateY(-50%);

    }

`

export const ChooseCollab=styled.div`
margin-right:17px;

border:1px solid #d1cdcd;
position:relative;
padding:1px 11px;
font-size:1.2rem;
div{
  position:absolute;
  top:-8px;
  right:-8px;
border-radius:50%;
height:20px;
text-align:center;
display:table-cell;
    vertical-align:middle;   
width:20px;
display:flex;
justify-content:center;
align-items:center;
padding-bottom:-10px;


background-color:#d1cdcd;


}


`
export const CollabList=styled.div`
display:flex;
`
export const Search = styled.div`
  ${myFlex};
  padding: 1rem 0;
  align-items: center;
  flex-wrap: wrap;
`;

export const ButtonContainer = styled.div`
  margin: 8rem 0 0 0;
`;
