import React, { useState } from "react";
import { Text } from "../../../utils/shared.style";
import BackComponent from "../../Svgs/Back.component";
import CloseReqComponent from "../../Svgs/CloseReq.component";
import ObjectiveComponent from "../../Svgs/Objective.component";
import { TabHeaderContainer, TabHeader } from "../MyOkrTab/MyOkrTab.style";
import AlignmentComponent from "./Alignment.component";
import ColloborationComponent from "./Colloboration.component";
import KeyResultComponent from "./KeyResult.component";
import NewKeyResult from "./NewKeyResult";
import axios from "axios";
import { useAuth } from "../../../context/Auth";
import { okr, collabortion, updateOkr } from "../../../api/Api";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Loader from "../../Loading/Loader";
import {
  NewObjective,
  ObjectiveHeader,
  ObjectiveModal,
  TabContainer,
} from "./NewObjective.style";
import OverviewComponent from "./Overview.component";
import { useEffect } from "react";

const tabs = [OverviewComponent, KeyResultComponent];

const NewObjectiveComponent = ({
  setModal,
  departments, //departments
  allUser = [],
  filterUser = () => {},
  okrData = [], //containrs okrs and departments
  updateOkrState, //update okrs
  render = true,
  setRender = () => {},
  trigger = () => {},
  okrToEdit, //single okr to update
}) => {
  const changeFormat = (date) => {
    let dat = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let mon =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    let year = date.getFullYear();
    return `${year}-${mon}-${dat}`;
  };
  toast.configure();
  const [tab, setTab] = useState(0);
  const { uid, idToken } = useAuth();
  const [validation, setValidation] = useState({
    title: false,
    timeframe: false,
    Okr: false,
  });
  const [modalComponent, setModalComponent] = useState("initial");
  const [overFlow, setOverFlow] = useState(true);
  const [EditKeyResult, setEditKeyResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [objective, setObjectiveData] = useState({
    Okr: okrToEdit?.type || "select",
    title: okrToEdit?.title || "",
    teams: "select",
    checkIn: okrToEdit?.checkin || "select",
    timeframe: okrToEdit?.timeframe || [],
    owner: okrToEdit?.owners ? okrToEdit?.owners[0] : "",
    date: {
      startDate: okrToEdit?.timeframe
        ? changeFormat(new Date(okrToEdit?.timeframe[0]))
        : "",
      endDate: okrToEdit?.timeframe
        ? changeFormat(new Date(okrToEdit?.timeframe[1]))
        : "",
    },
    visible: okrToEdit?.visible || "select",
  });
  const [keyResult, setKeyResult] = useState({
    title: "",
    type: "select",
    owner: "",
    weight: "",
    startValue: "",
    endValue: "",
    mileStone: [],
  });
  const [totalMileStone, setTotalMileStone] = useState([]);
  const [alignment, setAlignment] = useState({});

  const deleteCollab = (id) => {
    setCollaboration(collaboration.filter((item) => item.id !== id));
  };

  const [collaboration, setCollaboration] = useState(
    okrToEdit?.collaboration || []
  );

  //map kr fields and store keyResults
  const [totalKeyResult, setTotalKeyResult] = useState(
    okrToEdit?.keyresults || []
  );

  const [keyResultValidation, setKeyResultValidation] = useState({
    title: false,
    type: false,
    weight: false,
    startValue: false,
    endValue: false,
  });

  const createKeyResult = () => {
    if (
      !keyResult.title ||
      !keyResult.type ||
      keyResult.startValue === "" ||
      !keyResult.endValue ||
      !keyResult.weight ||
      keyResult.type === "select"
    ) {
      toast.error("Some fields are empty", { autoClose: 3000 });
      let temp = keyResultValidation;
      if (!keyResult.title) temp.title = true;
      if (!keyResult.type || keyResult.type === "select") temp.type = true;
      if (keyResult.startValue === "") temp.startValue = true;
      if (!keyResult.endValue) temp.endValue = true;
      if (!keyResult.weight) temp.weight = true;
      setKeyResultValidation({ ...temp });

      return;
    }
    if (EditKeyResult) {
      setTotalKeyResult(
        totalKeyResult.map((item, idx) =>
          idx === editId
            ? {
                title: keyResult.title,
                measureType: keyResult.type,
                startValue: parseFloat(keyResult.startValue),
                targetValue: parseFloat(keyResult.endValue),
                weight: parseInt(keyResult.weight),
                milestones: [...totalMileStone],
                status: "S",
              }
            : { ...item }
        )
      );
    } else {
      setTotalKeyResult([
        ...totalKeyResult,
        {
          title: keyResult.title,
          measureType: keyResult.type,
          startValue: parseFloat(keyResult.startValue),
          targetValue: parseFloat(keyResult.endValue),
          weight: parseInt(keyResult.weight),
          milestones: [...totalMileStone],
          // timeframe:keyResult.timeframe?keyResult.timeframe:[],
          status: "S",
        },
      ]);
    }
    setTotalMileStone([]);
    setModalComponent("initial");
    setKeyResult({
      title: "",
      type: "select",
      owner: "",
      weight: "",
      startValue: "",
      endValue: "",
      mileStone: [],
    });
    // setModalComponent("Alignment");
    setTab(1);
    setEditKeyResult(false);
  };
  const editKeyResult = (data, idx) => {
    setEditId(idx);
    setKeyResult({
      title: data?.title,
      type: data?.measureType || data?.measuretype,
      weight: data?.weight,
      startValue: data?.startValue || data?.startvalue,
      endValue: data?.targetValue || data?.targetvalue,
      status: data?.status || "S",
      milestones: data?.milestones,
    });
    setEditKeyResult(true);
    setModalComponent("newKey");
    setTotalMileStone([...data?.milestones]);
    setKeyResultValidation({
      title: false,
      type: false,
      weight: false,
      startValue: false,
      endValue: false,
    });
  };

  //create and update objective okr
  const createObjectiveOkr = async () => {
    setLoading(true);
    const data = {
      objectives: {
        objective: objective.title,
        type: objective.Okr,
        timeframe: objective.timeframe,
        status: "S",
      },
      keyResults: totalKeyResult,
      collaboration: collaboration, //not working
      UUID: uid,
      owners: [uid],
      linkedWith: okrToEdit?.linkedWith || alignment.id,
      ...(okrToEdit ? { okrID: okrToEdit._id } : {}),
    };

    //post request to create new objective
    let objID;
    const apiURL = okrToEdit ? updateOkr : okr.addOkr; //if content is editable then use update api
    await axios
      .post(apiURL, data, {
        headers: {
          Authorization: `${idToken}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        //add collaboration on new objective
        objID = res.data.okrID;
        if (collaboration.length) {
          for (let i = 0; i < collaboration.length; i++) {
            let dt = {};

            dt = { ...dt, [objID]: collaboration[i].id };

            const payload = { ...dt };
            axios
              .post(collabortion.postCollaborations, payload, {
                headers: {
                  Authorization: `${idToken}`,
                  uuid: uid,
                },
              })
              .then((res) => console.log(res))
              .catch((err) => console.log(err));
          }
        }


        if (Object.keys(alignment).length) {
          
            let dt = { uuid:uid, objID: objID };
            axios
              .post(collabortion.postAlignments, dt, {
                headers: {
                  Authorization: `${idToken}`,
                  uuid: uid,
                },
              })
              .then((res) => console.log(res))
              .catch((err) => console.log(err));
          }

        //if update
        if (okrToEdit) {
          updateOkrState(data);
        }

        trigger();
        setRender(!render);
        setModal(false);
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const setComp = (name) => setModalComponent(name);

  const InitialComp = () => (
    <>
      <TabHeaderContainer>
        <TabHeader active={!tab} onClick={() => setTab(0)}>
          Overview
        </TabHeader>
        <TabHeader active={tab} onClick={() => setTab(1)}>
          Key Result
        </TabHeader>
      </TabHeaderContainer>

      {/* Overview Key Result Tab  */}

      <TabContainer>
        {tabs?.length
          ? tabs.map((Tab, idx) =>
              idx === tab ? (
                <Tab
                  collaboration={collaboration}
                  createObjectiveOkr={createObjectiveOkr}
                  formData={idx === 1 ? keyResult : objective}
                  setFormData={idx === 1 ? setKeyResult : setObjectiveData}
                  key={idx}
                  setComp={setComp}
                  totalKeyResult={totalKeyResult}
                  deleteData={deleteCollab}
                  alignment={alignment}
                  setTab={setTab}
                  validation={validation}
                  setValidation={setValidation}
                  editKeyResult={editKeyResult}
                />
              ) : (
                ""
              )
            )
          : ""}
      </TabContainer>
    </>
  );
  useEffect(() => {
    if (
      modalComponent === "Alignment" ||
      modalComponent === "Collab" ||
      modalComponent === "initial"
    ) {
      setOverFlow(false);
    } else {
      setOverFlow(true);
    }
  }, [modalComponent]);
  return (
    <ObjectiveModal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
      <NewObjective overFlow={overFlow}>
        {/* Modal Header containing Back and Close Button  */}
        <ObjectiveHeader>
          <ObjectiveHeader>
            {modalComponent === "initial" ? (
              <ObjectiveComponent className="mr-2" />
            ) : (
              // Back button
              <BackComponent
                onClick={() => setModalComponent("initial")}
                className="mr-2"
              />
            )}
            <Text bold>{okrToEdit ? "Update Objective" : "New Objective"}</Text>
          </ObjectiveHeader>
          {/* Close Button  */}
          <div onClick={setModal}>
            <CloseReqComponent />
          </div>
        </ObjectiveHeader>
        {loading && <Loader type="spin" color="cyan" />}
        {modalComponent === "initial" ? InitialComp() : ""}
        {/* Allignment modal */}
        {modalComponent === "Alignment" ? (
          <AlignmentComponent
            formData={alignment}
            setModalComponent={setModalComponent}
            setFormData={setAlignment}
            okrData={okrData}
          />
        ) : (
          ""
        )}
        {/* Colloboration Modal  */}
        {modalComponent === "Collab" ? (
          <ColloborationComponent
            formData={collaboration}
            setFormData={setCollaboration}
            departments={departments}
            allUser={allUser}
            filterUser={filterUser}
          />
        ) : (
          ""
        )}
        {/* New Key Modal  */}
        {modalComponent === "newKey" ? (
          <NewKeyResult
            createObjectiveOkr={createObjectiveOkr}
            formData={keyResult}
            setFormData={setKeyResult}
            setTotalMileStone={setTotalMileStone}
            totalMileStone={totalMileStone}
            createKeyResult={createKeyResult}
            setModalComponent={setModalComponent}
            validation={keyResultValidation}
            setValidation={setKeyResultValidation}
            EditKeyResult={EditKeyResult}
          />
        ) : (
          ""
        )}
      </NewObjective>
    </ObjectiveModal>
  );
};

export default NewObjectiveComponent;
