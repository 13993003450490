import React from 'react';
import OkrTab from './OkrTab.component';
import ActivityLog from './ActivityLog.component';

import { MyOkrHeader } from '../MyOkrHeader/MyOkrHeader.style';
import useTab from '../../../hooks/useTab';

const tabs = [OkrTab, ActivityLog];
const tabContent = ['OKRs', 'Activity Log'];

// OKR ACTIVITY TAB COMPONENT

const MyOkrTabComponent = ({aspirationalOkrData,criticalOkrData,render,setRender,checkIn={}}) => {
 
  const { showTabs } = useTab(0, tabContent, tabs);
  return <MyOkrHeader>{showTabs(criticalOkrData,aspirationalOkrData,[],[],()=>{},()=>{},[],render={render} ,setRender={setRender},checkIn={checkIn})}</MyOkrHeader>;
};

export default MyOkrTabComponent;
