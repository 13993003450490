import styled from 'styled-components';
import { boxShadow, flexColumn, myFlex } from '../../../utils/shared.style';

export const AnalyticContainer = styled.section`
  ${myFlex};
  ${flexColumn};
  flex-grow: 1;
  padding: 1rem;
  min-height: 80vh;
  ${boxShadow};
  margin-left: 1rem;
`;
