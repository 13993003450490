import React from 'react';
import ModalComponent from '../../Modal/Modal.component';
import { Department, DepartmentText, Line } from '../Objective/Objective.style';
import { dummyColloboratData } from '../Okr/Tabs/helpers';
import { BarGraph } from './Collaboration.component';
import { Circle } from './Collaboration.style';
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';

const ColloborationModelComponent = ({ modal, onClick,tempDepartmentName=[],graphData=[],collabIdx="" }) => {
  
  return (
    <ModalComponent
      headerText='Collaboration Index'
      placeholder='Show'
      collab={true}
      modal={modal}
      onClick={onClick}
      OverallComponent={() => (
        <>
          <div style={{height:"150px",position:"relative",width:"150px"}}>
            <span style={{position:"absolute",top:"50%",fontSize:"25px",color:`#000`,fontWeight:"600",left:"50%",transform:"translateX(-50%) translateY(-50%)"}}>{parseFloat(collabIdx).toFixed(2)}</span>
        <CircularProgressbar styles={buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 0.25,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'butt',

    // Text size
    textSize: '22px',

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',

    // Colors
    pathColor: `#00B8E0`,
    textColor: '#f88',
    trailColor: '#d6d6d6',
    backgroundColor: '#3e98c7',
  })}
 value={collabIdx*100}/>
 </div >

          <DepartmentText>Overall Index</DepartmentText>
        </>
      )}
      DepartmentComponent={() => (
        <>
          <BarGraph style={{style:"space-around"}} ismodel={true} graphData={graphData} />
          <Line />
          <Department style={{style:"flex-start"}}>
            {
              tempDepartmentName?.map((item,index)=> <DepartmentText style={{marginLeft:`${index!==0?"22px":"0px"}`}}>{item}</DepartmentText>)
            }
           
            
          </Department>
        </>
      )}
    />
  );
};

export default ColloborationModelComponent;
