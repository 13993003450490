import DashboardComponent from '../../Svgs/Dashboard.component';
import LogsComponent from '../../Svgs/Logs.component';
import ObjectivesComponent from '../../Svgs/Objectives.component';
import OkrComponent from '../../Svgs/Okr.component';
import SettingComponent from '../../Svgs/Setting.component';


const navItems =(admin)=> [
  {
    path: '/',
    Component: DashboardComponent,
    name:'DASHBOARD'
  },
  {
    path: '/okr',
    Component: OkrComponent,
    name:'MY OKRS'
  },
  {
    path: '/objective',
    Component: ObjectivesComponent,
    name:'OBJECTIVES'
  },
  {
    path: '/logs',
    Component: LogsComponent,
    name:'LOGS'
  },
  {
    path: '/settings',
    Component: SettingComponent,
    name:'ADMIN'
  },
  
];

export default navItems;
