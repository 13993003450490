import React from 'react';
import {
  ProgressBarContainer,
  ProgressText,
  ProgressPercentage,
} from './Tab.style';
const ProgressBar = ({ width, widthContainer,text = true }) => {
  return (
    <ProgressBarContainer>
      {text ? <ProgressText>On Track</ProgressText> : ''}
      <ProgressPercentage width={width || '30%'} bg='#1db535' />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
