import React from 'react'

function BackArrowComponent(props) {
    return (
       
        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>props.setShowText(!props.showText)}>
        <path d="M18.7793 6.28536H4.17294L8.27703 2.13063C8.39493 2.01696 8.48917 1.88057 8.55422 1.72947C8.61926 1.57838 8.6538 1.41564 8.65578 1.25083C8.65777 1.08603 8.62716 0.922482 8.56578 0.769827C8.50439 0.617173 8.41346 0.478492 8.29834 0.361947C8.18321 0.245403 8.04622 0.153349 7.89543 0.0912053C7.74463 0.0290613 7.58308 -0.00191768 7.42028 9.1862e-05C7.25749 0.0021014 7.09673 0.0370588 6.94748 0.102907C6.79823 0.168754 6.66349 0.264162 6.55121 0.383513L0.363504 6.64757C0.248444 6.76129 0.157011 6.89715 0.0945853 7.04717C0.032159 7.19719 0 7.35834 0 7.52112C0 7.68391 0.032159 7.84506 0.0945853 7.99508C0.157011 8.14509 0.248444 8.28096 0.363504 8.39468L6.53016 14.6374C6.64337 14.7524 6.77786 14.8435 6.92593 14.9058C7.07401 14.968 7.23275 15 7.39307 15C7.5534 15 7.71214 14.968 7.86022 14.9058C8.00829 14.8435 8.14278 14.7524 8.25599 14.6374C8.37105 14.5237 8.46248 14.3878 8.52491 14.2378C8.58733 14.0878 8.61949 13.9267 8.61949 13.7639C8.61949 13.6011 8.58733 13.4399 8.52491 13.2899C8.46248 13.1399 8.37105 13.004 8.25599 12.8903L4.17294 8.75689H18.7793C19.103 8.75689 19.4135 8.62669 19.6425 8.39494C19.8714 8.16319 20 7.84887 20 7.52112C20 7.19338 19.8714 6.87906 19.6425 6.64731C19.4135 6.41555 19.103 6.28536 18.7793 6.28536Z" fill="white"/>
        </svg>
        
    )
}

export default BackArrowComponent
