import styled, { css } from 'styled-components';

export const myFlex = css`
  display: flex;
`;

export const flexColumn = css`
  ${myFlex};
  flex-direction: column;
`;

export const flexCenter = css`
  justify-content: center;
  align-items: center;
`;

export const TextCss = css`
  font-size: ${(props) => (props.lg ? '1.6rem' : '1.4rem')};
  font-weight: ${(props) => (props.bold ? 600 : 300)};
  color: ${(props) =>
    props.blue
      ? props.theme.blueDarkSecondary
      : props?.light
      ? props.theme.greyDark4
      : 'inherit'};
`;

export const Text = styled.h1`
  ${TextCss}
  color:${(props)=>(props.color?props.color:"")}
 
`;

export const boxShadow = css`
  background: #fff;
  box-shadow: 0px 4px 4px rgba(187, 187, 187, 0.5);
`;
