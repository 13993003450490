import React from 'react';

// import {WrapperDiv,Option} from '../../../Modal/Modal.style';
import {  DropdownButton,Dropdown } from "react-bootstrap";
import { FilterDiv,WrapperDiv,Option } from './Sidebar.style';

// import DropDown from '../../../../../node_modules'
// import './dropdown.css';






const DropDown=(props)=>{

    
    const {placeholder,options,name,setFormData,formData}=props;
    
  
    
    // console.log(props);
  

   
    
    
    
    
    
    return(
      
      <WrapperDiv style={{width:"100%",poistion:"relative"}} id="sel">
    

             <DropdownButton   className="dropdown " title={formData[name]||placeholder }>
                
         { options.length&&options.map(item=>{
              return(
                <Dropdown.Item>
                <Option  onClick={()=>setFormData({...formData,[name]:item})}>
                  {item}
                  <div style={formData[name]===item?{backgroundColor:"#5CE0D2"}:{}} className="fill">
  
                  </div>
                </Option>
                </Dropdown.Item>
              )
            })
          }
          
                {/* //   </Dropdown.Item>
                ))} */}
              </DropdownButton> 
    </WrapperDiv>
  
    )
  }
  
 DropDown.defaultProps={
     placeholder:"Temp",
     formData:{name:""},
     options:[]
 }
  
  export default DropDown;
  


  export const FilterComponent=(props)=>{
    const {name,formData,text,options,placeholder,setFormData}=props;
    return(
    <WrapperDiv style={{marginRight:"15px"}} id="sel">
    
      <FilterDiv>
    <DropdownButton  className="dropdown " title={formData[name]||placeholder}>
       
{ options.length&&options.map(item=>{
     return(
       <Dropdown.Item>
       <Option  onClick={()=>setFormData({...formData,[name]:item})}>
         {item}
         <div style={formData[name]===item?{backgroundColor:"#5CE0D2"}:{}} className="fill">

         </div>
       </Option>
       </Dropdown.Item>
     )
   })
 }
 
       {/* //   </Dropdown.Item>
       ))} */}
     </DropdownButton> 
     </FilterDiv>
</WrapperDiv>)

  }
  
  FilterComponent.defaultProps={
    placeholder:"Subjects",
    options:[],
    formData:{name:""},
  }