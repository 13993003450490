import React from 'react';

const NotificationComponent = () => {
  return (
    <svg width='22' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.054 16.815v-4.889a8.756 8.756 0 0 0-2.405-6.048 8.662 8.662 0 0 0-3.735-2.345c.033-.187.052-.376.057-.566A2.966 2.966 0 0 0 11 0a2.972 2.972 0 0 0-2.97 2.967c.004.19.023.379.056.566A8.662 8.662 0 0 0 4.35 5.878a8.756 8.756 0 0 0-2.405 6.048v4.89A2.829 2.829 0 0 0 .033 19.93a2.825 2.825 0 0 0 2.762 2.396h4.527c0 .974.387 1.909 1.077 2.598a3.68 3.68 0 0 0 6.279-2.598h4.527a2.831 2.831 0 0 0 2.762-2.396 2.823 2.823 0 0 0-1.913-3.115zM11 1.695a1.269 1.269 0 0 1 1.273 1.272c0 .057-.028.142-.028.198a3.486 3.486 0 0 0-.566-.056h-1.358c-.19.003-.38.022-.566.056 0-.056-.028-.141-.028-.198A1.265 1.265 0 0 1 11 1.696zM3.644 11.927A7.078 7.078 0 0 1 5.711 6.89a7.093 7.093 0 0 1 5.034-2.085h.51a7.1 7.1 0 0 1 6.57 4.398c.356.864.536 1.79.531 2.724v4.748H3.644v-4.748zM11 24.304a1.981 1.981 0 0 1-1.98-1.978h3.96A1.977 1.977 0 0 1 11 24.304zm8.205-3.674H2.795a1.132 1.132 0 0 1-1.131-1.13 1.13 1.13 0 0 1 1.131-1.13h16.41a1.132 1.132 0 0 1 1.132 1.13 1.13 1.13 0 0 1-1.132 1.13z'
        fill='#045680'
      />
    </svg>
  );
};

export default NotificationComponent;
