import styled, { css } from 'styled-components';
import { flexColumn, myFlex, TextCss } from '../../../utils/shared.style';

export const SharedPosition = css`
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #dbdbdb;
`;

export const TabHeaderContainer = styled.div`
  ${myFlex};
  flex-wrap: wrap;
  position: relative;
  flex-grow: 1;
  justify-content: ${(props) => (props?.sm ? 'space-between' : 'flex-start')};
  &::after {
    ${SharedPosition}
  }
`;

export const TabHeader = styled.p`
  ${TextCss};
  padding: ${(props) => (props?.sm ? '1rem 2rem' : '1rem 8rem')};
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
  
  ${(props)=>props.active?"color:#045680; font-weight:700;":""}

  &::after {
    ${SharedPosition};
    bottom: -2px;
    height: ${(props) => (props.active ? '4px' : '0px')};

    background: ${(props) => props.theme.blueDarkSecondary};
    z-index: 1000;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    padding: 1rem 3rem;
  }
`;

export const LogContainer = styled.div`
  padding: 1rem;
  ${flexColumn};
`;

export const LogHeader = styled.div`
  ${myFlex};
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin: 1rem 0;
`;

export const LogBody = styled.div`
  ${flexColumn};
  line-height: 2;
  width: ${(props) => (props?.width ? '33.3%' : 'fit-content')};
  word-break: break-word;

  @media (max-width: 768px) {
    /* ${flexColumn}; */
    width: 100%;
  }
`;

export const Objective = styled.div`
  ${myFlex};
  /* align-items: center; */
  flex-wrap: wrap;
  justify-content: space-between;
  width: 75vw;

  @media (max-width: 768px) {
    ${flexColumn};
  }
`;

export const CommentContainer = styled.div`
  ${flexColumn};
  line-height: 2;
`;

export const CommentHeader = styled.div`
  ${myFlex};
  align-items: center;
  cursor: pointer;
`;

export const AddCommentContainer = styled.div`
  position: relative;
  width: fit-content;
 
`;

export const CommentButton = styled.button`
  outline: none;
  border: ${(props) => `1px solid ${props.theme.blueDarkSecondary}`};
  color: ${(props) => props.theme.blueDarkSecondary};
  padding: 5px 25px;
  position: absolute;
  right: 10px;
  bottom: 15px;
  background: none;
  font-size: 1.3rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
`;

export const TextArea = styled.textarea`
  border: 1px solid #dbdbdb;
  width:700px;
  max-width:700px;
  padding:10px;
  font-style:italic;
  &:focus{
    outline:none;
  }
`;

export const Progress = styled.div`
  width: 25%;

  @media (max-width: 768px) {
    width: 50%;
  }
`;
