import React from "react";

function OrgDropdownComponent({style}) {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.18048 13.6421L0.27008 11.633L4.94825 7.18476L0.500019 2.50659L2.50917 0.596188L8.86781 7.28352L2.18048 13.6421Z"
        fill="white"
        fill-opacity="0.6"
      />
    </svg>
  );
}

export default OrgDropdownComponent;
