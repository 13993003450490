import styled from 'styled-components';
import {
  boxShadow,
  flexCenter,
  flexColumn,
  myFlex,
  TextCss,
} from '../../../utils/shared.style';

export const ChartViewContainer = styled.div`
  ${flexColumn};
  padding: 1rem;
  ${flexCenter};
  ${boxShadow};
  flex-grow: 1;
  .css-1xarhfg{
    max-width:90vw;
    margin:0 auto;
    overflow-X:scroll;
    margin-bottom:10px;
    padding-bottom:100px;
  }
`;

export const Chart = styled.div`
  min-width: 23.7rem;
  height:auto;
  background: ${(props) =>
    props?.super ? props.theme.blueDarkPrimary : '#445866'};

  padding: 1rem 0 0 1rem;
  position: relative;
  margin: 2rem 1rem 2rem 0;
  margin-bottom:0px;
  font-weight:800;
  border-radius:3.5px;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: -2rem;
  //   height: 2rem;
  //   width: 2px;
  //   background: #60799e;
  //   left: 50%;
  //   display: ${(props) => (props?.super ? 'block' : 'none')};
  // }

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: -2rem;
  //   height: 2rem;
  //   width: 2px;
  //   background: #60799e;
  //   left: 50%;
  //   display: ${(props) => (!props?.super ? 'block' : 'none')};
  // }
`;

export const ProfileContainer = styled.div`
  ${myFlex};
  align-items: flex-start;
`;

export const ImageContainer = styled.div`
  height: 100%;
`;

export const Detail = styled.div`
  ${flexColumn};
  line-height: 1.7;
  margin-top: 0.9rem;
`;

export const Text = styled.p`
  ${TextCss};
  text-align:left;
  color: #dbdbdb;
  font-weight: 600;
  font-size: 1.3rem;
  ${myFlex};
`;

export const Dropdown = styled.div`
  background: #000;
  height: 34px;
  width: 30px;
  opacity:0.4;
  ${myFlex};
  ${flexCenter};
`;

export const ChildChart = styled.div`
  ${myFlex};
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 75.3%;
    height: 1px;
    top: 0;
    left: 12%;
    background-color: #60799e;
  }
`;
