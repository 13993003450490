import React, { useState } from 'react';
import { Text } from '../../../utils/shared.style';
import DashboardInputComponent from '../../Dashboard/DashboardHeader/DashboardInput.component';
import {
  Line,
  ObjectiveHeader,
} from '../../Dashboard/Objective/Objective.style';
import InputComponent from '../../Input/Input.component';
import { Header } from '../../Settings/SettingsContent/SettingContent.style';
import DeleteComponent from '../../Svgs/Delete.component';
import EditComponent from '../../Svgs/Edit.component';
import { Browse, Button, InputContainer, Search, MileStones } from './NewObjective.style';
import { toast } from 'react-toastify';
import TabComponent from './Tab.component';
import FilterComponent from '../../Dashboard/DashboardHeader/Filter.component';

const NewKeyResult = ({ formData, setFormData, createObjectiveOkr, EditKeyResult, validation, setValidation, createKeyResult, totalMileStone, setModalComponent, setTotalMileStone }) => {
  console.log(formData);
  const types = [
    { label: "Percent", options: ["%"] },
    { label: "Currency", options: ["₹","$", "€", "£", "¥", "USD", "EUR", "GBP", "dollars", "euros", "pounds"] },
    { label: "Units", options: ["answers", "articles", "clients", "items", "meetings", "NPS", "people", "persons", "posts", "projects", "signups", "trials", "visitors"] }
  ];
  const [isMileStone, setIsMileStone] = useState(true);
  const [mileStone, setMilestone] = useState({
    timeframe: [],
    status: "S",
    title: ""
  })
  const DeleteMileStone = (idx) => {
    setTotalMileStone(totalMileStone.filter((item, id) => id !== idx));
  }
  const addMileStone = () => {
    if (!mileStone.title || mileStone.timeframe.length !== 2) {
      return;
    }

    if (!mileStone.timeframe[0] || !mileStone.timeframe[1]){
      return;
    }
    
    if(mileStone.timeframe[0] >= mileStone.timeframe[1]){
      console.log("show toast");
      toast.error("End date is smaller than Start Date", { autoClose: 3000 }); return;
    }

    setTotalMileStone([...totalMileStone, {
      status: mileStone.status,
      timeframe: [...mileStone?.timeframe],
      title: mileStone.title
    }]);

    setMilestone({
      timeframe: [],
      status: "S",
      title: "",

    })
  }
  console.log(formData);
  console.log(totalMileStone)
  return (
    <>
      <Text bold className='mb'>
        New Key Result
      </Text>
      <Line className='mbb' />
      <TabComponent browse={false} header='Details'>
        <InputContainer lg>
          <Text light>Title</Text>
          <InputComponent value={formData.title} name="title" valid={validation.title} onChange={(e) => { setFormData({ ...formData, [e.target.name]: e.target.value }); setValidation({ ...validation, [e.target.name]: false }) }} placeholder='eg keyResult 1' width='90%' />
        </InputContainer>
        <ObjectiveHeader>
          <InputContainer>
            <Text light>Type</Text>
            <DashboardInputComponent valid={validation.type} value={formData.type} width='100%' >
              <FilterComponent search={false}
                formData={formData}
                validation={validation}
                setValidation={setValidation}
                setFormData={setFormData} name="type" groupOption={true} options={types} />
              {/*["%", "$", "€", "£", "₹"]*/}
            </DashboardInputComponent>
          </InputContainer>
          {/* <InputContainer>
            <Text light>Owner</Text>
            <InputComponent name="owner" value={formData.name} onChange={(e)=>{setFormData({...formData,[e.target.type]:e.target.value})} }placeholder='eg John' width='90%' />
          </InputContainer> */}
          <InputContainer>
            <Text light>Weight</Text>
            <InputComponent valid={validation.weight} type="Number" value={formData.weight} name="weight" onChange={(e) => {
              console.log(e.target.value); if (e.target.value < 0 || e.target.value === "-") {
                toast.error("Cant assign -ve weight", { autoClose: 3000 }); return;
              }; setFormData({ ...formData, [e.target.name]: e.target.value }); setValidation({ ...validation, [e.target.name]: false })
            }} />
          </InputContainer>
        </ObjectiveHeader>
        <ObjectiveHeader>
          <InputContainer>
            <Text light>Start Value</Text>
            <InputComponent name="startValue" valid={validation.startValue} type="number" value={formData.startValue} onChange={(e) => {
              if (e.target.value < 0) {
                toast.error("Start Value cant be -ve ", { autoClose: 3000 }); return;
              };
              setFormData({ ...formData, [e.target.name]: e.target.value }); setValidation({ ...validation, [e.target.name]: false })
            }} placeholder="Start Value" width='100%' />
          </InputContainer>
          <InputContainer>
            <Text light>End Value</Text>
            <InputComponent name="endValue" valid={validation.endValue} value={formData.endValue} type="number" onChange={(e) => {
              if (e.target.value < 0) {
                toast.error("End Value cant be -ve ", { autoClose: 3000 }); return;
              };

              setFormData({ ...formData, [e.target.name]: e.target.value }); setValidation({ ...validation, [e.target.name]: false })
            }} placeholder="End Value" width='100%' />
          </InputContainer>
        </ObjectiveHeader>
      </TabComponent>
      <Header>
        <Header className='no-ms'>
          <input name="isMileStone" onChange={(e) => { setIsMileStone(!e.target.checked) }} type='checkbox' className='mr-1' />
          <Text light>No milestones</Text>
        </Header>
      </Header>

      <TabComponent
        header='Milestones'
        browse={false}
        HeaderChild={() => (
          isMileStone && <Text onClick={() => { addMileStone() }} light blue className='underline'>
            Add Milestone
          </Text>
        )}
      >
        {
          totalMileStone.map((item, idx) => {
            return (<MileStones>{item.title}

              <div onClick={() => DeleteMileStone(idx)}><DeleteComponent /></div>
            </MileStones>)
          })
        }
        {isMileStone && <> <Text light>Title</Text>
          <Search>
            <InputComponent lg name="title" placeholder="title" value={mileStone.title} onChange={(e) => { setMilestone({ ...mileStone, [e.target.name]: e.target.value }) }} plcaeholder="eg milestone" />
            <DeleteComponent />
          </Search></>}
        <Search>
          <div >
            <Text light>Start Date</Text>
            <InputComponent onChange={(e) => {
              if (!isMileStone) {
                setFormData({ ...formData, timeframes: [new Date(e.target.value)] })
                return;
              }
              let temp = mileStone;
              temp.timeframe[0] = new Date(e.target.value);
              console.log(e.target.value)
              setMilestone({ ...temp });
            }} type='date' /></div>
          <div >
            <Text light>End Date</Text>
            <InputComponent onChange={(e) => {
              if (!isMileStone) {
                setFormData({ ...formData, timeframe: [...formData.timeframes, new Date(e.target.value)] })
                return;
              }
              let temp = mileStone;

              temp.timeframe[1] = new Date(e.target.value);

              setMilestone({ ...temp });
            }} type='date' />
          </div>
        </Search>
      </TabComponent>
      {/* <TabComponent
        header='Alignment'
        HeaderChild={() => <EditComponent />}
      ></TabComponent> */}

      <Line className='mbb' />
      <Browse className='mbb'>
        {/*<Button>Cancel</Button>*/}
        <Button onClick={() => { createKeyResult() }} main>{EditKeyResult ? "Edit KeyResult" : "Create KeyResult"}</Button>
      </Browse>
    </>
  );
};

export default NewKeyResult;
