import styled from 'styled-components';
import { boxShadow, myFlex } from '../../../utils/shared.style';

export const CheckInContainer = styled.section`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.modal ? 'flex' : 'none')};
  z-index: 10000;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
    padding: 1rem 0;
  }
`;

export const CheckIn = styled.div`
  height: 100%;
  width: 30%;
  ${boxShadow};
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const CheckInHeader = styled.div`
  ${myFlex};
  justify-content: space-around;
  padding: 4rem 0 1rem 0;
`;
