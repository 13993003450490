import styled from "styled-components";

export const LoadingContainer=styled.div`
position:fixed;
top:50%;
left:50%;

transform:translateX(-50%) translateY(-50%);



`
export const DocumentLoader=styled.div`

background-color:#fff;
box-shadow:2px 3px 10px #cccc;
border-radius:10px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
width:400px;
height:170px;

`

export const Text=styled.div`

font-size:1rem;
font-weight:600;




`