import React from 'react';

const SearchComponent = (props) => {
  return (
    <svg
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.8211 13.9475L11.2656 10.392C12.2853 9.15462 12.8455 7.52425 12.6862 5.76376C12.4101 2.71279 9.92467 0.261919 6.87104 0.0202843C2.95443 -0.287734 -0.287734 2.95442 0.0202843 6.87104C0.261919 9.92467 2.71544 12.4101 5.76376 12.6862C7.52425 12.8455 9.15462 12.2853 10.392 11.2656L13.9475 14.8211C14.1891 15.0627 14.5795 15.0627 14.8184 14.8211C15.0601 14.5795 15.0601 14.1891 14.8211 13.9475ZM9.95122 9.95122C7.96503 11.9374 4.74412 11.9374 2.75793 9.95122C0.771743 7.96503 0.771743 4.74412 2.75793 2.75793C4.74412 0.771742 7.96503 0.771742 9.95122 2.75793C11.9374 4.74412 11.9401 7.96503 9.95122 9.95122Z'
        fill='#045680'
      />
    </svg>
  );
};

export default SearchComponent;
