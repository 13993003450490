import React from 'react';
import { Text } from '../../../utils/shared.style';
import { Line } from '../../Dashboard/Objective/Objective.style';
import NewRequestComponent from '../../Dashboard/Request/NewRequest.component';
import CloseReqComponent from '../../Svgs/CloseReq.component';
import { CheckIn, CheckInContainer, CheckInHeader } from './CheckIn.style';

const CheckInComponent = ({ modal, onClick }) => {
  return (
    <CheckInContainer modal={modal}>
      <CheckIn>
        <CheckInHeader>
          <div>
            <Text bold>John's OKR(2)</Text>
            <Text light>Consultant</Text>
          </div>
          <div onClick={onClick}>
            <CloseReqComponent />
          </div>
        </CheckInHeader>
        <Line />
        <NewRequestComponent
          progress='50%'
          time='Q2 2021'
          status='In Review'
          showMeta={false}
          IconName="CheckInsComponentSvg"
        />
        <NewRequestComponent
          progress='50%'
          time='Q2 2021'
          status='In Review'
          IconName="CheckInsComponentSvg"
          showMeta={false}
        />
        <NewRequestComponent
          progress='50%'
          time='Q2 2021'
          status='In Review'
          showMeta={false}
          IconName="CheckInsComponentSvg"
        />
        <NewRequestComponent
          progress='50%'
          time='Q2 2021'
          status='In Review'
          showMeta={false}
          IconName="CheckInsComponentSvg"
        />
      </CheckIn>
    </CheckInContainer>
  );
};

export default CheckInComponent;
