import React from 'react';
import {
  DashboardHeader,
  DashboardText,
} from '../components/Dashboard/DashboardHeader/DashboardHeader.style';
import HeaderComponent from '../components/Header/Header.component';
import MainContainerComponent from '../components/Layouts/MainContainer/MainContainer.component';
import SingleOkrComponent from '../components/MyOkr/SingleOkr/SingleOkr.component';
import { SingleOkrContainer } from '../components/MyOkr/SingleOkr/SingleOkr.style';


const SingleOkr=()=>{


    return(
        <main>
        <HeaderComponent />
        <MainContainerComponent>
          <DashboardHeader>
            <DashboardText>Single Okr</DashboardText>
          </DashboardHeader>
            <SingleOkrComponent/>
        </MainContainerComponent>
      </main>
    )
}


export default SingleOkr;