import React from 'react'
import ObjectiveComponent from '../../Svgs/Objective.component';
import CheckInsComponentSvg from '../../Svgs/CheckIn.component';
import CollabComponent from '../../Svgs/Collab.component';

const IconComponent={
    ObjectiveComponent:<ObjectiveComponent style={{marginRight:"0.5rem"}}/>,
    CheckInsComponentSvg:<CheckInsComponentSvg style={{marginRight:"0.5rem"}}/>,
    CollaborationComponent:<CollabComponent style={{marginRight:"0.5rem"}}/>


}

function SvgIcon({IconName}) {
    return (
    
        IconComponent[IconName]
    )
}

export default SvgIcon
