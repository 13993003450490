import React, { useState, useEffect } from 'react';
import { RegisterCompany } from './Login.style';
import { useHistory } from 'react-router-dom';
import { LogInFormContainer } from './Login.style';
import { FormLabel } from './Login.style';
import { InputField, LoginButton } from './Login.style';
import { useAuth } from '../../context/Auth';
import { company, User, department } from '../../api/Api';
import { auth } from '../../firebase';

import { createUserWithEmailAndPassword } from '@firebase/auth';
import axios from 'axios';



function Register({ setRegister }) {
    const history = useHistory();
    const { processing, setProcessing, setUID, setIdToken } = useAuth();
    const [uid, setUIDI] = useState("");
    const [idToken, setIdTokeni] = useState("")
    const [execute, setExecute] = useState(true);
    const [personRegister, setPersonRegister] = useState(false);


    const [formData, setFormData] = useState({
        email: "",
        name: "",
        gender: "",
        password: "",
        companyName: "",
        users: 0
    })

    const handleOnClick = async (e) => {
        e.preventDefault();
        console.log(formData);
        let { email, name, gender, password, companyName, users } = formData;
        document.addEventListener("readystatechange", () => { console.log("rahul") });

        if (!email || !name || !gender || !password || !companyName || !users) {
            alert("All Fields Are Mandatory")

        }
        else {
            const dt = await createUserWithEmailAndPassword(auth, email, password)
            console.log(dt);
            setUIDI(dt.user.uid);
            dt?.user.getIdToken(false).then((idToken) => { setIdTokeni(idToken) }).catch(err => console.log(err));
        }
    }
    const handleOnChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData({ ...formData, [name]: value })


    }
    useEffect(() => {
        console.log(uid, idToken);
        if (uid && idToken) {
            setPersonRegister(true);
        }

    }, [uid, idToken])
    useEffect(() => {
        setProcessing(true);
    }, [])



    useEffect(() => {
        console.log(personRegister && execute);
        if (personRegister && execute) {
            console.log("in PersonRegister")
            setExecute(false);
            let payload = {
                company: formData.companyName,
                minUsers: 10,
                maxUsers: 10000,
                current_users: formData.Users,
            }
            axios.post(company.postCompany, payload, {
                headers: {
                    Authorization: `${idToken}`
                }
            }).then(res => {
                console.log(res);
                let companyId = res.data.company_id;
                console.log(companyId);
                axios.post(department.postDepartment, {
                    company_id: res.data.company_id,
                    department: "Company",
                    owner: uid,
                    type: "department",
                    current_users: 1

                }, {
                    headers: {
                        Authorization: `${idToken}`
                    }
                }).then(res => {
                    axios.post(User.postUser, {
                        uuid: uid,
                        email: formData.email,
                        name: formData.name,
                        company_id: companyId,
                        department_id: res.data.department_id,
                        access: "superAdmin",
                        head: true,
                        gender: formData.gender,
                        role: "CEO",
                        reportingto: "",
                    }, {
                        headers: {
                            Authorization: `${idToken}`
                        }
                    }).then(res => {
                        console.log(res);
                        setProcessing(false);
                        setUID(uid);
                        setIdTokeni(idToken);
                        setTimeout(history.push('/'), 3000);
                        // history.push('/')
                    }
                    )
                })



            }).catch(err => console.log(err))

        }

    }, [personRegister])
    return (
        <RegisterCompany>
            <LogInFormContainer>

            <h1 style={{letterSpacing:".4rem"}}>S<i style={{marginLeft:"-0.4rem"}}>2</i>E PORTAL</h1>
                <FormLabel >
                    already have an account ? <span style={{ color: "cyan", cursor: "pointer" }} onClick={() => setRegister(false)} >Click here</span>
                </FormLabel>

            </LogInFormContainer>
            <LogInFormContainer>
                <h1>Register</h1>

            </LogInFormContainer>
            <form onSubmit={(e) => handleOnClick(e)}>
                <LogInFormContainer>

                    <FormLabel>
                        Full Name
                    </FormLabel>
                    <InputField onChange={(e) => handleOnChange(e)} value={formData.name} name="name" placeholder="Your full Name" />
                </LogInFormContainer>
                <LogInFormContainer>
                    <FormLabel>
                        Username
                    </FormLabel>
                    <InputField onChange={(e) => handleOnChange(e)} value={formData.email} name="email" placeholder="Email address" />


                </LogInFormContainer>
                <LogInFormContainer>
                    <FormLabel>
                        Gender
                    </FormLabel>
                    <InputField onChange={(e) => handleOnChange(e)} value={formData.gender} name="gender" placeholder="Your gender" />
                </LogInFormContainer>
                <LogInFormContainer>
                    <FormLabel>
                        Password
                    </FormLabel>
                    <InputField onChange={(e) => handleOnChange(e)} value={formData.password} name="password" placeholder="Your password" type="password" />
                </LogInFormContainer>
                <LogInFormContainer>
                    <FormLabel>
                        Company
                    </FormLabel>
                    <InputField onChange={(e) => handleOnChange(e)} value={formData.companyName} name="companyName" placeholder="Company name" type="text" />
                </LogInFormContainer>
                <LogInFormContainer>
                    <FormLabel>
                        Number of Users
                    </FormLabel>
                    <InputField onChange={(e) => handleOnChange(e)} value={formData.users} name="users" placeholder="no of users" type="number" />
                </LogInFormContainer>
                {/**
                <LogInFormContainer>
                    <FormLabel>
                        Department
                    </FormLabel>
                    <InputField value="Company" />
                </LogInFormContainer>
                 */}
                <LogInFormContainer>
                    <input className='btn' value="Register Now" type="submit" />
                </LogInFormContainer>
            </form>
        </RegisterCompany>
    )
}

export default Register

