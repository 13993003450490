import React from 'react'

function UnExpandComponent() {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.35805 1.00006L4.11572 1.00006L4.11572 4.38794L0.50018 4.38794L0.50018 3.63945L3.35805 3.63945L3.35805 1.00006Z" fill="#1F1F1F" stroke="#1F1F1F" stroke-miterlimit="10"/>
        <path d="M10.0037 1.00006L10.7614 1.00006L10.7614 3.63945L13.3667 3.63945L13.3667 4.38794L10.0037 4.38794L10.0037 1.00006Z" fill="#1F1F1F" stroke="#1F1F1F" stroke-miterlimit="10"/>
        <path d="M0.500181 10.4809L4.11572 10.4809L4.11572 13.8688L3.35805 13.8688L3.35805 11.2294L0.50018 11.2294L0.500181 10.4809Z" fill="#1F1F1F" stroke="#1F1F1F" stroke-miterlimit="10"/>
        <path d="M10.0037 10.4809L13.3667 10.4809L13.3667 11.2294L10.7614 11.2294L10.7614 13.8688L10.0037 13.8688L10.0037 10.4809Z" fill="#1F1F1F" stroke="#1F1F1F" stroke-miterlimit="10"/>
        </svg>
        
    )
}

export default UnExpandComponent
