import React, { useEffect, useState } from 'react';
import ListDropdownComponent from '../Svgs/ListDropdown.component';
import NotificationComponent from '../Svgs/Notification.component';
import UserComponent from '../Svgs/User.component';
import { useHistory } from 'react-router-dom';
// import AverageComponent from '../Svgs/Average.component'
import NotificationListComponent from './Notification.component';
import {
  Average,
  HeaderContainer,
  HeaderText,
  MenuContainer,
  // Progress,
  UserContainer,
  NotificationIconContainer,
} from './Header.style';
import ProfileComponent from './Profile.component';
// import { dummyNotification } from '../Dashboard/Okr/Tabs/helpers';
import { useAuth } from '../../context/Auth';
import { User, notifications } from '../../api/Api';
import axios from 'axios';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
// import { CheckIn } from '../Objective/CheckIn/CheckIn.style';


/**
 * Dashboard Header Component- contains User Profile Notification list
 *
 */

const HeaderComponent = ({ setDepartmentId = () => { },
  setCompanyId = () => { } }) => {
  const { uid, idToken, setAdmin, setManager, setDepartment_Id, setEmail } = useAuth();
  const [user, setUser] = useState("");
  const [checkIn, setNotifications] = useState([]);
  const [isRead, setIsRead] = useState(false);
  const history = useHistory();



  useEffect(() => {

    axios.get(User.getUserById(uid), { headers: { Authorization: `${idToken}`, uuid: uid } }).then(res => {

      setUser({ ...res.data.user, reportingto: res.data.approval.name });

      console.log(res.data.user);
      setEmail(res.data.user.email);
      setAdmin(res.data.user.access === 'superAdmin');
      setManager(res.data.user.access === 'admin');
      // console.log(res.data.user.access==='admin');
      // console.log(res.data.user.email);

      setCompanyId(res.data.user.companyid);
      setDepartmentId(res.data.user.department_id);
      // console.log(res.data.user.department_id);

      setDepartment_Id(res.data.user.department_id);


    }).catch(err => console.log(err));
    // axios.post(checkIn,{name:"Rahul"},{
    //   headers:{
    //     Authorization:`${idToken}`,
    //     uuid:uid

    //   }
    // }).then((res)=>console.log(res)).catch((err)=>console.log(err));
    axios.post(notifications, { name: "Rahul" }, {
      headers: {
        Authorization: `${idToken}`, uuid: uid
      }
    }).then(res => setNotifications(res.data.checkinData)).catch(err => console.log(err));
  }, []);

  const getColor = (val) => {
    if (val <= 10 || val === undefined)
      return "#d62e04";
    else if (val <= 20)
      return "#fcde1c";
    else if (val <= 50)
      return "#000cf0";
    else
      return "#099E60";
  }

  return (
    <HeaderContainer>
      <HeaderText>S2E PORTAL</HeaderText>
      <MenuContainer>
        {/* <Progress /> */}
        <div onClick={() => { history.push('/objective') }} style={{ height: "40px", position: "relative", width: "40px", cursor: "pointer" }}>
          <span style={{ position: "absolute", top: "50%", color: `${getColor(localStorage.getItem("companyPercent"))}`, fontWeight: "600", left: "50%", transform: "translateX(-50%) translateY(-50%)" }}>{localStorage.getItem("companyPercent")}%</span>

          <CircularProgressbar styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.25,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',

            // Text size
            textSize: '22px',

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: `${getColor(localStorage.getItem("companyPercent"))}`,
            textColor: '#f88',
            trailColor: '#d6d6d6',
            backgroundColor: '#3e98c7',
          })}
            value={localStorage.getItem("companyPercent")} />;
        </div>
        <Average style={{ color: `${getColor(localStorage.getItem("companyPercent"))}`, marginLeft: "10px" }}>Average</Average>
        <ListDropdownComponent />
        {/* Notification */}
        <NotificationIconContainer>
          {!isRead && <div className='cnt' style={{ color: "red", fontWeight: "600", cursor: "default", zIndex: "100", fontSize: "1.3rem", }} >{checkIn?.length ? checkIn.length : ""}</div>}
          <NotificationComponent />
          <NotificationListComponent setIsRead={setIsRead} notifications={checkIn} />
        </NotificationIconContainer>

        {/* User Profile  */}
        <UserContainer>
          <UserComponent />
          <ProfileComponent user={user} />
        </UserContainer>
      </MenuContainer>
    </HeaderContainer>
  );
};

export default HeaderComponent;
