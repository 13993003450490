import React from 'react';

const UserComponent = (props) => {
  return (
    <svg
      width='27'
      height='26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.009 14.112c5.246 2.531 6.403 5.599 6.017 10.507-.077.768-.694 1.381-1.465 1.381H2.495c-.772 0-1.389-.613-1.466-1.38-.386-4.909.771-7.977 6.018-10.508.463-.307 1.003-.23 1.466.077a9.351 9.351 0 0 0 10.03 0c.463-.307 1.003-.384 1.466-.077zm-.618 1.84a11.243 11.243 0 0 1-5.863 1.612 11.243 11.243 0 0 1-5.864-1.611c-3.78 1.917-4.938 3.911-4.706 8.053h21.14c.231-4.142-.926-6.136-4.707-8.053zM13.529 0c3.549 0 6.48 2.914 6.48 6.442s-2.931 6.443-6.48 6.443c-3.55 0-6.481-2.915-6.481-6.443C7.048 2.914 9.98 0 13.528 0zm0 1.917c-2.47 0-4.552 2.071-4.552 4.525 0 2.455 2.083 4.525 4.552 4.525 2.469 0 4.475-2.07 4.475-4.525 0-2.454-2.006-4.525-4.475-4.525z'
        fill='#045680'
      />
    </svg>
  );
};

export default UserComponent;
