import React, { useEffect, useContext } from "react";
import AddComponent from "../../Svgs/Add.component";
import {
  Button,
  DashboardHeader,
  DashboardInputContainer,
  DashboardText,
} from "./DashboardHeader.style";
import DashboardInputComponent from "./DashboardInput.component";
import FilterComponent from "./Filter.component";
import { useAuth } from "../../../context/Auth";
import { ModalContext } from "../../../context/Modal.context";

export const options = [
  "All",
  "Team1",
  "Team2",
  "Team3",
  "Team4",
  "Team5",
  "Team6",
];

const DashboardHeaderComponent = ({
  input = true,
  showObjectiveModal = () => {},
  headerText,
  setCompanyId = () => {},
  setDepartMentId = () => {},
  departments = [],
  allUser = [],
  quarter = "",
  setQuarter = () => {},
}) => {
  const { datefilter } = useContext(ModalContext);
  const {
    filterByDepartment,
    setFilterByDepartment,
    filterByUser,
    setFilterByUser,
    setFilterBy,
    admin,
    manager,
    departmentId,
  } = useAuth();
  console.log(filterByUser);
  useEffect(() => {
    if (filterByUser.id) setFilterByDepartment({});
    setFilterBy("user");
  }, [filterByUser]);
  useEffect(() => {
    if (filterByDepartment.id) setFilterByUser({});
    setFilterBy("department");
  }, [filterByDepartment]);
  // console.log(allUser);

  const userdata = admin
    ? allUser
    : allUser.filter((item) => item.departmentid === departmentId);

  return (
    <DashboardHeader>
      <div>
        <DashboardText>{headerText}</DashboardText>
      </div>
      {input ? (
        <DashboardInputContainer>
          {admin && (
            <DashboardInputComponent
              value={filterByDepartment.department || "All"}
              placeholder="Department"
            >
              <FilterComponent
                name="department"
                formData={filterByDepartment}
                setFormData={setFilterByDepartment}
                options={[
                  "All",
                  ...departments.map((item) => {
                    if (item.name === "Company") return "CEO";
                    else return item.name;
                  }),
                ]}
                id={["all", ...departments.map((item) => item._id)]}
                special
              />
            </DashboardInputComponent>
          )}
          {/* <DashboardInputComponent placeholder='Select Team'>
            <FilterComponent name='Team' options={options} />
          </DashboardInputComponent> */}
          <DashboardInputComponent
            value={filterByUser.user || "All"}
            placeholder="Select User"
          >
            <FilterComponent
              name="user"
              formData={filterByUser}
              setFormData={setFilterByUser}
              id={["alluser", ...userdata?.map((item) => item.uuid)]}
              options={["All", ...userdata?.map((item) => item.name)]}
            />
          </DashboardInputComponent>
          <DashboardInputComponent
            height="auto"
            viewBy="date"
            placeholder="View By"
            value={
              quarter.viewBy === `All ${new Date().getUTCFullYear()}`
                ? undefined
                : quarter.ViewBy
            }
            lg
          >
            <FilterComponent
              name="ViewBy"
              options={[
                `All ${new Date().getUTCFullYear()}`,
                `Q1 ${new Date().getUTCFullYear()}`,
                `Q2 ${new Date().getUTCFullYear()}`,
                `Q3 ${new Date().getUTCFullYear()}`,
                `Q4 ${new Date().getUTCFullYear()}`,
              ]}
              date
              formData={quarter}
              setFormData={setQuarter}
            />
          </DashboardInputComponent>
        </DashboardInputContainer>
      ) : (
        ""
      )}
      <Button onClick={showObjectiveModal}>
        <AddComponent className="no-mr" /> New Objective
      </Button>
    </DashboardHeader>
  );
};

export default DashboardHeaderComponent;
