import React from 'react';

const TickComponent = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='6'
      viewBox='0 0 8 6'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.87083 1.00006C8.05558 1.20529 8.03898 1.52144 7.83374 1.70619L4.226 4.95387C3.82927 5.311 3.222 5.29358 2.8464 4.91429L0.996674 3.04638C0.802369 2.85016 0.803918 2.53359 1.00013 2.33928C1.19635 2.14498 1.51293 2.14652 1.70723 2.34274L2.88602 3.53312C3.26162 3.91241 3.8689 3.92984 4.26563 3.5727L7.1647 0.96297C7.36993 0.778218 7.68608 0.794823 7.87083 1.00006Z'
        fill='white'
      />
    </svg>
  );
};

export default TickComponent;
