import React, { useState } from "react";
import { Text } from "../../../utils/shared.style";
import DashboardInputComponent from "../../Dashboard/DashboardHeader/DashboardInput.component";
import FilterComponent from "../../Dashboard/DashboardHeader/Filter.component";
import InputComponent from "../../Input/Input.component";
import { options } from "../../Dashboard/DashboardHeader/DashboardHeader.component";
import { ChooseCollab, Search } from "./NewObjective.style";
import CloseComponent from "../../Svgs/Close.component";
import {
  Browse,
  InputContainer,
  ObjectiveHeader,
  Button,
} from "./NewObjective.style";
import TabComponent from "./Tab.component";
import { collabortion } from "../../../api/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OverviewComponent = ({
  setComp,
  formData,
  setFormData,
  collaboration,
  deleteData,
  alignment,
  setTab,
  validation,
  setValidation,
}) => {
  //  toast.configure();
  const changeTab = () => {
    if (
      formData.title &&
      formData.timeframe.length === 2 &&
      formData.Okr &&
      formData.Okr !== "select"
    ) {
      setTab(1);
    } else {
      toast("Some fields are empty", { autoClose: 3000 });
      let temp = validation;
      if (!formData.title) {
        temp.title = true;
      }
      if (formData.timeframe.length !== 2) {
        temp.timeframe = true;
      }
      if (!formData.Okr || formData.Okr === "select") {
        temp.Okr = true;
      }
      setValidation({ ...temp });
    }
  };

  console.log("LINE 56", formData);

  return (
    <>
      <TabComponent header="Details" isOpen={true} browse={false}>
        <Text>Title</Text>
        <InputContainer lg>
          {/* <DashboardInputComponent width='100%' placeholder='Eg Increase' /> */}
          <InputComponent
            validation={validation}
            setValidation={setValidation}
            valid={validation.title}
            name="title"
            value={formData.title}
            onChange={(e) => {
              setFormData({ ...formData, [e.target.name]: e.target.value });
              setValidation({ ...validation, [e.target.name]: false });
            }}
            width="90%"
            placeholder="Eg Increase"
          />
        </InputContainer>
        <ObjectiveHeader>
          <InputContainer>
            <Text>Type</Text>

            <DashboardInputComponent
              valid={validation.Okr}
              value={formData.Okr}
              width="100%"
              search={false}
            >
              <FilterComponent
                name="Okr"
                options={["Critical", "Aspirational"]}
                search={false}
                formData={formData}
                setFormData={setFormData}
                setValidation={setValidation}
                validation={validation}
              />
            </DashboardInputComponent>
          </InputContainer>

          {/* <InputContainer>
            <Text>Team</Text>

            <DashboardInputComponent value={formData.teams} search={false} width="100%" >
              <FilterComponent
              name="teams"
              options={["team1","team2"]}
              search={false}
              formData={formData}
              setFormData={setFormData}              
              />
              </DashboardInputComponent>
          </InputContainer> */}

          {/* <InputContainer>
            <Text>Owner</Text>

            <DashboardInputComponent width='100%' placeholder='Eg Increase' />
            <InputComponent onChange={(e)=>{setFormData({...formData,[e.target.name]:e.target.value})}}  formData={formData} setFormData={setFormData} name="owner" value={formData.owner} width="70%" placeholder="Eg John" />
          </InputContainer> */}
          <InputContainer>
            <Text>Check In</Text>

            <DashboardInputComponent
              width="100%"
              search={false}
              value={formData.checkIn}
            >
              <FilterComponent
                name="checkIn"
                options={["daily", "weekly", "monthly", "Quarterly"]}
                search={false}
                formData={formData}
                setFormData={setFormData}
              />
            </DashboardInputComponent>
          </InputContainer>
        </ObjectiveHeader>
        <ObjectiveHeader>
          {/**Old Timeframe 
          <InputContainer>
            <Text>Time Period</Text>

           
            <DashboardInputComponent
              lg
              height="auto"
              valid={validation.timeframe}
              value={
                !formData.date.startDate && !formData.date.endDate
                  ? "select"
                  : `${formData.date.startDate} to ${formData.date.endDate}`
              }
              width="100%"
            >
              <FilterComponent
                name="timeframe"
                options={[]}
                serach={false}
                lg
                formData={formData}
                setFormData={setFormData}
                date
                component="newObjective"
                // minHeight="300px"

                setValidation={setValidation}
                validation={validation}
              />
            </DashboardInputComponent>
          </InputContainer>*/}
          <Search>
            <div>
              <Text>Start Date</Text>
              <InputComponent
                value={formData?.date?.startDate || ""}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  let temp = formData;
                  setValidation({ ...validation, timeframe: false })
                  temp.timeframe[0] = date;
      
                  let dat = date.getDate() < 10 ? `0${date.getDate()}`:date.getDate();
                  let mon = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}`:(date.getMonth() + 1);
                  let year = date.getFullYear();
      
                  let dt = `${year}-${mon}-${dat}`
                  temp.date.startDate = dt;
                  setFormData({ ...temp });
                }}
                type="date"
              />
            </div>
            <div>
              <Text>End Date</Text>
              <InputComponent
                value={formData?.date?.endDate || ""}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  let temp = formData;
                  setValidation({ ...validation, timeframe: false })
                  temp.timeframe[1] = date;
      
                  let dat = date.getDate() < 10 ? `0${date.getDate()}`:date.getDate();
                  let mon = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}`:(date.getMonth() + 1);
                  let year = date.getFullYear();
      
                  let dt = `${year}-${mon}-${dat}`
                  console.log(dt);
                  temp.date.endDate = dt;
                  setFormData({ ...temp });
                }}
                type="date"
              />
            </div>
          </Search>

          <InputContainer>
            <Text>Visible to</Text>

            <DashboardInputComponent width="100%" value={formData.visible}>
              <FilterComponent
                name="visible"
                options={["everyone"]}
                search={false}
                formData={formData}
                setFormData={setFormData}
              />
            </DashboardInputComponent>
          </InputContainer>
        </ObjectiveHeader>
      </TabComponent>

      <TabComponent header="Alignment" setComp={() => setComp("Alignment")}>
        <Text style={{ margin: "1rem 0" }}>
          {alignment ? alignment.name : "Not Assigned Yet"}
        </Text>
      </TabComponent>
      <TabComponent header="Collaboration" setComp={() => setComp("Collab")}>
        <Text style={{ textAlign: "center", margin: "1rem 0" }}>
          <div style={{ display: "flex" }}>
            {collabortion && collaboration.length
              ? collaboration.map((item) => {
                  return (
                    <ChooseCollab>
                      {item.name}
                      <div onClick={() => deleteData(item.id)}>
                        <CloseComponent />
                      </div>
                    </ChooseCollab>
                  );
                })
              : "Not Assigned Yet"}
          </div>
        </Text>
      </TabComponent>

      <Browse>
        {/*<Button>Cancel</Button>*/}
        <Button
          onClick={() => {
            changeTab();
          }}
          main
        >
          Ok
        </Button>
      </Browse>
    </>
  );
};

export default OverviewComponent;
