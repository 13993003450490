import React from 'react';
import SearchComponent from '../Svgs/Search.component';
import { Icon, Input, InputContainer } from './Input.style';

const InputComponent = (props) => {
  return (
    <InputContainer>
      <Input {...props} />
      {props?.search ? (
        <Icon>
          <SearchComponent />
        </Icon>
      ) : (
        ''
      )}
    </InputContainer>
  );
};

export default InputComponent;
