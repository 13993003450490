import React, { useState, useEffect } from 'react';
import { Text } from '../../../utils/shared.style';
import { Button } from './NewObjective.style';
import DashboardInputComponent from '../../Dashboard/DashboardHeader/DashboardInput.component';

import { Line } from '../../Dashboard/Objective/Objective.style';
import InputComponent from '../../Input/Input.component';
import ObjectiveComponent from '../../Svgs/Objective.component';
import { InputContainer, ObjectiveHeader, Search } from './NewObjective.style';
import TabComponent from './Tab.component';
import { AlignmentButton } from './NewObjective.style';

const AlignmentComponent = ({ formData, setFormData, okrData, setModalComponent }) => {
  console.log('In Alignment Component!');
  console.log(okrData);
  const [okr, setOkr] = useState([]);
  const [FilterData, setFilterData] = useState([]);

  const handleOnChange = (e) => {
    let val = e.target.value;

    let data = okr.filter((item) => {
      if (item.title)
        return item.title.toLowerCase().includes(val.toLowerCase());
      else
        return false;
    });
    console.log(`Filter Data in Handle On Change ${FilterData}`);
    setFilterData(data || []);
  }

  useEffect(() => {
    if (okrData) {
      setOkr(okrData.okrs || []);
      setFilterData(okrData.okrs || []);
    }
  }, [okrData])

  return (
    // <ObjectiveHeader>
    <>
      <div style={{ marginTop: '2rem', marginBottom: '1rem', marginLeft: "1rem" }}>
        <Text bold>Align Objective</Text>
        <Line />
      </div>
      <TabComponent isOpen={true} header='Details' browse={false}>
        <InputContainer lg>
          <Text>Aligned To</Text>
          <InputComponent disabled={true} style={{ color: "black", fontWeight: "500" }} value={formData.name} placeholder="eg Increase Revenue" width='90%' />
          {/* <InputComponent width='100%' /> */}
        </InputContainer>
      </TabComponent>
      <TabComponent header='Align To' browse={false}>
        <ObjectiveHeader>
          <InputContainer>
            <Text>Aligned To</Text>
            <InputComponent search={true} onChange={e => handleOnChange(e)} width='100%' />
          </InputContainer>
          <InputContainer>
            <Text>Aligned To</Text>
            <DashboardInputComponent placeholder="View by:" value="Manager" width='100%' />
          </InputContainer>
        </ObjectiveHeader>
        <Text light className='underline'>
          <em>Search Results({FilterData.length})</em>
        </Text>
        <div style={{ maxHeight: "200px", overflowY: "scroll", padding: "0px 20px" }}>
          {
            FilterData.map((item) => {
              return (<> <Search>
                <ObjectiveComponent className='mr-2' />
                <Text onClick={() => { setFormData({ name: item.title, id: item._id }) }} style={{ cursor: "pointer" }} >{item.title}</Text>
                <Line />
              </Search>
              </>)
            })
          }
        </div>
        {/* <Search>
          <ObjectiveComponent className='mr-2' />
          {

          }
          <Text onClick={(e)=>{setFormData({...formData,name:"Lorem ipsum"})}} style={{cursor:"pointer"}} >Lorem ipsum</Text>
        </Search>
        <Line />
        <Search>
          <ObjectiveComponent className='mr-2' />
          <Text onClick={(e)=>{setFormData({...formData,name:"Lorem ipsum"})}} style={{cursor:"pointer"}}>Lorem ipsum</Text>
        </Search>
        <Line /> */}
      </TabComponent>
      <div style={{ paddingTop: "100px" }}>
        <Line />
        <AlignmentButton>

          <Button style={{ paddingRight: "20px", paddingLeft: "20px" }} onClick={() => { setModalComponent("initial") }}>Cancel</Button>
          <Button style={{ paddingRight: "10px", paddingLeft: "10px", marginLeft: "10px" }} onClick={() => { setModalComponent("initial") }} main>Save Alignment</Button>
        </AlignmentButton>
      </div>
    </>
    // </ObjectiveHeader>
  );
};

export default AlignmentComponent;
