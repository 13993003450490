import React from 'react';

const GoComponent = (props) => {
  return (
    <svg
      width='24'
      height='21'
      viewBox='0 0 24 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M-1.04041e-06 11.0833L-9.38413e-07 9.91667L14.4018 9.91667L11.6054 7.43167L12.4215 6.56834L16.202 9.95167L16.202 11.0483L12.4095 14.4317L11.5934 13.5742L14.4018 11.0833L-1.04041e-06 11.0833ZM13.2016 1.16669C14.9786 1.16658 16.7207 1.64584 18.2341 2.55114C19.7475 3.45644 20.9727 4.75225 21.7734 6.29432C22.5741 7.83639 22.9189 9.56422 22.7693 11.2855C22.6198 13.0067 21.9818 14.6539 20.9264 16.0436C19.8709 17.4332 18.4395 18.5109 16.7914 19.1567C15.1432 19.8024 13.3431 19.9908 11.5913 19.701C9.83954 19.4112 8.20488 18.6544 6.86929 17.5151C5.5337 16.3757 4.54958 14.8983 4.02649 13.2475L2.87435 13.5858C3.63451 15.9892 5.25664 18.0476 7.44581 19.3868C9.63499 20.726 12.2458 21.257 14.8037 20.8834C17.3615 20.5098 19.6965 19.2563 21.3842 17.3508C23.0718 15.4453 24 13.0144 24 10.5C24 7.9856 23.0718 5.55467 21.3842 3.64919C19.6966 1.74372 17.3615 0.49022 14.8037 0.116594C12.2458 -0.257032 9.63499 0.274026 7.44582 1.61322C5.25664 2.95241 3.63452 5.0108 2.87435 7.41417L4.02649 7.75251C4.62315 5.84112 5.83824 4.16787 7.49122 2.98138C9.1442 1.79488 11.1469 1.15846 13.2016 1.16669Z'
        fill='#003D67'
      />
    </svg>
  );
};

export default GoComponent;
