import React,{useState} from 'react';
import { dummyOkrData } from '../../Dashboard/Okr/Tabs/helpers';
import InnerTabComponent from '../../Dashboard/Okr/Tabs/InnerTab.component';
import TabComponent from '../../Dashboard/Okr/Tabs/Tab.component';
import CheckinModelComponent from '../../Dashboard/Okr/CheckinModel.component';

// OKR TAB COMPONENT OF MY OKR PAGE
const OkrTabComponent = ({aspirationalOkrData,criticalOkrData,render,setRender,checkIn={}}) => {

  const [isCheckInModel,setIsCheckInModel]=useState(false);
  const [checkInModelData,setCheckinModelData]=useState({});
  const DisplayCheckInModel=(type,id)=>{
    setIsCheckInModel(true);
    console.log(criticalOkrData);
    console.log(type,id);
    setCheckinModelData(type==="critical"?criticalOkrData.find((item,i)=>item._id===id):aspirationalOkrData.filter((item,i)=>item._id===id));

  }
  console.log(checkInModelData)
  return (
    <>
      <TabComponent>
        {/* Inner Tab  */}
        <InnerTabComponent onClicked={(type,id)=>DisplayCheckInModel(type,id)} type="critical" okrData={criticalOkrData} okrs={dummyOkrData} />
      </TabComponent>
      <TabComponent headerText='Aspirational OKR'>
        <InnerTabComponent  onClicked={(type,id)=>DisplayCheckInModel(type,id)} type="aspirational" okrData={aspirationalOkrData}  okrs={dummyOkrData} />
      </TabComponent>
      {
        isCheckInModel&&<CheckinModelComponent render={render} setRender={setRender} setIsCheckInModel={setIsCheckInModel} okr={checkInModelData}/>
      }
    </>
  );
};

export default OkrTabComponent;
