import { createContext, useState } from 'react';

const dt=new Date();
const dtt=[dt.getDate(),dt.getMonth()+1,dt.getFullYear()];
export const ModalContext = createContext({
  modal: false,
  toggle: () => {},
});

const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [selectedDate,setDate]=useState({startDate:dtt,endDate:dtt});
  const [datefilter,setFilterDate]=useState({startDate:[],endDate:[]});

  const toggle = () => {
    setOpen((state) => !state);
  };
  const setDates=(data,factor)=>{
    
    
    let m=(data.getMonth()+1);
    let d=data.getDate();
    let y=data.getFullYear();

   
    
  
    setDate({...selectedDate,[factor]:[d,m,y]});
    setFilterDate({...datefilter,[factor]:[d,m-1,y]})
  }

  return (
    <ModalContext.Provider value={{ open, toggle, setDates, selectedDate,datefilter }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
