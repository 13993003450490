import React,{useState,useEffect} from 'react';
import { LeftCheckinWrapper,GraphContainer,KeyResults } from './Okr.style';
import ProgressBar from './Tabs/ProgressBar';
import { Progress, } from '../../MyOkr/MyOkrTab/MyOkrTab.style';
import { Button } from '../Request/Request.style';
import InputComponent from '../../Input/Input.component';
// import { Line } from './Okr.style';
import { Line } from '../Objective/Objective.style';
import KeyRes from '../../Svgs/KeyRes.component';
import TabComponent from '../../MyOkr/NewObjective/Tab.component';
import { useAuth } from '../../../context/Auth';
// import {User} from '../../../api';

import axios from 'axios';
import { User } from '../../../api/Api';
function LeftCheckinContainer({data}) {
    console.log(data);
    const {idToken}=useAuth();
    const [owner,setOwner]=useState("");
    useEffect(()=>{
        if(data?.owners[0])
        {
        axios.get(User.getUserById(data?.owners[0]),{
            headers:{
                Authorization:`${idToken}`
                }
            
        }).then(res=>setOwner(res.data.user.name)).catch(err=>console.log(err));
        }

    },[])
    function getStatus(n){
        if(n==="C")
        return "Completed"
        else if(n==="S")
        return "Not Started"
        else if(n==="T")
        return "on Track"
        else
        return "At Risk"
    }
    
    function getColor(n){
        if(n==="C")
        return "#00B8E0"
        else if(n==="S")
        return "#B7B7B7"
        else if(n==="T")
        return "#099E60"
        else
        return "#E95252"
    }
    
    return (
        <LeftCheckinWrapper>
            <div style={{display:"flex"}}>
            <Button style={{color:`${getColor(data?.status)}`,borderColor:`${getColor(data?.status)}`,borderRadius:"17px",padding:"3px 10px"}}>{getStatus(data?.status)}</Button>
            <Progress style={{marginLeft:"50px",width:"50%",display:"flex"}}>
                <ProgressBar text={false} width={`${data?.progress}%`}/><span style={{display:"flex",alignItems:"center",justifyContent:"center",fontWeight:"600",fontSize:"1.2rem"}}> {data?.progress}%</span>

                
            </Progress>
            </div>
            <GraphContainer style={{height:"150px"}}>

            </GraphContainer>
            <div className='group'>
                    <div className='groupChild'>
                               
                               
                     <label>Owner</label>
                               <InputComponent value={owner} date={false}/>
                    </div>
                    <div className='groupChild'>
                               
                               
                     <label>Type</label>
                               <InputComponent value={data?.type} date={false}/>
                    </div>
                    <div className='groupChild'>
                               
                               
                     <label>TimePeriod</label>
                               <InputComponent value={`${new Date(data?.timeframe[0]).toLocaleDateString()} to ${new Date(data?.timeframe[1]).toLocaleDateString()}`} date={false}/>
                    </div>
                    {/* <div className='groupChild'>
                               
                               
                     <label>Last Updated</label>
                               <InputComponent value="2 weeks ago" date={false}/>
                    </div> */}
            </div>
            <Line style={{margin:"13px 0px"}}/>
            <div className="keyresult">
            <TabComponent  header={`Key Results`} browse={false}>
                
                    {data?.keyresults?.map((item,idx)=>{
                return(
                    <>
                    <KeyResults>
                    <KeyRes/>
                    <div className='text'>
                   {item.title}
                    </div> 
                    
                    </KeyResults>
                    <div style={{fontSize:"10px",marginTop:"4px",color:"grey"}}>{item.milestones.length} milestones</div>
                    {(idx+1)!==data.keyresults.length&&<Line/>} 
                    </>)
                    })}
                     
                
                          
                </TabComponent>

            </div>
            <Line style={{margin:"13px 0px"}}/>
            <div className="Alignment">
            <TabComponent  header={`MileStones`} browse={false}>
            {data?.keyresults?.map((key,id)=>{
                return(<>{key?.milestones?.map((item,idx)=>{
                    return(
                        <>
                        <KeyResults>
                        <KeyRes/>
                        <div className='text'>
                       {item.title}
                        </div> 
                        
                        </KeyResults>
                    
                        {(idx+1)!==data?.keyresults?.length&&<Line style={{margin:"6px 0px"}}/>} 
                        </>)
                })}</>)
               
                    })}
                          
                </TabComponent>

            </div>
        </LeftCheckinWrapper>
    )
}

export default LeftCheckinContainer
