import styled from 'styled-components';
import { myFlex } from '../../../utils/shared.style';

export const MainContainer = styled.section`
  ${myFlex};
  min-height:${({page})=>page==="Objectives"?"91.5vh":"100vh"};

`;

export const AppContainer = styled.div`
  /* ${myFlex}; */
  /* flex-grow: 1; */
  width: 100%;
  margin-top: 0.3rem;
  
`;
