import React, { useState,useEffect } from "react";
import { Text } from "../../../utils/shared.style";
import { options } from "../../Dashboard/DashboardHeader/DashboardHeader.component";
import DashboardInputComponent from "../../Dashboard/DashboardHeader/DashboardInput.component";
import FilterComponent from "../../Dashboard/DashboardHeader/Filter.component";
import { Circle, NotificationBody } from "../../Header/Header.style";
import UserComponent from "../../Svgs/User.component";
import { MyOkrHeader, User } from "./MyOkrHeader.style";

//My Okr Header Component
const MyOkrHeaderComponent = ({ userData,filterByTime }) => {
  const [formData, setFormData] = useState({
    timeframe: [],
   date:{
     startDate:"",
     endDate:""
   }
  });
  useEffect(()=>{
    if(formData.timeframe.length===2)
    {
      filterByTime(formData.timeframe)
    }

  },[formData])

  
  
  return (
    <MyOkrHeader>
      <User>
        <NotificationBody>
          <Circle>
            <UserComponent />
          </Circle>
          <div style={{ lineHeight: 1.8 }}>
            <Text lg bold>
              {userData?.name || "Jonas"}
            </Text>
            <NotificationBody>
              <Text>{userData.access} | {userData.role}</Text>
              <Text bold style={{ marginLeft: "2rem" }}>
                Approver:
              </Text>
              <Text>{userData?.reportingto || "Self"}</Text>
            </NotificationBody>
          </div>
        </NotificationBody>
        <DashboardInputComponent
          height="auto"
          value={
            !formData.date.startDate && !formData.date.endDate
              ? "select"
              : `${formData.date.startDate} to ${formData.date.endDate}`
          }
          lg
          placeholder="View by"
        >
          <FilterComponent
            component="newObjective"
            options={[]}
            formData={formData}
            setFormData={setFormData}
            name="timeframe"
            date
          />
        </DashboardInputComponent>
      </User>
    </MyOkrHeader>
  );
};

export default MyOkrHeaderComponent;
