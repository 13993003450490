import styled, { css } from 'styled-components';
import { flexCenter, flexColumn, myFlex } from '../../../utils/shared.style';

export const DashboardHeader = styled.div`
  ${myFlex};
  justify-content: space-between;
  padding: 1rem;
  background-color: ${(props) => props.theme.backgroundColor};
  flex-wrap: wrap;
  line-height: 2;
  @media (max-width: 500px){
    flex-direction:column;
    button{
      display:block;
      margin-x :auto;
      width:50px;
      align-self:center;
    }

  }
  
`;

export const DashboardText = styled.h1`
  font-size: 2rem;
  font-weight: bold;
`;

export const DashboardInputContainer = styled.div`
  ${myFlex};
  flex-wrap: wrap;
  
  @media (min-width: 1179px){
    flex:7;
    display:flex;
    justify-content:flex-end;
  }
 @media (max-width: 500px){
   flex-direction:row;
  justify-content:space-between;
 }
`;

export const sharedStyle = css`
  ${myFlex};
  min-width: ${(props) => (props?.lg ? '25rem' : props?.width || '13rem')};
  flex-grow: 0;
  padding: 4px 2px 5px 9px;
  border-radius: 4px;
  border: solid 1px #dbdbdb;
  margin-right: 1rem;
  justify-content: space-between;
  align-items: center;
`;

export const FilterContainer = styled.div`
  width: calc(100% - 15px);
  ${flexColumn};
  /* height: 20rem; */
  height: 0rem;
  margin: 5px 10px 579px 9px;
  padding: 7px 5px 15px 11px;
  z-index:-1000;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #eaeaea;
  background-color: #fff;
  position: absolute;
  left: -12px;
  ${lg=>lg&&`width:100%;`}
  opacity: 0;
  transition: all 500ms linear;
  padding: 1px;
  overflow-y: scroll;
  cursor: default;
`;

export const DashboardInputWrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin-left:${({mr})=>mr?"0px":"14px"}1;
 
  &:hover ${FilterContainer} {
    opacity: 1;
    z-index: 10000;
    height: ${({height})=>height==="auto"?'auto':'20rem'};
  }
  @media (max-width:500px)
  {
    // margin-left:0px;
    margin-left:auto;
    margin-right:auto;
  }
`;
export const DashboardInput = styled.div`
  ${sharedStyle};

  @media (max-width: 767px) {
    margin: 1rem 0.2rem;
  }
  ${({mr})=>mr==="0"?`margin:0px`:""}
  @media (max-width: 500px){
    
    
    min-width:${({viewBy})=>viewBy==='date'?'17rem !important':""};
    .dropdown{
      margin-left:0px !important;
    }
    
  }
  ${({bdrcolor})=>bdrcolor&&`border-color:${bdrcolor}`}
 
`;

export const InputContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  border-bottom: ${(props) => `1px solid ${props.theme.greyDark1}`};
  color: ${(props) => `${props.theme.greyDark2}`};
  padding: 5px 25px;
  ${({placeholder})=>placeholder==='search'&&`padding-left:15px`}
  &::-webkit-input-placeholder {
    font-style: italic;
    color: ${(props) => `${props.theme.greyDark2}`};
  }
`;

export const SearchContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 5px;
`;
export const InputPlaceholder = styled.span`
  font-size: 14px;
  font-weight: 700;
  ${myFlex}

  @media (max-width:500px)
  {
    .dropdown {
      margin-left:0px !important;
    }
  }
  
`;

export const InputVal = styled.span`
  font-weight: normal;
  margin-left: 4px;
  font-size: inherit;
`;

export const IconContainer = styled.span`
  ${myFlex};
  ${flexCenter};
  
  @media (max-width:500px){
    .dropdown{
      margin-left:0px !important;
    }
  }
`;

export const Button = styled.button`
  ${sharedStyle};
  background-color: ${(props) => props.theme.blueDark2};
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  padding: 0 1rem;

  @media (max-width: 970px) {
    margin: 1rem 0;
    padding: 10px;
  }
`;

export const Option = styled.div`
  ${myFlex};
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 1rem 0;
  cursor: default;
`;

export const Label = styled.label`
  color: #666;
  font-weight: normal;

  & span {
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    
  }

  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    top: 3px;
    padding: 2px;
    margin: 0 5px 0 0;
    width: 5px;
    height: 5px;
    border-radius: 11px;
    border: ${(props) => `2px solid ${props.theme.greyDark1}`};
    background-color: transparent;
    cursor:pointer;
  }
  
`;

export const OptionContainer = styled.div`
  ${flexColumn}
`;

export const RadioInput = styled.input`
  display: none;

  &:checked + ${Label}::after {
    border-radius: 50%;
    width: 1px;
    height: 1px;
    position: absolute;
    top: 10px;
    padding: 2px;
    margin: 0 5px 0 0;
    left: 10px;
    content: ' ';
    display: block;
    background: #004c97;
    cursor: pointer;
  }
`;

export const DateContainer = styled.div`
  ${myFlex};
  border-top: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
  margin-bottom: 1rem;
`;

export const Date = styled.div`
  width: 50%;
  padding: 5px;
  ${flexColumn};
  ${flexCenter};
  border-right: solid 1px #dbdbdb;
  cursor:pointer;
  border-bottom: ${(props) =>
    props?.active ? `3px solid ${props.theme.blueDarkPrimary}` : ''};
`;
export const Dates = styled.div`
  width: 50%;
  padding: 5px;
  ${flexColumn};
  ${flexCenter};
  border-right: solid 1px #dbdbdb;
  cursor:pointer;
  border-bottom: ${(props) =>
    props?.active ? `3px solid ${props.theme.blueDarkPrimary}` : ''};
`;
