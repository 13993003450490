import React from 'react';
import { Text } from '../../../utils/shared.style';
import AddReqComponent from '../../Svgs/AddReq.component';
import CloseReqComponent from '../../Svgs/CloseReq.component';


import {
  ProgressBarContainer,
  ProgressPercentage,
} from '../Okr/Tabs/Tab.style';
import SvgIcon from './SvgIcon';

import {
  Detail,
  FlexContainer,
  Heading,
  NewRequestContainer,
  Dot,
  NewReq,
  Request,
  Created,
  Name,
 
  Button,
  Status,
} from './Request.style';

/**
 * New Request Component-A Component to display new request with its status
 * @param {bool} props.isRequestNew checks if request is new
 * @param {approved} props.approved checks if request is approved
 * @param {string} props.progress progress percentage
 */

 

const getColor=(temp)=>{
  if(temp==="not Started")
  return "#b7b7b7";
  else if(temp==="on Track")
  return "#099e60"
  else if(temp==="Completed")
  return "#00b8e0"
  else
  return "#e95252"
}

const NewRequestComponent = ({
  isRequestNew = false,
  approved = false,
  progress,
  handleOnClick=()=>{},
  time = null,
  showMeta = true,
  request = 'Lorem ipsum',
  IconName,
  name="",
 title="",
 owner="",
 okrID="",
 _id="",
 showDetails=()=>{}
}) => {
  return (
    <NewRequestContainer>
      {/* Header for request */}

      <FlexContainer>
        <Heading>
          {/* <ObjectiveComponent style={{ marginRight: '0.5rem' }} /> */}
          {/* <CheckInsComponentSvg style={{ marginRight: '0.5rem' }} />
           */}
           <SvgIcon IconName={IconName}  />
          Objective
          {isRequestNew ? (
            <FlexContainer>
              <Dot />
              <NewReq>New</NewReq>
            </FlexContainer>
          ) : (
            ':'
          )}
        </Heading>
        <Detail style={{cursor:"pointer"}} onClick={()=>showDetails(okrID)}>
          <em>View Details</em>
        </Detail>
      </FlexContainer>

      {/* Request Body  */}
      <FlexContainer>
        <Request>{title}</Request>
      </FlexContainer>

      {/* Progress Indicator in Percentage  */}
      {progress ? (
        <FlexContainer>
          <Created>
            <Heading>Progress:</Heading>
            <ProgressBarContainer>
              <div style={{ width: '9rem' }}>
                <ProgressPercentage width={progress} bg={ getColor(time)} /> 
                <div style={{position:"absolute",top:"50%",transform:"translateY(-50%)",left:"100%",color:"#000",fontWeight:"800"}}>
                  {progress}
                </div>
              </div>
            </ProgressBarContainer>
          </Created>
          {time ? (
            <Created>
              <Heading>Status</Heading>
              <Text color={getColor(time)} bold>{time}</Text>
            </Created>
          ) : (
            ''
          )}
          {/* <Created> */}
            {/* <Heading>Status</Heading> */}
            {/* <Status approved={approved} meta={showMeta}>
              Approved
            </Status>
          </Created> */}
        </FlexContainer>
      ) : (
        ''
      )}

      {/* Requst Meta Data  */}
      {showMeta ? (
        <FlexContainer>
          <Created>
            <Heading>Created By</Heading>
            <Name>{owner}</Name>
          </Created>
          <Created>
            <Heading>Dept</Heading>
            <Name>{name}</Name>
          </Created>

          {/* Approve/Reject request  */}
          <FlexContainer>
            {isRequestNew ? (
              <>
                <CloseReqComponent onClick={()=>{handleOnClick(_id,"R")}} className='no-mr' />
                <AddReqComponent onClick={()=>handleOnClick(_id,'A')} className='no-mr' />
              </>
            ) : (
              <Button approved={approved}>
                {approved ? 'Approved' : 'Rejected'}
              </Button>
            )}
          </FlexContainer>
        </FlexContainer>
      ) : (
        ''
      )}
    </NewRequestContainer>
  );
};

export default NewRequestComponent;
