import React from 'react';

const WarningComponent = (props) => {
  return (
    <svg
      width='2'
      height='8'
      viewBox='0 0 2 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 7.09049C2 7.33797 1.89899 7.55452 1.69697 7.74014C1.50842 7.91338 1.27946 8 1.0101 8C0.740741 8 0.50505 7.91338 0.30303 7.74014C0.10101 7.55452 0 7.33179 0 7.07193C0 6.81207 0.10101 6.59551 0.30303 6.42227C0.50505 6.24903 0.740741 6.16241 1.0101 6.16241C1.27946 6.16241 1.50842 6.25522 1.69697 6.44084C1.89899 6.62645 2 6.843 2 7.09049ZM0.181818 0.76102C0.181818 0.550657 0.26936 0.37123 0.444444 0.222737C0.619529 0.0742457 0.814815 0 1.0303 0C1.25926 0 1.45455 0.0742457 1.61616 0.222737C1.79125 0.37123 1.87879 0.575406 1.87879 0.835267C1.87879 1.03326 1.83165 1.50967 1.73737 2.2645C1.73737 2.2645 1.65657 2.87084 1.49495 4.08353C1.42761 4.529 1.37374 4.88786 1.33333 5.16009C1.27946 5.33333 1.18519 5.43851 1.05051 5.47564C1.02357 5.47564 0.976431 5.45708 0.909091 5.41995C0.841751 5.38283 0.787879 5.33333 0.747475 5.27146C0.626263 4.67749 0.498316 3.82985 0.363636 2.72854C0.242424 1.61485 0.181818 0.95901 0.181818 0.76102Z'
        fill='white'
      />
    </svg>
  );
};

export default WarningComponent;
