import react, { useState } from 'react';

const selectStyle = {
    position: "relative",
    width: "100%",
    border: "1px solid #dbdbdb",
    borderRadius: "4px",
    fontSize: "1.3rem"
}

const labelStyle_1 = {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1
}

const labelStyle_2 = {
    width: "91%",
    padding: "6px 15px",
    whiteSpace: "nowrap"
}

const dataListStyle = {
    width: "100%",
    padding: "0",
    margin: "0",
    height: "150px",
    overflowY: "scroll",
    overflowX: "hidden",
    listStyle: "none",
    position: 'absolute',
    left: 0,
    top: 35,
    zIndex: 500,
    backgroundColor: "white",
    border: "1px solid #dbdbdb",
    borderRadius: "4px"
}

const listStyle = {
    width: "90%",
    padding: "4px 15px",
    cursor: "pointer"
}

function SelectComponent({ selectValue, selectedValue, datalists }) {

    const [list, setList] = useState(false);
    return <div className='select' style={selectStyle} >
        <label id="list-btn" onClick={() => setList(!list)} style={labelStyle_1}></label>
        <label style={labelStyle_2} id={selectedValue?.value}>{selectedValue?.label || selectedValue?.name}</label>
        {list && <ul className="data-list" style={dataListStyle}>
            {datalists.map(datalist => {
                return <li style={listStyle} id={datalist?.value} onClick={() => selectValue({ target: { name: datalist?.label || "role", value: datalist?.value, extraValue: datalist?.extraValue } })}>{datalist?.label || datalist?.name}</li>
            })}
        </ul>}
    </div>
}

export default SelectComponent;