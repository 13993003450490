import React from 'react';

const BackComponent = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='10'
      viewBox='0 0 14 10'
      fill='none'
      {...props}
    >
      <path
        opacity='0.3'
        d='M1.04038 4.54038C0.78654 4.79422 0.78654 5.20578 1.04038 5.45962L5.17695 9.59619C5.43079 9.85003 5.84235 9.85003 6.09619 9.59619C6.35003 9.34235 6.35003 8.93079 6.09619 8.67695L2.41924 5L6.09619 1.32304C6.35004 1.0692 6.35004 0.657646 6.09619 0.403805C5.84235 0.149964 5.4308 0.149964 5.17696 0.403805L1.04038 4.54038ZM13.5 4.35L1.5 4.35L1.5 5.65L13.5 5.65L13.5 4.35Z'
        fill='black'
      />
    </svg>
  );
};

export default BackComponent;
