import React, { useState, useEffect } from "react";
import ChartComponent from "./Chart.component";
import { ChartViewContainer, ChildChart } from "./ChartView.style";
import { Tree, TreeNode } from "react-organizational-chart";
import { useAuth } from "../../../context/Auth";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loader from "../../Loading/Loader";

const ChartViewComponent = () => {
  const history = useHistory();
  const { idToken, uid } = useAuth();
  const [dt, setDt] = useState(null);
  const [refData, setRefData] = useState(null);
  const [okrs, setOkrs] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [type, setType] = useState("SuperAdmin");
  const [loading, setLoading] = useState(false);

  //push children to parent
  function getObject(theObject, id, user) {
    if (theObject?.id === user.reportingto) {
      theObject.children.push({
        id: user.uuid,
        name: user.name,
        role: user.role,
        department: user.departmentid,
        progress: 10,
        status: false,
        root: false,
        parent: user.reportingto,
        children: [],
      });
    } else if (theObject?.children?.length === 0) {
      return;
    }
    theObject?.children?.forEach((child) => getObject(child, id, user));
  }

  //create hierarchy structure
  const createHierarchyData = (uData) => {
    var hData = { root: { children: [] } };
    let dtt = uData.find((user) => user.reportingto === "");
    hData.root = {
      ...hData.root,
      id: dtt.uuid,
      name: dtt.name,
      role: dtt.role,
      department: dtt.departmentid,
      progress: 10,
      status: false,
      root: true,
      parent: dtt.reportingto,
      children: [],
    };

    uData.forEach((user) => {
      getObject(hData.root, user.reportingto, user);
    });
    // console.log(hData);
    return hData;
  };
  const expandIcon = (data, root) => {
    if (root) {
      setType("SuperAdmin");
    } else {
      setType("member");
      console.log(data);
      setChartData(data);
    }
  };
  const viewAllOkr = (id) => {
    history.push("/okr", {
      id: id,
    });
  };

  const renderSubNodes = (items) =>
    items?.children?.map((item) => {
      return (
        <TreeNode
          label={
            <div>
              {/* <HierarchyNode
                data={item}
                datas={dt}
                okrs={okrs}
                refDatas={refData}
                setData={setDt}
                id={item?.id}
              /> */}
            </div>
          }
        >
          {item.status === true ? renderSubNodes(item) : <></>}
        </TreeNode>
      );
    });
  console.log(dt, refData);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await axios
        .post(
          `https://s2e.onrender.com/api/v1/hierarchy`,
          {},
          {
            headers: {
              Authorization: `${idToken}`,
              uuid: uid,
            },
          }
        )
        .then((res) => {
          const hierarchyData = createHierarchyData(res.data.user_data);
          setDt({ ...hierarchyData });
          setRefData(createHierarchyData(res.data.user_data));
          axios
            .post(
              `https://s2e.onrender.com/api/v1/okr`,
              {
                status: ["S", "R", "T", "C"],
              },
              {
                headers: {
                  Authorization: `${idToken}`,
                },
              }
            )
            .then((res) => {
              const okrdata = res.data.okrs;
              console.log(okrdata);
              setOkrs(okrdata === null ? [] : okrdata);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    };

    getData();
  }, []);
  console.log(okrs);
  return (
    <ChartViewContainer>
      {loading ? (
        <Loader type="spin" color="cyan" />
      ) : (
        <>
          {type === "SuperAdmin" ? (
            <Tree
              lineWidth={"2px"}
              lineColor={"green"}
              lineBorderRadius={"10px"}
              label={
                <ChartComponent
                  viewAllOkr={viewAllOkr}
                  expandIcon={expandIcon}
                  data={refData?.root}
                  root={true}
                  okrs={okrs}
                  name={refData?.root?.name}
                />
              }
            >
              {refData?.root?.children?.map((node) => {
                return (
                  <TreeNode
                    label={
                      <ChartComponent
                        viewAllOkr={viewAllOkr}
                        expandIcon={expandIcon}
                        data={node}
                        root={false}
                        okrs={okrs}
                        name={node.name}
                      />
                    }
                  >
                    {/* {node?.children.map((item)=><TreeNode label={<ChartComponent data={item} root={false} okrs={okrs} name={item.name} />}/>)} */}
                  </TreeNode>
                );
              })}
            </Tree>
          ) : (
            <Tree
              lineWidth={"2px"}
              lineColor={"green"}
              lineBorderRadius={"10px"}
              label={
                <ChartComponent
                  viewAllOkr={viewAllOkr}
                  expandIcon={expandIcon}
                  data={chartData}
                  root={true}
                  okrs={okrs}
                  name={chartData.name}
                />
              }
            >
              {chartData.children.map((node) => {
                return (
                  <TreeNode
                    label={
                      <ChartComponent
                        viewAllOkr={viewAllOkr}
                        expandIcon={expandIcon}
                        data={node}
                        root={false}
                        okrs={okrs}
                        name={node.name}
                      />
                    }
                  >
                    {/* {node?.children.map((item)=><TreeNode label={<ChartComponent data={item} root={false} okrs={okrs} name={item.name} />}/>)} */}
                  </TreeNode>
                );
              })}
            </Tree>
          )}

          <div style={{ alignSelf: "flex-end" }}>
            <button
              style={{
                fontWeight: "500",
                fontSize: "2rem",
                display: "inline-block",
                textAlign: "center",
                width: "27.69px",
              }}
            >
              -
            </button>
            <button
              style={{
                fontWeight: "500",
                fontSize: "2rem",
                display: "inline-block",
              }}
            >
              +
            </button>
          </div>
        </>
      )}
    </ChartViewContainer>
  );
};

export default ChartViewComponent;
