import React from 'react';
import { Recatangle, StatusContainer, StatusWrapper } from './Objective.style';

/**
 *
 * Component that displays status color with description
 */

const StatusComponent = ({
  text1 = 'On Track',
  text2 = 'Not Started',
  text3 = 'Completed',
  text4 = 'At Risk',
  white = false,
}) => {
  return (
    <StatusContainer white={white}>
      <StatusWrapper white={white}>
        <Recatangle bg='#099e60' />
        {text1}
      </StatusWrapper>
      <StatusWrapper white={white}>
        <Recatangle bg='#b7b7b7' />
        {text2}
      </StatusWrapper>
      <StatusWrapper white={white}>
        <Recatangle bg='#00b8e0' />
        {text3}
      </StatusWrapper>
      <StatusWrapper white={white}>
        <Recatangle bg='#e95252' />
        {text4}
      </StatusWrapper>
    </StatusContainer>
  );
};

export default StatusComponent;
