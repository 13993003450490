import styled, { css } from 'styled-components';
import { flexCenter, flexColumn, myFlex } from '../../../utils/shared.style';

export const sharedBox = css`
  ${flexColumn};
  min-width: 35%;
  min-height: 25.5rem;
  padding: 6px 15px;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(187, 187, 187, 0.25);
  background-color: #fff;
  margin-bottom: 1rem;
  flex-grow: 1;
  margin-right: 1rem;

  ${({ modal }) =>
    modal &&
    `
    width:85%;
    margin:auto;
    // margin-top: 25vh;
    min-height: fit-content;
    position:fixed;
    top:50%;
    left:50%;
    max-width:1079px;

    transform:translateX(-50%) translateY(-50%);
    // transform:translateY(-50%);
    max-height:90vh;
    overflow-y:scroll;
    @media (max-width: 768px) {
      height: fit-content;
      /* margin: 25vh auto;
      width: 90%; */
      width: 95%;
      
      top:50%;
      left:50%;
      max-height:90vh;
      overflow-y: scroll;
      
      // transform:translateY(-50%);
    }
  `};

  
 
`;

export const Wrapper = styled.div`
  

  ${myFlex};
  width:${({ flex }) => flex === "1" ? "100%" : "50%"};
  ${({ modal }) =>
    modal &&
    `
   position: fixed;
   top:0;
   left:0;
   bottom:0;
   right:0;
   width: 100vw;
   background: rgba(0,0,0,0.5);
   z-index: 1000000;
  display:block;

   ${flexCenter}
  `}
  @media (max-width: 915px) {
    width:100%;
  }
  
`;

export const ObjectiveContainer = styled.div`
  ${sharedBox}
  ${({modal})=>!modal&&`
  min-height:255px;
  max-height:255px;
  box-sizing:border-box;
  // max-width:656px;
  margin-right:14px;
  
  
  `}
  
  margin-bottom:14px;
  @media (max-width:768px)
  {
    margin-right:0px;
  }
  ${({modal})=>modal&&"max-width:1079px;"}
 
`;

export const ObjectiveHeader = styled.div`
  ${myFlex};
  justify-content: space-between;
  flex-wrap: wrap;
  ${({ headerText, modal }) => { return (headerText === "Request" && modal === true) ? `background-color:#9F9DFF; ` : `` }}
`;

export const TextContainer = styled.div`
  ${myFlex}
  ${flexCenter};
  justify-content: space-between;
`;

export const PinFeature=styled.div`
    color:#fff;
    display:flex;
    min-width:200px;
    justify-content:space-around;
`

export const Text = styled.h1`
  font-size: 1.6rem;
  font-weight: 500;
  margin-right: 1rem;
`;

export const InfoContainer = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  ${myFlex};
  ${flexCenter};
  background: ${(props) => props.theme.greyLight2};
  position: relative;

  &::before {
    content: '${(props) => props.info}';
    position: absolute;
    left: 2rem;
    display: none;
    padding: 1rem 6px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    min-width: 17rem;
    font-size: 1.2rem;
  }
  &:hover::before {
    display: ${(props) => (props.info ? 'block' : 'none')};
  }
`;

export const ButtonContainer = styled.div`
  ${myFlex};
  ${flexCenter}
`;

export const StatusContainer = styled.div`
  ${myFlex};
  justify-content: space-between;
  margin: ${(props) => (props?.white ? '0,5rem 0' : '2rem 0')};
  flex-wrap: wrap;
`;

export const Recatangle = styled.p`
  width: 11px;
  height: 8px;
  background-color: ${(props) => props.bg};
  margin-right: 0.5rem;
`;

export const StatusWrapper = styled.div`
  ${myFlex}
  ${flexCenter};
  font-size: 1.3rem;
  font-weight: 500;
  color: ${(props) => (props?.white ? '#dbdbdb' : 'inherit')};
  margin-right: ${(props) => (props?.white ? '0.9rem' : 0)};
`;

export const StatusProgress = styled.div`
  ${myFlex};
  width: 100%;
  height: 1.5rem;
  position: relative;
  margin-bottom: 1rem;

 border-radius:15px;
 overflow:hidden;
`;

export const Status = styled.div`
  ${myFlex};
  background: ${(props) => props.bg};
  width: ${(props) => props.width};
  height: 100%;
`;

export const sharedLine = css`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.greyDark1};
`;
export const Line = styled.div`
  ${sharedLine}
  ${({marginTop})=>marginTop?`margin-top:${marginTop};`:""}
`

export const BarGraphContainer = styled.div`
  ${myFlex};
  // justify-content: ${(props) => props.space ? props.space : "space-between"};
  
//  margin-left:42px;
  margin-top: 1rem;
  // justify-content:space-around;
  position:relative;
  @media (max-width:768px){
    .graphs{height:auto !important;
    }
  }
`;

export const ObjectiveLine=styled.div`

${sharedLine}
  ${({marginTop})=>marginTop?`margin-top:${marginTop};`:""}
  position:absolute;
  bottom:1.9rem;
`;
export const BarGraphWrapper = styled.div`
  ${flexColumn};
  width: 3.1rem;
  height: 11rem;
  overflow: hidden;
  justify-content: flex-end;

  
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    
  
  
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  
`;

export const Rect = styled.div`
  height: ${(props) => props.height};
  background: ${(props) => props.bg};
`;

export const Department = styled.div`
  ${myFlex};
  justify-content:${(props) => props.style ? props.style.style : "space-between"} ;
  // justify-content:space-around;
  `;

export const DepartmentText = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
`;
export const PinText=styled.div`
font-size:12px;
display:flex;
align-items:center;
justify-content:space-between;
`;

export const ObjectiveModalWrap = styled.div`
  ${myFlex};
  /* align-items: flex-end; */
  border-top: ${(props) => `1px solid ${props.theme.greyDark1}`};

  @media (max-width: 768px) {
    ${flexColumn}
  }
`;

export const OverallContainer = styled.div`
  ${flexColumn};
  width: 30%;
  margin-right: 1rem;
  border-right: ${(props) => `1px solid ${props.theme.greyDark1}`};
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    border-bottom: ${(props) => `1px solid ${props.theme.greyDark1}`};
    border-right: 0;
  }
`;

export const DepartmentContainer = styled.div`
  ${flexColumn};
  flex-grow: 1;
  @media (max-width:768px){
    min-height:230px;
  }
  
`;

export const ObjectiveCircle = styled.div`
  position: relative;
  padding: 3rem;
  margin: 3px;
  ${flexColumn};
  ${flexCenter};
  border: 10px solid;
  border-color: red green #00b8e0 grey;
  // padding:1rem;

  
  border-radius: 50%;
  color: #5a5a5a;
  & h1 {
    // height:100%;
    // width:100%;
    // z-index:100;
    // background-color:#fff;
    font-size: 1.8rem;
  }
`;
export const UserLevelScreenContainer=styled.div`
margin-top:10px;
display:flex;
// border:1px solid red;
flex:1;
`

export const OkrStatus=styled.div`
  flex:1;
  font-size:1.3rem;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin:0 8px;
  position:relative;
  .bodr{
    position:absolute;
    top:5px;
    left:0;
    bottom:5px;
    border-left:1px solid grey;
    font-size:2.2rem;
    @media and (max-width:990px)
    {
      font-size:1.9rem;
    }
  }
  ${({first})=>!first&&`
  // border-left:1px solid gray;
  `}
 
    border-bottom:${(props)=>props.border};
`

export const ProgressBar=styled.div`
height:200px;
width:200px;

`