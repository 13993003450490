import React from 'react';

const CloseReqComponent = (props) => {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.0012 0C8.82536 0 6.6984 0.645208 4.88926 1.85403C3.08012 3.06286 1.67007 4.78101 0.837421 6.79122C0.00476665 8.80142 -0.213094 11.0134 0.21139 13.1474C0.635873 15.2814 1.68363 17.2417 3.22218 18.7802C4.76072 20.3187 6.72095 21.3665 8.85497 21.791C10.989 22.2155 13.201 21.9976 15.2112 21.165C17.2214 20.3323 18.9395 18.9223 20.1483 17.1131C21.3572 15.304 22.0024 13.177 22.0024 11.0012C21.9992 8.08446 20.8392 5.28809 18.7767 3.22565C16.7143 1.16321 13.9179 0.00314608 11.0012 0ZM14.7903 15.7554L11.0012 11.952L7.20973 15.7554L6.48472 15.0185L10.269 11.2389L6.48472 7.43555L7.21448 6.70578L11.0012 10.4949L14.7903 6.70578L15.5177 7.43555L11.731 11.2389L15.5177 15.0185L14.7903 15.7554Z'
        fill='#F77575'
      />
    </svg>
  );
};

export default CloseReqComponent;
