import React from 'react';

const LogsComponent = () => {
  return (
    <svg
      className='nav'
      width='26'
      height='33'
      viewBox='0 0 26 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.3109 16.1139H9.84236C9.64951 16.1139 9.46457 16.1905 9.3282 16.3269C9.19184 16.4632 9.11523 16.6482 9.11523 16.841C9.11523 17.0339 9.19184 17.2188 9.3282 17.3552C9.46457 17.4915 9.64951 17.5681 9.84236 17.5681H20.3109C20.5037 17.5681 20.6887 17.4915 20.825 17.3552C20.9614 17.2188 21.038 17.0339 21.038 16.841C21.038 16.6482 20.9614 16.4632 20.825 16.3269C20.6887 16.1905 20.5037 16.1139 20.3109 16.1139Z'
        fill='white'
      />
      <path
        d='M20.3128 20.4731H9.84428C9.65968 20.4859 9.48679 20.5682 9.36054 20.7035C9.23428 20.8388 9.16406 21.017 9.16406 21.202C9.16406 21.3871 9.23428 21.5652 9.36054 21.7005C9.48679 21.8358 9.65968 21.9181 9.84428 21.9309H20.3128C20.4974 21.9181 20.6703 21.8358 20.7965 21.7005C20.9228 21.5652 20.993 21.3871 20.993 21.202C20.993 21.017 20.9228 20.8388 20.7965 20.7035C20.6703 20.5682 20.4974 20.4859 20.3128 20.4731Z'
        fill='white'
      />
      <path
        d='M9.11524 12.4782C9.11524 12.5737 9.13405 12.6683 9.17059 12.7565C9.20713 12.8447 9.26069 12.9249 9.32821 12.9924C9.39573 13.0599 9.47589 13.1135 9.56411 13.15C9.65233 13.1866 9.74688 13.2054 9.84237 13.2054H20.3109C20.5037 13.2054 20.6887 13.1288 20.825 12.9924C20.9614 12.856 21.038 12.6711 21.038 12.4782C21.038 12.2854 20.9614 12.1004 20.825 11.9641C20.6887 11.8277 20.5037 11.7511 20.3109 11.7511H9.84237C9.74675 11.7506 9.65199 11.7691 9.56357 11.8055C9.47514 11.8419 9.3948 11.8954 9.32719 11.9631C9.25957 12.0307 9.20603 12.111 9.16965 12.1994C9.13328 12.2879 9.11478 12.3826 9.11524 12.4782Z'
        fill='white'
      />
      <path
        d='M9.84236 8.8461H16.3865C16.5793 8.8461 16.7643 8.76949 16.9006 8.63313C17.037 8.49676 17.1136 8.31182 17.1136 8.11897C17.1136 7.92613 17.037 7.74118 16.9006 7.60482C16.7643 7.46845 16.5793 7.39185 16.3865 7.39185H9.84236C9.64951 7.39185 9.46457 7.46845 9.3282 7.60482C9.19184 7.74118 9.11523 7.92613 9.11523 8.11897C9.11523 8.31182 9.19184 8.49676 9.3282 8.63313C9.46457 8.76949 9.64951 8.8461 9.84236 8.8461Z'
        fill='white'
      />
      <path
        d='M25.3599 7.19006L19.6368 1.46699C19.2258 1.08514 18.6911 0.864067 18.1304 0.844238H5.77274C5.35132 0.848819 4.94871 1.01945 4.65234 1.31908C4.35596 1.61872 4.18974 2.02316 4.18977 2.44461V4.53205H2.10232C1.67788 4.53205 1.27082 4.70066 0.970691 5.00079C0.670563 5.30092 0.501953 5.70798 0.501953 6.13242V30.5555C0.501953 30.9799 0.670563 31.387 0.970691 31.6871C1.27082 31.9872 1.67788 32.1559 2.10232 32.1559H20.7153C21.1392 32.1549 21.5453 31.9859 21.8447 31.6859C22.1441 31.3859 22.3122 30.9793 22.3122 30.5555V28.468H24.3997C24.8241 28.468 25.2312 28.2994 25.5313 27.9993C25.8314 27.6992 26 27.2921 26 26.8677V8.6965C25.9755 8.13355 25.7481 7.59849 25.3599 7.19006ZM20.8371 30.5555C20.8371 30.5936 20.8222 30.6303 20.7955 30.6576C20.7689 30.6849 20.7326 30.7007 20.6945 30.7016H2.10232C2.06357 30.7016 2.0264 30.6862 1.999 30.6588C1.9716 30.6314 1.9562 30.5942 1.9562 30.5555V6.12894C1.9562 6.10975 1.95998 6.09075 1.96733 6.07302C1.97467 6.0553 1.98543 6.03919 1.999 6.02562C2.01257 6.01205 2.02868 6.00129 2.04641 5.99395C2.06413 5.9866 2.08314 5.98282 2.10232 5.98282H4.18977V26.8572C4.18977 27.0674 4.23116 27.2755 4.31159 27.4697C4.39201 27.6638 4.5099 27.8403 4.6585 27.9889C4.80711 28.1375 4.98354 28.2554 5.1777 28.3358C5.37187 28.4162 5.57997 28.4576 5.79014 28.4576H20.8441L20.8371 30.5555ZM24.5284 26.8711C24.5284 26.9099 24.513 26.9471 24.4856 26.9745C24.4582 27.0019 24.421 27.0173 24.3823 27.0173H5.77274C5.73399 27.0173 5.69682 27.0019 5.66942 26.9745C5.64202 26.9471 5.62662 26.9099 5.62662 26.8711V2.44461C5.62662 2.40586 5.64202 2.36869 5.66942 2.34129C5.69682 2.31388 5.73399 2.29849 5.77274 2.29849H18.1304V7.24224C18.1304 7.62794 18.2836 7.99783 18.5563 8.27056C18.8291 8.54328 19.199 8.6965 19.5846 8.6965H24.5284V26.8711Z'
        fill='white'
      />
    </svg>
  );
};

export default LogsComponent;
