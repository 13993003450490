import React from "react";
import { Wrapper } from "./Objective.style";
import { OkrContainer } from "../Okr/Okr.style";
import CommonHeaderComponent from "./CommonHeader.component";
import InnerTabComponent from "../Okr/Tabs/InnerTab.component";
import CheckinModelComponent from "../Okr/CheckinModel.component";
function PopUpObjectives(props) {
  const { objectiveData, setPopUp } = props;
  const [isCheckInModel, setIsCheckInModel] = React.useState(false);
  // const [checkInModelData,setCheckinModelData]=React.useState()
  // const DisplayCheckInModel=(type,id)=>{
  //     console.log(type,id);

  //     // console.log(criticalOkrData)
  //     setCheckinModelData(type==="critical"?criticalOkrData.find((item,i)=>item._id===id):aspirationalOkrData.filter((item,i)=>item._id===id));
  //     setIsCheckInModel(true);
  //   }
  return (
    <Wrapper modal>
      <OkrContainer maxWidth="1079px" modal>
        <CommonHeaderComponent
          setModal={setPopUp}
          isExpand={false}
          headerText={`Objective by Department ${objectiveData.name}`}
          placeholder="View by"
          info="OKR List..."
          filter={false}
          //   onClick={setModal}
          //   modal={modal}
        />
        <InnerTabComponent okrData={objectiveData.data} />
      </OkrContainer>
      {/* {
                // onClicked={DisplayCheckInModel}
        isCheckInModel&&<CheckinModelComponent Render={render} SetRender={setRender} setIsCheckInModel={setIsCheckInModel} okr={checkInModelData}/>
      } */}
    </Wrapper>
  );
}
PopUpObjectives.defaultProps = {
  objectiveData: {},
};
export default PopUpObjectives;
