import React, { useState } from "react";

import DropdownComponent from "../../Svgs/Dropdown.component";
import GoComponent from "../../Svgs/Go.component";
import ListDropdownComponent from "../../Svgs/ListDropdown.component";
import WarningComponent from "../../Svgs/Warning.component";
import { Link } from "react-router-dom";
import HoverList from "../Okr/Tabs/HoverList";
import {
  InnerTabContainer,
  Tab,
  PanelHeader,
  DropDownContainer,
  WarningContainer,
  Icon,
} from "../Okr/Tabs/Tab.style";

/**
 *
 * @param {JSX.Element} props.children Inner Tab dropdown
 * @param {object} props.okr Array of Okr data/feedback
 */

const getProgress = (status) => {
  if (status === "R") return "At Risk";
  else if (status === "S") return "Not Started";
  else if (status === "T") return "on Track";
  else if (status === "C") return "Completed";
  else return 0;
};

const InnerTab = ({
  editable,
  onEdit,
  children,
  type = "",
  okr,
  id = "",
  from = "",
  setCheckin = () => {},
  onGoClick = () => {},
  showType,
}) => {
  const [open, setOpen] = useState(false);

  const [startDate, startMonth, startYear] = [
    new Date(okr.timeframe ? okr.timeframe[0] : new Date())?.getDate(),
    new Date(okr.timeframe ? okr.timeframe[0] : new Date())?.getMonth() + 1,
    new Date(okr.timeframe ? okr.timeframe[0] : new Date())?.getFullYear(),
  ];
  const [endDate, endMonth, endYear] = [
    new Date(okr.timeframe ? okr.timeframe[1] : new Date())?.getDate(),
    new Date(okr.timeframe ? okr.timeframe[1] : new Date())?.getMonth() + 1,
    new Date(okr.timeframe ? okr.timeframe[1] : new Date())?.getFullYear(),
  ];

  const [hoverList, setHoverList] = useState(false);

  return (
    <Tab col>
      <Tab>
        <PanelHeader lg>
        <Link style={{
            wordBreak: "break-word",
            padding: "1rem 0",
            display: "flex",
            width: "95%",
            paddingRight: "5%",
            alignItems: "center", textDecoration: "none", color: "black", whiteSpace: "pre-wrap"
          }} to={`/okr/${id}`}>
            {new Date(okr.timeframe ? okr.timeframe[1] : Date.now()) < new Date() ? (
              <WarningContainer>
                <WarningComponent className='no-mr' />
              </WarningContainer>) : ""}

            {/* OKR */}
            {okr?.okr || okr?.title || `No data`}
          </Link>
        </PanelHeader>

        <PanelHeader sm>{okr?.owner || okr?.username || "No data"}</PanelHeader>

        {/* Time */}
        <PanelHeader>
          {okr?.time ||
            `${startDate}-${startMonth}-${startYear
              .toString()
              .slice(2, 4)}  to  ${endDate}-${endMonth}-${endYear
              .toString()
              .slice(2, 4)}` ||
            "No data"}
        </PanelHeader>
        <PanelHeader lg>{okr.collaborator}</PanelHeader>
        <PanelHeader sm>{okr.status}</PanelHeader>
        {/* {okr?.status ? <PanelHeader>{okr.status}</PanelHeader> : ''} */}

        {/* Icons */}
        {/*<Icon>
          <div
            onClick={() => {
              onGoClick(type, okr._id);
              setCheckin(true);
            }}
          >
            <GoComponent style={{ marginRight: "0.5rem" }} />
          </div>
          {editable && (
            <div
              style={{ position: "relative" }}
              onClick={() => setHoverList(!hoverList)}
            >
              <DropdownComponent style={{ marginRight: "5px" }} />
              <DropdownComponent style={{ marginRight: "5px" }} />
              <DropdownComponent />
              {editable && hoverList && (
                <HoverList okrId={okr._id} onEdit={onEdit} />
              )}
            </div>
          )}
        </Icon>*/}
      </Tab>
      {open ? children : ""}
    </Tab>
  );
};

/**
 * @param {Array} props.okrs array of okrs
 */
const InnerTabComponent = ({
  editable,
  onEdit,
  type = "",
  collaborators = [],
  showType = false,
  title = "OKR",
}) => {
  console.log("Logging OKR Data");
  return (
    <InnerTabContainer>
      {/* OKR HEADER  */}

      <Tab>
        <PanelHeader fontWeight="800" lg>
          {title}
        </PanelHeader>
        <PanelHeader fontWeight="800" sm>
          Owner
        </PanelHeader>

        <PanelHeader fontWeight="800">
          Time
        </PanelHeader>
        <PanelHeader fontWeight="800" lg>
          Collaborator
        </PanelHeader>
        <PanelHeader fontWeight="800" sm>
          Status
        </PanelHeader>
      </Tab>

      {/* OKR LIST */}
      {collaborators?.length &&
        collaborators.map((collab, id) => {
          return (
            <InnerTab
              editable={editable}
              onEdit={onEdit}
              type={type}
              showType={showType}
              okr={collab}
              id={collab._id}
              key={collab._id}
            ></InnerTab>
          );
        })}
    </InnerTabContainer>
  );
};

export default InnerTabComponent;