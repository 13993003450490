import React from 'react'

function CloseComponent(props) {
    return (
        <svg width="6" height="6" viewBox="0 0 6 6" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.39746 5.77734H-0.000976562L1.85059 2.84375L0.0888672 0.0195312H1.52637L2.55371 1.89062L3.6084 0.0195312H4.99902L3.2373 2.79688L5.1084 5.77734H3.64746L2.55371 3.81641L1.39746 5.77734Z" fill="white"/>
</svg>

    )
}

export default CloseComponent;
