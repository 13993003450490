import styled from 'styled-components';

export const MainNavbar = styled.div`
  min-height: 100%;
  width: 79px;
  min-height: inherit;
  min-width:79px;
  background-color: ${(props) => props.theme.blueDarkSecondary};
  z-index: 1000;

  @media (max-width: 768px) {
    position: absolute;
    height:100%;
    display: ${(props) => (props?.modal ? 'block' : 'none')};
  }
`;

export const MainNavbarChild=styled.div`
@media (max-width: 768px) {
  position: sticky;
  top:0;
  
}
`;
export const Icon = styled.div`
  text-align: center;
  padding: 1rem 0;
  margin: 1rem 0;
  background-color: ${(props) =>
    props?.active ? props.theme.blueDarkTertiary : ''};
`;
export const Text=styled.div`

color:#fff;


`;