import React from 'react';
import { department } from '../../../api/Api';
import ListDropdownComponent from '../../Svgs/ListDropdown.component';
import SearchComponent from '../../Svgs/Search.component';
import {
  DashboardInput,
  IconContainer,
  InputPlaceholder,
  InputVal,
  DashboardInputWrapper,
} from './DashboardHeader.style';

const DashboardInputComponent = ({
  placeholder,
  value = 'All',
  children,
  lg = false,
  width = null,
  dropdownIcon = true,
  search = false,
  height="",
  viewBy="",
  mr=false,
  marginLeft="",
  bdrcolor="",
  minHeight="",
  valid=false
  
}) => {
  
  return (
    <DashboardInputWrapper style={minHeight?{minHeight:minHeight}:{}} mr={mr} height={height||minHeight}>
      <DashboardInput bdrcolor={bdrcolor?bdrcolor:valid?"red":""}  mr={mr} viewBy={viewBy} lg={lg} width={width}>
        <InputPlaceholder>
          {search ? (
            <IconContainer>
              <SearchComponent className='mr-2' />
            </IconContainer>
          ) : (
            ''
          )}
           {`${placeholder?placeholder:""}`}<InputVal>{value}</InputVal>
        </InputPlaceholder>
        {dropdownIcon ? (
          <IconContainer>
            <ListDropdownComponent className="dropdown" style={marginLeft?{ marginLeft:marginLeft }:{marginLeft:"38px"}} />
          </IconContainer>
        ) : (
          ''
        )}
      </DashboardInput>
      {children}
    </DashboardInputWrapper>
  );
};

export default DashboardInputComponent;
