import React from 'react';

const OkrComponent = () => {
  return (
    <svg
      width='31'
      height='31'
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='nav'
    >
      <path
        d='M14.8238 20.7749C11.7388 20.7749 9.22656 18.2627 9.22656 15.1777C9.22656 12.0927 11.7388 9.58045 14.8238 9.58045C15.4614 9.57921 16.0945 9.6882 16.695 9.90262L16.0409 11.7412C15.6492 11.6019 15.2363 11.5315 14.8205 11.533C14.0997 11.533 13.395 11.7467 12.7957 12.1472C12.1963 12.5477 11.7291 13.1169 11.4533 13.7829C11.1774 14.4489 11.1052 15.1817 11.2459 15.8887C11.3865 16.5957 11.7336 17.2452 12.2433 17.7549C12.7531 18.2646 13.4025 18.6117 14.1095 18.7524C14.8165 18.893 15.5493 18.8208 16.2153 18.545C16.8813 18.2691 17.4505 17.802 17.851 17.2026C18.2515 16.6032 18.4653 15.8985 18.4653 15.1777C18.4652 14.7621 18.3947 14.3495 18.257 13.9574L20.0956 13.3033C20.3085 13.9042 20.4174 14.5369 20.4178 15.1744C20.421 18.2627 17.9088 20.7749 14.8238 20.7749Z'
        fill='white'
      />
      <path
        d='M24.3625 10.4004H19.6016V5.63954L25.2086 0L26.1197 3.88227L30.002 4.79345L24.3625 10.4004ZM21.5541 8.44792H23.5554L26.1262 5.89012L24.4926 5.50612L24.1086 3.87251L21.5508 6.44333V8.44792H21.5541Z'
        fill='white'
      />
      <path
        d='M19.8897 8.73122L14.1348 14.4862L15.5154 15.8668L21.2704 10.1119L19.8897 8.73122Z'
        fill='white'
      />
      <path
        d='M14.824 25.5814C13.3092 25.5801 11.813 25.2479 10.4399 24.6082C9.06675 23.9685 7.84991 23.0367 6.87436 21.8778C5.89881 20.719 5.18809 19.361 4.79188 17.899C4.39566 16.4369 4.3235 14.9059 4.58044 13.4131C4.83738 11.9202 5.41723 10.5015 6.27945 9.256C7.14168 8.01052 8.26548 6.96835 9.57233 6.20233C10.8792 5.4363 12.3375 4.9649 13.8455 4.82106C15.3535 4.67722 16.8747 4.86442 18.3028 5.36957L17.6487 7.20819C16.7408 6.88559 15.7842 6.72157 14.8208 6.72332C10.1607 6.72332 6.36958 10.5145 6.36958 15.1745C6.36958 19.8345 10.164 23.6289 14.824 23.6289C19.484 23.6289 23.2752 19.8378 23.2752 15.1777C23.2764 14.2143 23.1124 13.2578 22.7903 12.3498L24.6289 11.6957C25.1852 13.2654 25.3567 14.9457 25.1291 16.5954C24.9014 18.2451 24.2812 19.8161 23.3207 21.1765C22.3601 22.5369 21.0871 23.6469 19.6086 24.4134C18.1302 25.1799 16.4894 25.5805 14.824 25.5814Z'
        fill='white'
      />
      <path
        d='M14.8248 30.0169C12.6642 30.0148 10.5302 29.5409 8.57177 28.6284C6.61336 27.7158 4.87783 26.3867 3.48648 24.7337C2.09513 23.0808 1.08151 21.1439 0.516462 19.0585C-0.0485881 16.9732 -0.15144 14.7895 0.215094 12.6603C0.581628 10.531 1.40871 8.50748 2.63853 6.73107C3.86835 4.95466 5.47126 3.46823 7.33523 2.37566C9.1992 1.28308 11.2793 0.610722 13.4301 0.405557C15.5809 0.200392 17.7506 0.467374 19.7875 1.18784L19.1334 3.02647C17.7491 2.53941 16.2923 2.29073 14.8248 2.29102C7.72089 2.29102 1.93816 8.07049 1.93816 15.1777C1.93816 22.2849 7.71763 28.0643 14.8248 28.0643C21.932 28.0643 27.7115 22.2849 27.7115 15.1777C27.7139 13.7098 27.464 12.2524 26.9728 10.8691L28.8114 10.215C29.6071 12.4538 29.8534 14.851 29.5296 17.2048C29.2057 19.5587 28.3212 21.8003 26.9504 23.741C25.5796 25.6817 23.7627 27.2648 21.6526 28.3569C19.5424 29.4491 17.2008 30.0183 14.8248 30.0169Z'
        fill='white'
      />
    </svg>
  );
};

export default OkrComponent;
