import React,{useState,useEffect} from 'react';
import LogInImg from '../components/img/login.png';
import {useHistory} from 'react-router-dom';
import { useAuth } from '../context/Auth';
import { LoginComponent } from '../components/Login/Login.style';
import { ImgComponent } from '../components/Login/Login.style';
import { LogInForm } from '../components/Login/Login.style';
import LogInFormComponent from '../components/Login/LogInForm.component';


function LogIn() {

    const {signUp,signIn,uid,idToken,setUID,setIdToken}=useAuth();
    console.log(uid," ",idToken);
    const [loading,setLoading]=useState(true);
    const history=useHistory();
    const [formData,setFormData]=useState({email:"",password:""});
    const setData=(e)=>{
        let name =e.target.name;
        let val=e.target.value;
        setFormData({...formData,[name]:val});
    }
    const OnClick=()=>{
        if(!formData.email||!formData.password)
            return;
        else{
            signIn(formData.email,formData.password);

        }
    }
    useEffect(()=>{
        if(uid,idToken)
        {
            setUID("");
            setIdToken("")
        }
       
            setLoading(false);
        


    },[])
    

    return (
        <>
        
        <LoginComponent >
        <ImgComponent >
            <img src={LogInImg} style={{maxHeight:"100vh",width:"100%"}} alt="loginimg"/>
        </ImgComponent>
        <LogInForm>
            <LogInFormComponent formData={formData} setFormData={setFormData} OnChange={setData} OnClick={OnClick} />
            
        </LogInForm>

        </LoginComponent>
        </>
    )
}

export default LogIn;
