import styled, { css } from 'styled-components';
import { flexCenter, flexColumn, myFlex } from '../../utils/shared.style';
import { SharedSmallText } from '../Dashboard/Request/Request.style';

export const sharedStyle = css`
  ${myFlex};
  align-items: center;
`;

export const HeaderContainer = styled.header`
  height: 60px;
  box-shadow: 0 4px 10px 0 #d6e1ec;
  background-color: ${(props) => props.theme.backgroundColor};
  ${sharedStyle};
  justify-content: space-between;
  box-sizing:border-box;
  text{
    font-weight:600;
    right:200px;
  }
`
;

export const HeaderText = styled.span`
  font-size: 2rem;
  letter-spacing: 4px;
  text-align: left;
  margin-left: 1.6rem;
  color: ${(props) => props.theme.blackPrimary};

  @media (max-width: 400px) {
    font-size: 1.3rem;
    font-weight:800;
    letter-spacing: 0;
  }
`;

export const Average = styled.span`
  color: ${(props) => props.theme.orangePrimary};
  font-size: 1.3rem;
  margin-right: 0.2rem;
  font-weight: 500;
`;

export const MenuContainer = styled.div`
  ${sharedStyle};
  position: relative;
`;

export const Progress = styled.div`
  width: 36px;
  height: 36.9px;
  flex-grow: 0;
  margin: 0px 0 0 0.9px;
  padding: 12px 7px 10.9px 8px;
  border: solid 2px #f7931e;
  background-color: #f7931e;
`;

export const SharedPopup = css`
  position: absolute;
  right: 0;
  bottom: -18rem;
  width: 32rem;
  height: 0;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  ${flexColumn};
  padding: 10px;
  opacity: 0;
  transition: all 500ms ease-in-out;
`;
export const NotificationBody = styled.div`
  ${myFlex};
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const ProfileContainer = styled.div`

  ${SharedPopup};
  
  z-index:-100;
`;

export const NotificationContainer = styled.div`
  ${SharedPopup};
  ${flexColumn};
  z-index:-100;
  .cnt{
    color:red;
    font-weight:600;
    font-size:1.3rem;
  }
`

;

export const NotificationWrapper = styled.div`
  display: none;
`;

export const ProfileWrapper = styled.div`
  display: none;
  
`;

export const IconContainer = css`
  ${myFlex};
  ${flexCenter};
  position: relative;
  height: 60px;
  padding: 0 2rem;
  margin: 0 0, 5rem;
  transition: all 500ms ease-in-out;

  @media (max-width: 400px) {
    padding: 0 1rem;
  }

  &:hover {
    background: ${(props) => props.theme.blueDarkPrimary};
  }

  &:hover > svg path {
    fill: white;
  }
`;

export const SharedHover = css`
  width: 32rem;
  min-height: 19rem;
  opacity: 1;
  z-index: 10000;
  overflow-y: scroll;
`;

export const UserContainer = styled.div`
  ${IconContainer}

  &:hover ${ProfileContainer} {
    ${SharedHover}
  }
  &:hover ${ProfileWrapper} {
    ${flexColumn}
  }
`;

export const NotificationIconContainer = styled.div`
  ${IconContainer}

  &:hover ${NotificationContainer} {
    ${SharedHover};
    width: 35rem;
  }

  &:hover ${NotificationWrapper} {
    ${flexColumn}
  }
  @media (max-width: 400px) {
    position: static;
    &:hover ${NotificationContainer} {
      ${SharedHover};
      width: 90vw;
    }
  }
`;

export const ProfileHeader = styled.h1`
  ${SharedSmallText};
  font-weight: 500;
`;

export const ProfileDetails = styled.div`
  ${myFlex};
  align-items: center;
`;

export const Circle = styled.div`
  margin: 1rem 1rem 1rem 0;
  width: ${(props) => (props?.sm ? '3.8rem' : '6rem')};
  height: ${(props) => (props?.sm ? '3.8rem' : '6rem')};
  border-radius: 50%;
  ${myFlex};
  ${flexCenter};
  border: ${(props) => `1px solid ${props.theme.greyDark1}`};
`;

export const Meta = styled.p`
  font-size: 1.2rem;
  margin: 0.3rem 0 !important;
  color: #8c8c8c;
`;

export const Notification = styled.p`
  ${SharedSmallText};
  font-weight: 400;
  width: 50%;
  word-break: break-word;
`;
