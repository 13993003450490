import styled from 'styled-components';
import {
  boxShadow,
  flexCenter,
  flexColumn,
  myFlex,
} from '../../../utils/shared.style';

export const SettingContent = styled.div`
  ${myFlex};
  ${flexColumn};
  padding: 1rem;
  margin-bottom: 3rem;
  ${boxShadow}
`;

export const Header = styled.div`
  ${myFlex};
  justify-content:${({justifyContent})=>justifyContent?justifyContent:"space-between"};
  align-items: center;
  margin-top: 2rem;
  flex-wrap: wrap;
  ${({minHeight})=>minHeight?`min-height:${minHeight};margin:0px`:""}
`;

export const TableHeader = styled.div`
  width: 20%;
  word-break: break-word;
  font-size: 14px;
  font-weight: ${(props) => (props?.light ? 400 : 500)};
  ${myFlex};
  align-items: center;
`;

export const InnerTab = styled.div`
  border-left: 1px solid #dbdbdb;
  margin-left: 1rem;
  line-height: 2;
`;

export const SmallLine = styled.div`
  width: 2rem;
  height: 1px;
  background: #dbdbdb;
  margin-right: 0.5rem;
`;

export const TabContainer = styled.div`
  ${myFlex};
  justify-content: space-between;
  margin: 2rem 0 1rem 0;
`;

export const Tab = styled.div`
  width: ${(props) => (props?.sm ? '5%' : '14%')};
  word-break: break-word;
  font-weight: ${(props) => (props?.main ? 'bold' : 400)};
  font-size: 1.3rem;
`;

export const Modal = styled.section`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props?.modal ? 'block' : 'none')};
  z-index: 1000;
  overflow-y: scroll;
`;

export const ModalContainer = styled.div`
  width: 30%;
  ${boxShadow};
  margin: 25vh auto;
  ${flexColumn};
  padding: 1rem 1.5rem;
  height: fit-content;
  overflow-y: scroll;
  line-height: 2;

  @media (max-width: 768px) {
    width: 85%;
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: 0;
  top: -1rem;
`;

export const Or = styled.div`
  ${myFlex};
  align-items: center;
`;

export const Container = styled.div`
  border: 1px solid #dbddbd;
  padding: 5rem 0;
  line-height: 2;
  ${flexColumn};
  ${flexCenter};
  .upload{
    color:#045680;
    border:1px solid #045680;
    border-radius:4px;
    padding:3px 8px;
    cursor:pointer;
    font-size:1.1rem;
  }
`;
