import styled from 'styled-components';
import { sharedBox, sharedLine } from '../Objective/Objective.style';

export const OkrContainer = styled.section`
  ${sharedBox};
  flex-direction: column;
  // margin-top:-11px;
  margin-right:14px;
  ${({modal})=>modal&&"max-width:1079px;"}
  @media (max-width:768px)
  {
    margin-right:0px;
  }  
  `;

export const OkrHeader = styled.h1`
  font-size: 16px;
  color: ${(props) => props.theme.blueDarkSecondary};
  margin: 1rem;
`;
export const WrapperOkrContainer=styled.div`
height:calc( 100% - 255px );
width:100%;

`
export const Line = styled.div`
  ${sharedLine};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 7rem;
    height: 5px;
    background: ${(props) => props.theme.blueDarkSecondary};
  }
`;
export const MileStonesContainer=styled.div`
display:flex;
justify-content:space-around

`

export const MileTitle=styled.div`
flex:0.65;
font-weight:500;
font-size:1.3rem;
`
export const MileStatus=styled.div`
flex:0.35;
`

export const LeftCheckinWrapper=styled.div`

width:380px;
border-right:1px solid #cccc;
padding-right:10px;
margin-right:10px;
margin-top:10px;
.group{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;

}
.groupChild{
  margin-bottom:16px;
  width:48%;
  label{
    font-size:1.1rem;
   
    
  }
}



`
export const GraphContainer=styled.div`

`

export const KeyResults=styled.div`
display:flex;
.text{
  margin-left:10px;
  font-size:1.2rem;
  font-weight:400;
}

`