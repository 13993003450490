import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { useAuth } from "../../../context/Auth";

import { collabortion } from "../../../api/Api";

import NewRequestComponent from "./NewRequest.component";
import RequestHeaderComponent from "./RequestHeader.component";

const NewOkrComponent = (props) => {
  const history = useHistory();
  const { uid, idToken } = useAuth();
  const { departmentOkr } = props;
  const allCollab = props.alignments || [];

  const [collabs, setCollabs] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [collabId, setCollabId] = useState("");
  const [data, setData] = useState([]);

  const [progressBarData, setProgressBarData] = useState({
    request: 0,
    approved: 0,
    rejected: 0,
  });

  const handleOnClick = (id, status) => {
    props?.setShowApprove(true);
    props?.setStatus(status);

    let tl = data.find((item) => item.collab._id === id);

    props.setTitle(tl.okr.title);
    props.setOwner(tl.okr.username);
    props.setHeader("Alignment Request");
    // setOwner(tl.okr.username);
    setCollabId(id);
  };

  //Approve Alignment
  useEffect(() => {
    if (props.approve) {
      axios
        .post(
          collabortion.approveAlignment,
          { requestID: collabId, status: props?.status },
          {
            headers: {
              Authorization: `${idToken}`,
            },
          }
        )
        .then((res) => {
          setCollabs(
            collabs.map((item) =>
              item._id === collabId ? { ...item, status: props?.status } : item
            )
          );
          props.setApprove(false);
        })
        .catch((err) => console.log(err));
    }
  }, [props.approve]);

  //map okr with alignment item
  useEffect(() => {
    const temp = [];
    console.log("71", collabs.length);
    console.log("72", departmentOkr);
    collabs?.forEach((item, index) => {
      console.log(index, item);
      if (!item.to) {
        console.log(`${!item.to} not found!`);
        return;
      }
      departmentOkr.forEach((okr) => {
        if (okr._id === `${item.to._id}`) temp.push({ okr: okr, collab: item });
      });
    });
    console.log("77", temp.length);
    setData([...temp]);
  }, [collabs]);

  useEffect(() => {
    const temp = [...new Map(allCollab.map((v) => [v._id, v])).values()];
    const temp2 = temp.filter((item, index) => item.uuid === uid);
    setCollabs(temp2);
  }, []);

  useEffect(() => {
    if (data.length) {
      let temp = { request: 0, approved: 0, rejected: 0 };
      if (data.length) {
        data.forEach((item) => {
          console.log(`93 ${item.collab}`);
          item.collab.status === "NA"
            ? (temp.request += 1)
            : item.collab.status === "A"
            ? (temp.approved += 1)
            : (temp.rejected += 1);
        });
        setProgressBarData(() => {
          return { ...temp };
        });
      }
    }
  }, [data]);

  useEffect(() => {
    let temp = data;
    if (sortBy === "latest") {
      console.log(data);
      temp.sort((a, b) => {
        let name1, name2;

        name1 = new Date(a.timestamp);
        name2 = new Date(b.timestamp);

        if (name1 > name2) return -1;
        if (name1 < name2) return 1;

        return 0;
      });

      console.log(temp);
      setData([...temp]);
    }
  }, [sortBy]);

  const showDetails = (id) => {
    history.push(`/okr/${id}`);
  };

  const getData = (cnt) => {
    const { approved, rejected, request } = progressBarData;
    let total = approved + rejected + request;
    return (cnt / total) * 100;
  };

  return (
    <>
      {/*<>
      <RequestHeaderComponent />
      {/* {
        data.map((item)=>{
          return(
            <NewRequestComponent handleOnClick={handleOnClick} _id={item.checkin._id} IconName="ObjectiveComponent" title={item.okr.title} name={item.okr.name} owner={item.okr.owners[0]} isRequestNew={item.checkin.status==="NA"} approved={item.checkin.status==="A"}/>
          )
        })
      } 
      <NewRequestComponent IconName="ObjectiveComponent" isRequestNew />
      <NewRequestComponent IconName="ObjectiveComponent" approved />
      <NewRequestComponent IconName="ObjectiveComponent" approved={false} />
    </>*/}
      <RequestHeaderComponent
        index={`${progressBarData.approved}/${
          progressBarData.approved +
          progressBarData.rejected +
          progressBarData.request
        }`}
        checkin
        request={getData(progressBarData?.request)}
        approved={getData(progressBarData?.approved)}
        rejected={getData(progressBarData?.rejected)}
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
      {data?.map((item) => {
        console.log(item);
        return (
          <NewRequestComponent
            handleOnClick={handleOnClick}
            okrID={item.okr._id}
            showDetails={showDetails}
            _id={item.collab._id}
            title={item.okr.title}
            name={item.okr.name}
            owner={item.collab.userdetails[0].name}
            isRequestNew={item.collab.status === "NA"}
            approved={item.collab.status === "A"}
            IconName="CollaborationComponent"
          />
        );
      })}
    </>
  );
};

export default NewOkrComponent;
