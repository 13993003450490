import React from "react";
import {
  DashboardHeader,
  DashboardText,
} from "../components/Dashboard/DashboardHeader/DashboardHeader.style";
import HeaderComponent from "../components/Header/Header.component";
import MainContainerComponent from "../components/Layouts/MainContainer/MainContainer.component";
import LogContainer from "../components/Logs/LogsContainer/LogsContainer.component";

const Logs = () => {

  return (
    <main>
      <HeaderComponent />
      <MainContainerComponent>
        <DashboardHeader>
          <DashboardText>Logs</DashboardText>
        </DashboardHeader>
        <LogContainer/>
      </MainContainerComponent>
    </main>
  );
};

export default Logs;
