import React from 'react';
import { dummyStatusGraph } from '../Okr/Tabs/helpers';
import CommonHeaderComponent from './CommonHeader.component';
import useFullScreen from '../../../hooks/useFullScreen';
import { useAuth } from '../../../context/Auth';
import {
  BarGraphContainer,
  BarGraphWrapper,
  Department,
  DepartmentText,
  Line,
  ObjectiveContainer,
  Rect,
  Status,
  StatusProgress,
  Wrapper,
  ObjectiveLine
} from './Objective.style';
import ObjectiveModal from './ObjectiveModal.component';
import StatusComponent from './Status.component';

const improveText = (text, len) => {
  if (text.length <= len) return text;
  const updatedText = text.slice(0, len);
  return (updatedText.trim()) + "..";
}
/**
 * @param {array} props.graphs array of graph width status percentage
 */
const calcPercentage = (num, total) => {
  return (parseFloat(num / total) * 100);
}

function max(a, b) {
  return a < b ? b : a;
}

const getGraphHeight = (data) => {
  let final = [];
  let parent = []

  let mx = 0;

  for (let j = 0; j < data[0].length; j++) {
    let temp = [];
    let [a, b, c, d] = [data[0][j], data[1][j], data[2][j], data[3][j]];
    let total = a + b + c + d;
    mx = max(total, mx);
    parent.push(total);

    temp = [calcPercentage(a, total), calcPercentage(b, total), calcPercentage(c, total), calcPercentage(d, total)];
    final = [...final, temp];
  }

  return { data: final, mx, parent: parent };
}

export const BarGraph = ({ tempDepartmentName = [], handleOnClick = () => { }, notStarted = [], onTrack = [], atRisk = [], completed = [], graphs, style = "" }) => {

  let temp = [[...atRisk], [...completed], [...notStarted], [...onTrack]];
  let { data, mx } = getGraphHeight(temp);
  const height = (number) => {
    return ((number / mx) * 100)
  }

  return (
    <BarGraphContainer style={{ display: "flex", flexGrow: "100", }} space={style ? style.style : ""}>
      {/* {graphs?.length 
        ? graphs.map((graph, idx) => (
            <BarGraphWrapper key={idx}>
              <Rect height={graph.red} bg='#e95252' className='firstBar' />
              <Rect height={graph.blue} bg='#00b8e0' />
              <Rect height={graph.grey} bg='#b7b7b7' />
              <Rect height={graph.green} bg='#099e60' className='lastBar' />
            </BarGraphWrapper>
          ))
        : ''} */}

      {
        data?.length
          ? data.map((item, idx) => {
            return (
              <div className="graphs" style={{ display: "flex", width: "55px", height: "100%", marginRight: "10px", flexDirection: "column", alignItems: "center" }}>
                <BarGraphWrapper style={{ height: '100%'/*marginLeft: `${idx !== 0 ? "22px" : "0px"}`*/ }} key={idx}>
                  <Rect style={{ cursor: "pointer" }} onClick={() => handleOnClick(tempDepartmentName[idx].deptId, "R", tempDepartmentName[idx].name)} height={`${item[0]}%`} bg='#e95252' className='firstBar' />
                  <Rect style={{ cursor: "pointer" }} onClick={() => handleOnClick(tempDepartmentName[idx].deptId, "C", tempDepartmentName[idx].name)} height={`${item[1]}%`} bg='#00b8e0' />
                  <Rect style={{ cursor: "pointer" }} onClick={() => handleOnClick(tempDepartmentName[idx].deptId, "S", tempDepartmentName[idx].name)} height={`${item[2]}%`} bg='#b7b7b7' />
                  <Rect style={{ cursor: "pointer" }} onClick={() => handleOnClick(tempDepartmentName[idx].deptId, "T", tempDepartmentName[idx].name)} height={`${item[3]}%`} bg='#099e60' className='lastBar' />
                </BarGraphWrapper>
                <DepartmentText style={{marginTop: "5px", cursor:"pointer", whiteSpace:"nowrap" /*marginLeft: `${idx !== 0 ? "22px" : "0px"}`*/ }}>{improveText(tempDepartmentName[idx].name === "Company"?"CEO":tempDepartmentName[idx].name, 7)}</DepartmentText>
              </div>)
          }) : ""
      }
      <ObjectiveLine></ObjectiveLine>
    </BarGraphContainer>
  );
};

const ObjectiveComponent = (props) => {
  const { departmentChartData, overAllData, handleOnClick } = props;

  let tempDepartmentName = departmentChartData?.labels;
  let notStarted = departmentChartData?.track[0];
  let onTrack = departmentChartData?.track[1];
  let atRisk = departmentChartData?.track[2];
  let completed = departmentChartData?.track[3];

  const [modal, setModal] = useFullScreen();
  let total = overAllData[0] + overAllData[1] + overAllData[2] + overAllData[3];
  let ft = -1, st = 0;
  for (let i = 0; i < 4; i++) {
    if (ft === -1 && overAllData[i]) {
      ft = i;
    }
    if (overAllData[i])
      st = i;
  }

  return (
    <>
      {modal && (
        <ObjectiveModal
          modal={modal}
          onClick={setModal}
          Department={tempDepartmentName}
          notStarted={notStarted}
          onTrack={onTrack}
          atRisk={atRisk}
          completed={completed}
          overAllData={overAllData}
          color={[`${calcPercentage(overAllData[1], total)}%`, `${calcPercentage(overAllData[0], total)}%`,
          `${calcPercentage(overAllData[3], total)}%`,
          `${calcPercentage(overAllData[2], total)}%`,
          ]}
        />
      )}
      <Wrapper flex="0.5">
        <ObjectiveContainer>
          <CommonHeaderComponent
            placeholder='Show'
            headerText='Objective Status'
            onClick={setModal}
            filter={false}
          />
          {/* Status Indicator  */}
          <StatusComponent />

          {/* Status Progress width-percentage */}
          <StatusProgress>
            <Status width={`${calcPercentage(overAllData[1], total)}%`} bg='#099e60' className={`${ft === 0 ? "first" : ""} ${st === 0 ? "last" : ""} `} />
            <Status width={`${calcPercentage(overAllData[0], total)}%`} bg='#b7b7b7' className={`${ft === 1 ? "first" : ""} ${st === 1 ? "last" : ""}`} />
            <Status width={`${calcPercentage(overAllData[3], total)}%`} bg='#00b8e0' className={`${ft === 2 ? "first" : ""} ${st === 2 ? "last" : ""}`} />
            <Status width={`${calcPercentage(overAllData[2], total)}%`} bg='#e95252' className={`${ft === 3 ? "first" : ""} ${st === 3 ? "last" : ""}`} />
          </StatusProgress>
          <Line />

          {/* Graph width of color percentage or pixel recommended percentage for accuracy */}

          <BarGraph handleOnClick={handleOnClick} tempDepartmentName={tempDepartmentName} notStarted={notStarted} onTrack={onTrack} atRisk={atRisk} completed={completed} graphs={dummyStatusGraph} />

          {/* Seperator Line */}

          <Department >
            {/* {
              tempDepartmentName&&tempDepartmentName.map((name)=><DepartmentText>{name}</DepartmentText>)
                
            } */}
            {/* <DepartmentText>Dept1</DepartmentText>
            <DepartmentText>Dept2</DepartmentText>
            <DepartmentText>Dept3</DepartmentText>
            <DepartmentText>Dept4</DepartmentText>
            <DepartmentText>Dept5</DepartmentText> */}
          </Department>
        </ObjectiveContainer>
      </Wrapper>
    </>
  );
};

export default ObjectiveComponent;
