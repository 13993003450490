import React from 'react';
import { Wrapper } from '../Objective/Objective.style';
import {ApproveModalContainer,ApproveButtonWrapper,ApproveModalHeader,ApproveModalContent} from './Request.style'
import { Text } from '../../Objective/ChartView/ChartView.style';
import InputComponent from '../../Input/Input.component';
import { AddCommentContainer,TextArea } from '../../MyOkr/MyOkrTab/MyOkrTab.style';
// import { Line } from '../Okr/Okr.style';
import { Line } from '../Objective/Objective.style';
import { Browse,Button } from '../../MyOkr/NewObjective/NewObjective.style';

function ApproveModalComponent(props) {
    const {title,name,owner,buttonText,setModal,bgcolor,sendApproval}=props;
    // console.log(bgcolor);
    return (
        <Wrapper modal={true}>
            <ApproveModalContainer>
                <ApproveModalHeader>
                <div className='firstDiv'>
            <Text style={{color:"black"}} bold>
            {title}
            </Text>
            <Text onClick={()=>setModal(false)} style={{color:"black",cursor:"pointer"}} bold>
            ✖
            </Text>
            </div>
                </ApproveModalHeader>
                <ApproveModalContent>
                    <Text style={{color:"black"}} bold>
                    Title
                    </Text>
                    <InputComponent style={{width:"100%",boxSizing:"border-box",marginBottom:"10px"}} value={name} />
                </ApproveModalContent>
                <ApproveModalContent>
                    <Text style={{color:"black"}} bold>
                        Owner
                    </Text>
                    <InputComponent  style={{boxSizing:"border-box",marginBottom:"10px"}} value={owner}/>
                </ApproveModalContent>
                <ApproveModalContent style={{}}>
                    <Text style={{color:"black"}} bold>
                        Comments
                    </Text>
                    {/* <AddCommentContainer> */}
                    <TextArea style={{width:"90%"}} placeholder="Add a comment"  rows='6' cols='5' />
                    {/* </AddCommentContainer> */}
                </ApproveModalContent>
                <Line/>
                <ApproveButtonWrapper>
                <Browse>
        <Button onClick={()=>{setModal(false)}}>Cancel</Button>
        <Button onClick={()=>{sendApproval();setModal(false)}}  bg={bgcolor} >{buttonText}</Button>
      </Browse>
                </ApproveButtonWrapper>



            </ApproveModalContainer>
            
        </Wrapper>
    )
}

export default ApproveModalComponent
