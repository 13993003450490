import React, { useState, useEffect } from 'react';
import { ButtonContainer, Wrapper } from '../Objective/Objective.style';
import { useHistory } from 'react-router-dom';
import { CheckInContent } from './Tabs/Tab.style';
// import { CheckInHeader } from '../../Objective/CheckIn/CheckIn.style';
import CheckInComponentSvg from '../../Svgs/CheckIn.component';
import { CheckInHeader } from './Tabs/Tab.style';
import { Line } from '../Objective/Objective.style';
import { Button } from '../../MyOkr/NewObjective/NewObjective.style';
import { CheckInBox, CheckInBody, CommentCheckInBox, MileStonesText, Slider, ShowProgress } from './Tabs/Tab.style';
// import { CommentContainer } from '../../MyOkr/MyOkrTab/MyOkrTab.style';
import { AddCommentContainer, TextArea } from '../../MyOkr/MyOkrTab/MyOkrTab.style';
import InputComponent from '../../Input/Input.component';
import DashboardInputComponent from '../DashboardHeader/DashboardInput.component';
import FilterComponent from '../DashboardHeader/Filter.component';
// import TabComponent from './Tabs/Tab.component';
import TabComponent from '../../MyOkr/NewObjective/Tab.component';
import axios from 'axios';
import { useAuth } from '../../../context/Auth';
import { checkIn } from '../../../api/Api';
import MileStonesComponent from './MileStones.component';
import LeftCheckinContainer from './LeftCheckinContainer';


function CheckinModelComponent({ okr, clickedKeyResult, setIsCheckInModel, Render, SetRender = () => { } }) {
    // console.log(okr);

    const { idToken } = useAuth();
    const history = useHistory();
    const [keyResult, setKeyResult] = useState({
        key: clickedKeyResult || "",
        weight: 0,
    });
    const [progress, setProgress] = useState({});
    const [expand, setExpand] = useState(false);
    const [totalWeight, setTotalWeight] = useState(0);
    const [krsWeight, setKrsWeight] = useState(0);
    const [startValue, setStartValue] = useState(0);
    const [mileStone, setMileStone] = useState({});

    const handleCheckIn = () => {

        let temp = 0;
        let krs = {};
        for (let key in progress) {

            let ml = {};

            for (let i = 0; i < okr?.keyresults[temp]?.milestones.length; i++) {
                console.log(okr?.keyresults[i]?.milestones);
                ml = { ...ml, [i]: mileStone[okr?.keyresults[temp]?.milestones[i].title] || okr?.keyresults[temp]?.milestones[i].status };
            }
            if (progress.hasOwnProperty(key)) {

                krs = {
                    ...krs, [temp]: {
                        progress: parseInt(progress[key]), milestones: ml
                    }
                }
            }
            temp += 1;
        }
        // okr?.keyresults.find((item)=>item.title===key).milestones
        let id = okr._id;
        let obj = {
            [id]: {
                progress: objectiveProgress, krs: krs
            }
        }
        const payload = { obj: obj };
        console.log(payload);
        axios.post(checkIn, payload, {
            headers: {
                Authorization: `${idToken}`,
            },
        }).then(res => { SetRender(!Render); setIsCheckInModel(false) }).catch(err => console.log(err));


    }
    const [mileStones, setMileStones] = useState([]);

    const [render, setRender] = useState(false);
    const [objectiveProgress, setObjectiveProgress] = useState();

    const handleProgressBar = (e) => {
        let { name, value } = e.target;
        let temp = progress[name];
        temp[0] = value;
        setProgress({ ...progress, [name]: [...temp], });
    }

    useEffect(() => {
        let { keyresults } = okr;
        let progress = {};
        let totalWeight = 0;
        for (let i = 0; i < keyresults?.length; i++) {
            progress = { ...progress, [keyresults[i].title]: [keyresults[i].progress, keyresults[i].weight, keyresults[i].targetvalue] }
            totalWeight += keyresults[i].weight;
        }
        setProgress({ ...progress });
        setRender(!render);
        setTotalWeight(totalWeight);
    }, []);

    const getStatus = (val) => {
        const data = {
            "S": "not Started",
            "T": "on Track",
            "C": "Completed",
            "R": "at Risk"
        }
        return data[val];
    }
    useEffect(() => {
        if (render) {
            let total = 0;
            let totalweigtt = 0;
            let num = 0;
            for (var key in progress) {
                if (progress.hasOwnProperty(key)) {
                    total += parseInt((progress[key][1] * parseInt(progress[key][0])) / totalWeight);
                    num += 1;
                }
            }
            setObjectiveProgress(total);
        }
    }, [progress, render])

    const showPercentarge = () => {
        let k = keyResult.key;
        return Math.round(progress[k][2] * (progress[k][0] / 100))
    }

    useEffect(() => {
        if (keyResult.key) {
            let data = okr?.keyresults?.find((item) => item.title === keyResult.key);
            console.log(data);
            setKrsWeight(data.weight);
            setMileStones(data.milestones);
            setStartValue(data.startvalue);
        }
    }, [keyResult])

    return (
        <Wrapper modal={true}>
            <CheckInContent>


                <CheckInHeader>
                    <div className="firstChild">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CheckInComponentSvg />
                            <span style={{ marginLeft: "5px" }}>New check in</span>
                        </div>
                        <div style={{ cursor: "pointer" }} onClick={() => setIsCheckInModel(false)}>
                            X
                        </div>
                    </div>
                    <div className="secondChild">
                        <div>
                            Objective
                            <div>
                                {okr?.title}
                            </div>
                        </div>
                        <span onClick={() => setExpand(!expand)} style={{ color: 'blue', fontWeight: "500", cursor: "pointer" }}>
                            {!expand ? "view Details" : "close Details"}
                        </span>


                    </div>

                    <Line style={{ marginTop: "10px" }} />
                </CheckInHeader>


                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    {expand && <LeftCheckinContainer data={okr} />}

                    <div style={{ maxWidth: "380px" }}>
                        {/* Static blue box temproray disabled
                   <CheckInBox>
                       <div>
                                Q2 2022 has ended. Do you want to close or postpone this objective?
                       </div>
                       <ButtonContainer style={{display:"flex" ,justifyContent:"flex-end"}}>
                            <Button main style={{border:"1px solid #fff"}}>
                                Dismiss
                            </Button>
                            <Button >
                                Close or Postpone
                            </Button>
                       </ButtonContainer>
                   </CheckInBox>
                */}
                        <CheckInBody>
                            <div className="firstChild">
                                <div>
                                    <label>Progress</label>
                                    <InputComponent value={objectiveProgress} date={false} />
                                    <div style={{ color: "grey", marginTop: "3px" }}>Target 100% | Expected 40%</div>
                                </div>
                                <div>
                                    <label>Objective Status</label>
                                    <InputComponent value={getStatus(okr?.status)} date={false} />
                                </div>
                            </div>
                            <p style={{ fontWeight: "bold", fontSize: "1rem" }}>Key Result</p>

                            <DashboardInputComponent mr value={keyResult.key ? keyResult.key : "Select Key Result"}>
                                <FilterComponent
                                    date={false}
                                    name="key"
                                    formData={keyResult}
                                    setFormData={setKeyResult}
                                    options={okr?.keyresults?.map((item) => item.title)}
                                />
                            </DashboardInputComponent>
                            <Line style={{ marginTop: ".8rem", marginBottom: ".8rem" }} />

                            <TabComponent header="Progress" browse={false}>
                                {
                                    keyResult.key && (
                                        <Slider>
                                            <ShowProgress style={{ left: "0",marginTop:"3px" }}>
                                                {startValue}
                                            </ShowProgress>
                                            <div>
                                                <InputComponent style={{ padding: "0px", width: "100%", color: "#fff", height: "5px" }} name={keyResult.key} value={progress[keyResult.key][0]} type="range" min="0" max="100" onChange={(e) => { handleProgressBar(e) }} />
                                            </div>
                                            <ShowProgress style={{marginTop:"3px"}}>
                                                {showPercentarge()}
                                            </ShowProgress>
                                        </Slider>
                                    )
                                }
                            </TabComponent>
                            <div style={{ marginTop: "10px", marginBottom: "20px" }} className="secondChild">

                                <TabComponent header="Milestones" browse={false}>
                                    <div style={{ display: "flex", fontSize: "1.2rem", justifyContent: "space-between" }}>
                                        <div style={{ fontWeight: "600", fontSize: "1.4rem" }}>
                                            MileStone Title
                                        </div>
                                        <div style={{ fontSize: "1.4wrem", fontWeight: "600" }}>
                                            Status
                                        </div>

                                    </div>
                                    {mileStones.map((item, id) => {
                                        return (

                                            <MileStonesComponent mileStone={mileStone} setMileStone={setMileStone} data={item} id={id} key={id} />
                                        )

                                    })}
                                </TabComponent>



                            </div>

                        </CheckInBody>
                        <CommentCheckInBox>
                            <div style={{ marginBottom: "10px", fontWeight: "800", fontSize: "1.1rem" }}>Include a Note</div>
                            <AddCommentContainer style={{ maxWidth: "95%" }}>
                                <TextArea placeholder="type @ to mention people" style={{ maxWidth: "95%" }} rows='6' />

                            </AddCommentContainer>
                            <Line style={{ marginTop: "5px", marginBottom: "5px" }} />
                            <ButtonContainer style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button onClick={() => setIsCheckInModel(false)} >
                                    Cancel
                                </Button>
                                <Button onClick={() => { handleCheckIn() }} main>
                                    CheckIn
                                </Button>
                            </ButtonContainer>


                        </CommentCheckInBox>
                    </div>
                </div>

            </CheckInContent>

        </Wrapper>
    )
}

export default CheckinModelComponent;