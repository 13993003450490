import styled from 'styled-components';

export const LoginComponent=styled.div`
display:flex;


`;

export const ImgComponent=styled.div`
flex:5.7;
position:relative;
img{
  position:sticky;
  top:0;
  bottom:0;

}
`;
export const LogInForm=styled.div`
flex:4.3;
background-color:rgba(4, 86, 128, 0.8);
padding:100px 30px;


`;

export const LogInFormContainer=styled.div`
    color:#fff;
    margin-top:15px;
  .btn{
    display:block;
width:73%;
height:35px;
margin-top:10px;
opacity:0.85;
color: #045680;
font-weight:700;
letter-spacing:0.3px;
cursor:pointer;
  }
`;

export const FormLabel=styled.div
`
color:#fff;
font-weight:200;

`;
export const InputField=styled.input`

display:block;
width:70%;
outline:none;
height:30px;
background-color:transparent;

border:1px solid #fff;

  margin: 5px 0;
  padding: .3rem .7rem;
  border-radius:5px;
  
  ::-webkit-input-placeholder {
    color:#fff;
    font-weight:normal;
  }
  &:focus{
    background-color:#fff;
    opactity:0.8;
  }
  // background-color:transparent !important;
`;

export const LoginButton=styled.button`
display:block;
width:73%;
height:35px;
margin-top:10px;
opacity:0.85;
color: #045680;
font-weight:700;
letter-spacing:0.3px;
cursor:pointer;

`
export const RegisterCompany=styled.div`

`