import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import "firebase/auth";

const app = initializeApp({
  apiKey: "AIzaSyAuJkafkzjHqZzEkG3RmvBa1nfwoa-fGJs",
  authDomain: "fir-go-auth.firebaseapp.com",
  projectId: "fir-go-auth",
  storageBucket: "fir-go-auth.appspot.com",
  messagingSenderId: "682150439458",
  appId: "1:682150439458:web:c99fdd98eec137e2e0787b",
});
export const auth = getAuth(app);
export default app;
