import React,{useState,useEffect} from 'react';
import {MileStonesContainer,MileTitle,MileStatus} from './Okr.style';
import DropDown from './Sidebar/DropDown';
// import DashboardInputComponent from "../DashboardHeader/DashboardInputComponent";
// import FilterComponent from "../DashboardHeader/Filter.component";
import DashboardInputComponent from '../DashboardHeader/DashboardInput.component';
import FilterComponent from '../DashboardHeader/Filter.component';


function MileStonesComponent(props) {
    const {data,id,mileStone,setMileStone}=props;
    console.log(data);
    const [formData,setFormData]=useState({})
    let name="status"+id;
    console.log(formData);
    const [status,setStatus]=useState("")
    console.log(props);
    useEffect(()=>{
        setStatus(getText(data.status))
        setMileStone({...mileStone,[data?.title]:data.status})

    },[])
    const getStatus=(st)=>{
        if(st==="Not Started")
        {
            return "S"
        }
        else if(st==="on Track")
        {
            return "T"
        }
        else if(st==="At Risk")
        {
            return "R"
        }
        else {
            return "C"
        }
    }   
    useEffect(()=>{
        if(formData[data.title])
        {
            setMileStone({...mileStone,[data.title]:getStatus(formData[data.title])})
        }

    },[formData])

    const getText=(status)=>{
        if(status==="S")
        return "Not Started";
        else if(status==="T")
        return "On Track";
        else if(status==="R")
        return "At Risk";
        else 
        return "Completed"
    }
    const getColor=(st)=>{
        if(st==="Not Started")
        {
            return "#b7b7b7"
        }
        else if(st==="At Risk")
        {
            return "#e95252"
        }
        else if(st==="Completed")
        {
            return "#00b8e0"
        }
        else
        return "#099e60"

    }
    console.log(mileStone);
    return (
        <MileStonesContainer style={{marginBottom:"10px"}}>
            <MileTitle>
            {data?.title}
            </MileTitle>
            <MileStatus style={{marginRight:"-10px"}}>
            <DashboardInputComponent marginLeft="3px" bdrcolor={getColor(formData[data.title]||status)} value={formData[data.title]||status} >
            <FilterComponent
              
              formData={formData}
              setFormData={setFormData}
              options={["Not Started","At Risk","Completed","on Track"]}
              name={data.title}
              id={[`status${id}Not Started`,`status${id}At Risk`,`status${id}Completed`,`status${id}on Track`]}
              special
             
              
            />
          </DashboardInputComponent>
            </MileStatus>
        </MileStonesContainer>
    )
}

export default MileStonesComponent
