import React from 'react';
import NavbarComponent from '../Navbar/Navbar.component';
import { AppContainer, MainContainer } from './MainContainer.style';

const MainContainerComponent = ({ children,page }) => {
  return (
    <MainContainer page={page}>
      <NavbarComponent page={page} />
      <AppContainer>{children}</AppContainer>
    </MainContainer>
  );
};

export default MainContainerComponent;
