import { useState } from 'react';

const useFullScreen = (isModal = false) => {
  const [modal, setState] = useState(isModal);

  const setModal = () => {
    setState((state) => !state);
  };
  

  return [modal, setModal];
};

export default useFullScreen;
