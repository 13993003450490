import React from 'react';

const MenuComponent = (props) => {
  return (
    <svg
      className='nav'
      width='23'
      height='17'
      viewBox='0 0 23 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={()=>{props.setShowText(!props.showText)}}

    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.4375 0H21.5625C21.9437 0 22.3094 0.149256 22.579 0.414932C22.8486 0.680609 23 1.04094 23 1.41667C23 1.79239 22.8486 2.15272 22.579 2.4184C22.3094 2.68408 21.9437 2.83333 21.5625 2.83333H1.4375C1.05625 2.83333 0.690618 2.68408 0.421034 2.4184C0.151451 2.15272 0 1.79239 0 1.41667C0 1.04094 0.151451 0.680609 0.421034 0.414932C0.690618 0.149256 1.05625 0 1.4375 0ZM1.4375 7.08333H21.5625C21.9437 7.08333 22.3094 7.23259 22.579 7.49827C22.8486 7.76394 23 8.12428 23 8.5C23 8.87572 22.8486 9.23606 22.579 9.50173C22.3094 9.76741 21.9437 9.91667 21.5625 9.91667H1.4375C1.05625 9.91667 0.690618 9.76741 0.421034 9.50173C0.151451 9.23606 0 8.87572 0 8.5C0 8.12428 0.151451 7.76394 0.421034 7.49827C0.690618 7.23259 1.05625 7.08333 1.4375 7.08333ZM1.4375 14.1667H21.5625C21.9437 14.1667 22.3094 14.3159 22.579 14.5816C22.8486 14.8473 23 15.2076 23 15.5833C23 15.9591 22.8486 16.3194 22.579 16.5851C22.3094 16.8507 21.9437 17 21.5625 17H1.4375C1.05625 17 0.690618 16.8507 0.421034 16.5851C0.151451 16.3194 0 15.9591 0 15.5833C0 15.2076 0.151451 14.8473 0.421034 14.5816C0.690618 14.3159 1.05625 14.1667 1.4375 14.1667Z'
        fill='white'
      />
    </svg>
  );
};

export default MenuComponent;
