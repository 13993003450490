import { useState } from 'react';
import {
  TabHeader,
  TabHeaderContainer,
} from '../components/MyOkr/MyOkrTab/MyOkrTab.style';
import { Header } from '../components/Settings/SettingsContent/SettingContent.style';

const useTab = (active = 0, tabs, tabContent, sm = false, TabChild,allDepartment=[],allUsers=[],filterDept=()=>{},searchUser=()=>{},filterByMembers=()=>{},filterByStatus=()=>{},sortByField=()=>{}) => {
  const [activeTab, setActiveTab] = useState(active);

  const showTabs = (criticalOkrData=[],aspirationalOkrData=[],allUser=[],allDepartments=[],filterByDept=()=>{},filterByUser=()=>{},deptPercent,render,setRender,checkIn={},setCheckin=()=>{}) => (
    <>
      <Header>
        <TabHeaderContainer sm={sm}>
          {tabs?.length &&
            tabs.map((tab, idx) => (
              <TabHeader
                key={idx}
                active={idx === activeTab}
                onClick={() => setActiveTab(idx)}
                sm={sm}
                theme={{blueDarkSecondary:"#045680"}}

              >
                {tab}
              </TabHeader>
            ))}
        </TabHeaderContainer>
        {TabChild ? TabChild(activeTab) : ''}
      </Header>

      {tabContent?.length &&
        tabContent.map((Tab, idx) =>
          idx === activeTab ? <Tab filterByStatus={filterByStatus} checkInData={checkIn.checkIn} render={render} setRender={setRender} sortByField={sortByField} filterByMembers={filterByMembers}  aspirationalOkrData={aspirationalOkrData} allDepartments={allDepartments} allUser={allUser} criticalOkrData={criticalOkrData} allDepartment={allDepartment} filterByDept={filterByDept} allUsers={allUsers} filterDept={filterDept} searchUser={searchUser} filterByUser={filterByUser} deptPercent={deptPercent} key={idx} setCheckin={setCheckin} /> : ''
        )}
    </>
  );

  return { showTabs };
};

export default useTab;
