import React, { useState, useEffect, useContext } from "react";
import CollaborationComponent from "../Collaboration/Collaboration.component";
import ObjectiveComponent from "../Objective/Objective.component";
import OkrComponent from "../Okr/Okr.component";
import RequestComponent from "../Request/Request.component";
import { DashboardContent, FlexContainer } from "./DashboardContent.style";
import { useAuth } from "../../../context/Auth";
import UserLevelScreen from "../Objective/UserLevelScreen";
import { ModalContext } from "../../../context/Modal.context";

/**
 *
 * Wrapper Component containing Objective,Collaboration,OkR and Request Component
 */
function filterDepData(data) {
  let { labels, datasets } = data;

  let newlabel = [];
  let final = [];
  let a1 = [];
  let b1 = [];
  let c1 = [];
  let d1 = [];
  if (labels && datasets) {
    for (let j = 0; j < labels?.length; j++) {
      let [a, b, c, d] = [
        datasets[0].data[j],
        datasets[1].data[j],
        datasets[2].data[j],
        datasets[3].data[j],
      ];
      let total = a + b + c + d;
      if (total) {
        newlabel = [...newlabel, labels[j]];
        a1 = [...a1, a];
        b1 = [...b1, b];
        c1 = [...c1, c];
        d1 = [...d1, d];
      }
    }
  }
  final = { labels: newlabel, track: [[...a1], [...b1], [...c1], [...d1]] };
  return final;
}
function FilterCollabData(data, deptsData = []) {
  console.log("Filter Collab Data");
  console.log(data);
  let { labels, datasets } = data;

  let newLabel = [];
  let final = [];
  let depts = {};
  if (labels && datasets) {
    let collabList = datasets[0].data;

    for (let i = 0; i < labels.length; i++) {
      const deptId = deptsData.find((dpt) =>{
        return dpt.name === labels[i];
      });
      depts[deptId.deptId] = labels[i];
      if (collabList[i] > 0) {
        newLabel = [...newLabel, labels[i]];
        final = [...final, collabList[i]];
      }
    }
  }
  
  return { labels: newLabel, data: final, depts };
}
const DashboardContentComponent = (props) => {
  const { admin, filterByDepartment, filterByUser, filterBy } = useAuth();
  const { datefilter } = useContext(ModalContext);
  const [filterObjective, setFilterObjective] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const {
    departmentChartData,
    okrData,
    collabChartData,
    handleOnClick,
    overAllChartData,
    collabIdx,
    allCollab,
    alignments,
    render,
    setRender,
    departmentId = "",
    companyId = "",
    quarter = "",
    setQuarter = () => {},
    allUser,
    filterUser,
  } = props;

  const overAllData = overAllChartData?.datasets;

  useEffect(() => {
    if (datefilter.startDate.length && datefilter.endDate.length) {
      setStartDate(
        new Date(
          datefilter.startDate[2],
          datefilter.startDate[1],
          datefilter.startDate[0]
        )
      );
      setEndDate(
        new Date(
          datefilter.endDate[2],
          datefilter.endDate[1],
          datefilter.endDate[0]
        )
      );
    }
  }, [datefilter]);

  useEffect(() => {
    if (filterByDepartment.id || filterByUser.id || filterBy.id) {
      if (filterBy === "user") {
        if (filterByUser.id !== "alluser") {
          setFilterObjective(true);
        } else {
          setFilterObjective(false);
        }
      } else if (filterBy === "department") {
        if (filterByDepartment.id !== "all") {
          setFilterObjective(true);
        } else {
          setFilterObjective(false);
        }
      }
    }
  }, [filterByDepartment, filterByUser]);

  let data = filterDepData(departmentChartData);
  //build data for collab component
  let Collab = FilterCollabData(collabChartData, departmentChartData.labels);

  const [pin, setPin] = useState(false);
  return (
    <DashboardContent>
      <FlexContainer pin={pin}>
        {admin && !filterObjective ? (
          <ObjectiveComponent
            handleOnClick={handleOnClick}
            overAllData={overAllData ? overAllData[0].data : [1, 1, 1, 1]}
            departmentChartData={data}
            startDate={startDate}
            endDate={endDate}
          />
        ) : (
          <UserLevelScreen
            handleOnClick={handleOnClick}
            startDate={startDate}
            endDate={endDate}
            okrData={okrData}
          />
        )}
        <CollaborationComponent
          collabIdx={collabIdx}
          allCollab={allCollab}
          collabChartData={Collab}
          okrs={okrData.okrs || []}
          isFilter={filterObjective}
        />
        <OkrComponent
          allUser={allUser}
          filterUser={filterUser}
          startDate={startDate}
          endDate={endDate}
          okrData={okrData}
          render={render}
          setRender={setRender}
        />
      </FlexContainer>
      <RequestComponent
        allCollab={allCollab}
        alignments={alignments}
        departmentId={departmentId}
        companyId={companyId}
        pin={pin}
        setPin={setPin}
        startDate={startDate}
        endDate={endDate}
      />
    </DashboardContent>
  );
};

export default DashboardContentComponent;
