import React, { useEffect, useState } from 'react';
import { dummyComments } from '../../Dashboard/Okr/Tabs/helpers';
import LogContainerComponent from './LogContainer.component';
import CheckInComponent from '../../Svgs/CheckIn.component';
import ObjectiveComponent from '../../Svgs/Objective.component';
import CollabComponent from '../../Svgs/Collab.component';
import Loader from '../../Loading/Loader';

const CheckIn = (props) => {
  return (
    <LogContainerComponent
      IconComponent={CheckInComponent}
      objectiveText={props.title}
      progressWidth={`${parseFloat(props.progress).toFixed(2)}%`}
      // notes='Lorem ipsum'
      // comments={dummyComments}
      headerText='CheckIn'
      time={props.time}
      status={props.status}

    />)
};

const Objective = () => (
  <LogContainerComponent
    IconComponent={ObjectiveComponent}
    objectiveText='Lorem ipsum'
    comments={dummyComments}
    headerText='New Objective'
  />
);

const Collaboration = () => (
  <LogContainerComponent
    IconComponent={CollabComponent}
    objectiveText='Lorem ipsum'
    comments={dummyComments}
    headerText='Collaboration Request'
    assignment='Lorem ipsum'

  />
);

// CHECKIN OBJECTIVE COLLOBORAION LOG IN ACTIVITY LOG TAB
const ActivityLogComponent = (props) => {

  let customProps = {}; //store checkin data value
  //if check in data is raw
  if (props.checkInData.data) {

    const checkInData = props.checkInData;
    //create checkin data 
    if (checkInData.data?.length) {
      let temp1 = []; //store build checkin data

      checkInData.data.forEach((item) => {
        let dt = item.checkin_data || item["checkin-data"];

        for (const [key, value] of Object.entries(dt)) {
          let okr = checkInData.okrs.filter((item) => item.id === key)
          if (okr.length) {
            temp1.push({
              title: okr[0].objective,
              checkin: value,
              time: (new Date().getTime() - new Date(item.timestamp).getTime()) / (1000 * 60 * 60 * 24)
            });
          }
        }

        //store checkin props
        customProps.checkInData = temp1.sort((a, b) => a.time - b.time); //sort data in ascending order
      });
    }
  } else {
    customProps.checkInData = props.checkInData;
  }

  console.log("Custom props");
  console.log(customProps);

  return (
    <>{props.loading ? <Loader type="spin" color="cyan" /> : <>
      {Object.keys(customProps?.checkInData || {}).length ? customProps?.checkInData?.map((item, idx) => <CheckIn key={idx} item={item} time={Math.ceil(item.time)} title={item.title} progress={item.checkin.progress} />) : ""}
      {/* <CheckIn checkInData={checkInData} /> */}
      {/* <Objective />
      <Collaboration /> */}
    </>}
    </>
  );
};

export default ActivityLogComponent;
