import React from 'react';

const hoverStyle = {
    position: 'absolute',
    backgroundColor: 'white',
    top: "0px",
    left: "0",
    fontSize: "1.4rem",
    zIndex: 1,
    border: "1px solid #eee"
}

const ulStyle = {
    listStyle: "none",
    padding: "0",
    margin: "0"
}

const liStyle = {
    padding: "10px 20px",
    borderBottom: "1px solid #eee",
    cursor: "pointer"
}

function HoverList({ okrId, onEdit }) {
    return <div style={hoverStyle}>
        <ul style={ulStyle}>
            <li onClick={() => onEdit(okrId)} style={liStyle}>Edit</li>
        </ul>
    </div>
}

export default HoverList;