import React from 'react';
import MyOkrHeaderComponent from '../MyOkrHeader/MyOkrHeader.component';
import MyOkrTabComponent from '../MyOkrTab/MyOkrTab.component';
import { MyOkr } from './MyOkr.style';

const MyOkrContainerComponent = ({aspirationalOkrData,criticalOkrData,userData,render,setRender,filterByTime,checkIn={}}) => {
  return (
    <>
      <MyOkr>
        <MyOkrHeaderComponent render={render} setRender={setRender} filterByTime={filterByTime} userData={userData}  />
        <MyOkrTabComponent checkIn={checkIn}  render={render} setRender={setRender}  aspirationalOkrData={aspirationalOkrData} criticalOkrData={criticalOkrData} />
      </MyOkr>
    </>
  );
};

export default MyOkrContainerComponent;
