import React from 'react';
import { Option, RadioInput, Label } from './DashboardHeader.style';

const OptionComponent = ({ name, option,handleOnChange,id="",key }) => {
  
   return (
    <Option name={name}>
      <RadioInput  type='radio' value={option} onChange={(e)=>{  handleOnChange(e,id)}}  id={id?id:option} name={name} />
      <Label htmlFor={id?id:option} >
        <span>{option}</span>
      </Label>
    </Option>
  );
};

export default OptionComponent;
