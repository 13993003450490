import React, { useState, useEffect } from "react";
import {
  DashboardHeader,
  DashboardText,
} from "../components/Dashboard/DashboardHeader/DashboardHeader.style";
import HeaderComponent from "../components/Header/Header.component";
import MainContainerComponent from "../components/Layouts/MainContainer/MainContainer.component";
import { Button } from "../components/MyOkr/NewObjective/NewObjective.style";
import ObjectiveContainerComponent from "../components/Objective/ObjectiveContainer/ObjectiveContainer.component";
import { Header } from "../components/Settings/SettingsContent/SettingContent.style";
import { okr ,User} from "../api/Api";
import { useAuth } from "../context/Auth";
import axios from "axios";
import Loader from "../components/Loading/Loader";


export const AllUser=()=>{
  
}

const Objectives = () => {
  const [chart, setChart] = useState(false);
  const { idToken, uid } = useAuth();
  const [criticalOkrData, setCriticalOkrData] = useState([]);
  const [aspirationalOkrData, setAspirationalOkrData] = useState([]);
  const [allOkr,setAllOkr]=useState([]);
  const [allDepartments,setAllDepartments]=useState([]);
  const [allUser,setAllUser]=useState([]);
  const [users,setUsers]=useState({});
  const [deptPercent,setDeptPercent]=useState({});
  const [loading,setLoading]=useState(false);

  console.log(allDepartments);
  console.log(allUser);
  useEffect(()=>{
    let temp={};
    if(allUser.length&&allDepartments.length)
    {
      allDepartments.forEach((dept)=>{
        allUser.forEach(user=>{
          if(dept._id===user.departmentid)
          {
            if(temp[dept._id])
            {
              temp[dept._id].push(user.uuid);
            }
            else{
              temp[dept._id]=[user.uuid];
            }
          }
        })
      })
    }
    setUsers(temp);
  },[allUser,allDepartments])

  console.log(users);
  useEffect(()=>{
    let temp={}
    for (const [key, value] of Object.entries(users)) {
        let total=value.length;
        let okrCreated=0;
        if(allOkr?.length)
        {
        value.forEach((user)=>{
      
          let isOkr=allOkr.find(okr=>okr.createdby===user)
          if(isOkr)
          {
            okrCreated++;
          }

          // allOkr.forEach(okr=>{
          //   if(user===okr.createdby)
          //   okrCreated++;
          // })
        })
        }
        temp[key]=parseFloat((okrCreated/total)*100).toFixed(2);



    
    }
    console.log(temp); 
    setDeptPercent(temp); 

  },[users,allOkr])
  useEffect(() => {
   
    const getData=async()=>{
      setLoading(true);
     await axios
      .post(
        okr.viewAllOkr,
        {  status: ["S", "R", "T", "C"] },
        { headers: { Authorization: `${idToken}`,uuid:uid } }
      )
      .then((res) => {
        console.log(res);
        setAllDepartments(res.data.departments);
        setAllOkr(res.data.okrs);
        let critical = res.data.okrs.filter((item) => item.type === "Critical");

        let aspirational = res.data.okrs.filter(
          (item) => item.type === "Aspirational"
        );

        setCriticalOkrData([...critical]);
        setAspirationalOkrData([...aspirational]);
       
      })
      .catch((err) => console.log(err));
      await axios.get(User.getAllUser,{
        headers:{
          Authorization:`${idToken}`,
          uuid:`${uid}`
        }
      }).then(res=>{setAllUser(res.data.users)}).catch(err=>console.log(err));
    
      setLoading(false);
    }
      getData()
  }, []);
 
  const filterByStatus=(val)=>{
    if(val!=="All")
    {
      let critic=allOkr.filter((item)=>item.type==="Critical")
      let aspi=allOkr.filter((item)=>item.type==="Aspirational")
    setCriticalOkrData(critic.filter((item)=>item.status===val));
    setAspirationalOkrData(aspi.filter((item)=>item.status===val));
    }
    else{
      setCriticalOkrData(allOkr.filter((item)=>item.type==="Critical"));
      setAspirationalOkrData(allOkr.filter((item)=>item.type==="Aspirational"));
    }

  }

  const filterBySearch=(val)=>{
    if(val)
    {
    let critic=allOkr.filter((item)=>item.type==="Critical")
    let aspi=allOkr.filter((item)=>item.type==="Aspirational")
    setCriticalOkrData(critic.filter((item)=>item.title.toLowerCase().includes(val.toLowerCase())));
    setAspirationalOkrData(aspi.filter((item)=>item.title.toLowerCase().includes(val.toLowerCase())));
    }
    else{
      let critic=allOkr.filter((item)=>item.type==="Critical")
      let aspi=allOkr.filter((item)=>item.type==="Aspirational")
      setCriticalOkrData(critic);
      setAspirationalOkrData(aspi);
    }

  }

  const FilterbyDept=(data)=>{
    if(data==="all")
    {
      let critic=allOkr.filter((item)=>item.type==="Critical")
      let aspi=allOkr.filter((item)=>item.type==="Aspirational")
      setCriticalOkrData(critic);
      setAspirationalOkrData(aspi);
    }
    else{
     
      let critic=allOkr.filter((item)=>item.type==="Critical")
      let aspi=allOkr.filter((item)=>item.type==="Aspirational")
      setCriticalOkrData(critic.filter((item)=>item.departmentid===data));
      setAspirationalOkrData(aspi.filter((item)=>item.departmentid===data));
      


    }


  }
  const FilterByUser=(data)=>{

    if(data==="all")
    {
      let critic=allOkr.filter((item)=>item.type==="Critical")
      let aspi=allOkr.filter((item)=>item.type==="Aspirational")
      setCriticalOkrData(critic);
      setAspirationalOkrData(aspi);
    }
    else
    {
      let critic=allOkr.filter((item)=>item.type==="Critical")
      let aspi=allOkr.filter((item)=>item.type==="Aspirational")
      setCriticalOkrData(critic.filter((item)=>item.createdby===data));
      setAspirationalOkrData(aspi.filter((item)=>item.createdby===data));
    }



  }
  return (
    <>
      <main>
        <HeaderComponent />
        <MainContainerComponent page="Objectives">
          <DashboardHeader>
            <Header className="no-ms">
              <DashboardText>Objectives</DashboardText>
            </Header>
            <Header className="no-ms">
              <Button
                borderRadius="5px 0px 0px 5px"
                main={!chart}
                marginRight="0px"
                onClick={() => setChart((state) => !state)}
              >
                List View
              </Button>
              <Button
                borderRadius="0px 5px 5px 0px"
                main={chart}
                marginRight="0px"
                onClick={() => setChart((state) => !state)}
              >
                Org Chart
              </Button>
            </Header>
          </DashboardHeader>
          {loading?<Loader type="spin" color="cyan" />:
          <ObjectiveContainerComponent deptPercent={deptPercent}  filterBySearch={filterBySearch} filterByStatus={filterByStatus} filterByUser={FilterByUser} filterByDept={FilterbyDept} allDepartments={allDepartments} allUser={allUser} criticalOkrData={criticalOkrData} aspirationalOkrData={aspirationalOkrData} chart={chart} />
          }
          </MainContainerComponent>
      </main>
    </>
  );
};

export default Objectives;
