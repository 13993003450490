import React from 'react';

const AddReqComponent = (props) => {
  return (
    <svg
      width='22'
      height='23'
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.001 3.24458e-05C4.92641 3.24458e-05 6.905e-08 4.92235 6.905e-08 10.997C-0.000161849 12.4422 0.284443 13.8733 0.837554 15.2085C1.39067 16.5437 2.20144 17.7568 3.22356 18.7785C7.52163 23.0766 14.483 23.0766 18.7785 18.7785C23.074 14.4804 23.0766 7.52166 18.7785 3.22359C17.7596 2.1988 16.5476 1.38624 15.2126 0.832918C13.8776 0.2796 12.4461 -0.00348857 11.001 3.24458e-05ZM16.9365 8.36081L9.96744 15.0177C9.8723 15.1087 9.74602 15.1599 9.61438 15.161C9.47864 15.1604 9.3484 15.1072 9.25109 15.0126L5.29584 11.0573C5.21202 10.9595 5.16821 10.8335 5.17319 10.7048C5.17816 10.576 5.23155 10.4538 5.32267 10.3627C5.4138 10.2716 5.53596 10.2182 5.66473 10.2132C5.79351 10.2082 5.91942 10.2521 6.0173 10.3359L9.6195 13.9381L16.2303 7.624C16.33 7.54181 16.4569 7.50032 16.5859 7.50786C16.7148 7.51539 16.8361 7.57139 16.9254 7.66463C17.0148 7.75786 17.0656 7.88143 17.0676 8.01055C17.0697 8.13967 17.0228 8.26479 16.9365 8.36081Z'
        fill='#1DB535'
      />
    </svg>
  );
};

export default AddReqComponent;
