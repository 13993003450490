import React, { useState, useEffect } from "react";
import { Text } from "../../../utils/shared.style";
import DashboardInputComponent from "../../Dashboard/DashboardHeader/DashboardInput.component";
import { Line } from "../../Dashboard/Objective/Objective.style";
import ObjectiveComponent from "../../Svgs/Objective.component";
import { InputContainer, ObjectiveHeader, Search } from "./NewObjective.style";
import TabComponent from "./Tab.component";
import InputComponent from "../../Input/Input.component";
import { ChooseCollab, CollabList } from "./NewObjective.style";
import FilterComponent from "../../Dashboard/DashboardHeader/Filter.component";
// import DeleteComponent from '../../Svgs/Delete.component';
// import CloseReqComponent from '../../Svgs/CloseReq.component';
import CloseComponent from "../../Svgs/Close.component";
const ColloborationComponent = ({
  departments,
  formData,
  setFormData,
  allUser,
  filterUser,
}) => {
  console.log(allUser);
  const [department, setDepartment] = useState({
    department: "",
  });
  const [val, setVal] = useState();
  const [FilterData, setFilterData] = useState([]);

  useEffect(() => {
    if (department.id) {
      if (department.id === "alldept") filterUser("all");
      else filterUser(department.id.substring(0, department.id.length - 1));
    }
  }, [department]);

  useEffect(() => {
    if (val) {
      let data = allUser.filter((item) => {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
      setFilterData(data);
    } else {
      setFilterData(allUser);
    }
  }, [allUser]);

  const deleteData = (id) => {
    setFormData(formData.filter((item) => item.id !== id))
  }

  const handleOnChange = (e) => {
    let val = e.target.value;
    setVal(val);

    let data = allUser.filter((item) => {
      return item.name.toLowerCase().includes(val.toLowerCase());
    });
    setFilterData(data);
  };
  const setUpCollab = (data, id) => {
    setFormData([...formData, { name: data, id: id }]);
  };
  return (
    // <ObjectiveHeader>
    <>
      <div style={{ margin: "3rem 0" }}>
        <Text bold>Collaboration</Text>
        <Line />
      </div>
      <TabComponent header="Details" isOpen={true} browse={false}>
        <CollabList>
          {formData.map((item) => {
            return (
              <ChooseCollab>
                {item.name}

                <div onClick={() => deleteData(item.id)}>
                  <CloseComponent />
                </div>
              </ChooseCollab>
            );
          })}
        </CollabList>
      </TabComponent>
      <TabComponent header="Colloborate with" browse={false}>
        <ObjectiveHeader>
          <InputContainer>
            <InputComponent search={true} onChange={(e) => handleOnChange(e)} />
          </InputContainer>
          <InputContainer>
            <DashboardInputComponent
              value={
                department.department ? department.department : "Department"
              }
            >
              <FilterComponent
                name="department"
                formData={department}
                setFormData={setDepartment}
                options={ departments ? ["All", ...(departments?.map((item) => item.name))] : ["All"]}
                id={departments ? ["alldept", ...(departments?.map((item, idx) => `${item._id + idx}`))] :  ["alldept"]}
                special
              />
            </DashboardInputComponent>
          </InputContainer>
        </ObjectiveHeader>
        <Text light className="underline">
          <em>Search Results({FilterData.length})</em>
        </Text>
        <div style={{ maxHeight: "300px", overflowY: "scroll" }}>        {FilterData.map((item, id) => {
          return (
            <div key={id}>
              <Search>
                <ObjectiveComponent className="mr-2" />
                <Text
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setUpCollab(item.name, item.uuid);
                  }}
                >
                  {item.name}
                </Text>
              </Search>
              <Line />
            </div>
          );
        })}
        </div>

      </TabComponent>
    </>
    // </ObjectiveHeader>
  );
};

export default ColloborationComponent;
