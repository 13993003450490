import ModalProvider from "./context/Modal.context";
import { useEffect, useState } from "react";

import Routes from "./Routes/Routes";
import { Route } from "react-router-dom";
import GlobalCss from "./utils/global.style";
import Menu from "./components/Menu/Menu.component";

// import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
require("dotenv").config();

const App = () => {
  return (
    <>
      <ModalProvider>
        {/* Styled component global css */}
        <GlobalCss />
        {/* App Routes */}
        {/* Same as */}
        <Routes />
        <Menu />
        {/* <Route exact path='/login' component={LogIn}/> */}
      </ModalProvider>
    </>
  );
};

export default App;
