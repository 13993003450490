import React from 'react';

const ListDropdownComponent = ({ style, fill = '#1F1F1F',className="" }) => {
  return (
    <svg
      width='23'
      height='23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 10.414 8.414 9l3.293 3.293L15 9l1.414 1.414-4.707 4.707L7 10.414z'
        fill={fill}
      />
    </svg>
  );
};

export default ListDropdownComponent;
