import React, { useEffect, useState } from "react";
import NewRequestComponent from "./NewRequest.component";
import RequestHeaderComponent from "./RequestHeader.component";
import { useAuth } from "../../../context/Auth";
import { collabortion } from "../../../api/Api";
import ApproveModalComponent from "./ApproveModal.component";
import axios from "axios";
import { useHistory } from "react-router-dom";

const CollaborationComponent = (props) => {
  const [collabs, setCollabs] = useState([]);
  const history = useHistory();
  const { uid, idToken } = useAuth();

  const [sortBy, setSortBy] = useState("");

  const [collabId, setCollabId] = useState("");
  const [data, setData] = useState([]);
  const { departmentOkr, allCollab } = props;
  const [progressBarData, setProgressBarData] = useState({
    request: 0,
    approved: 0,
    rejected: 0,
  });

  const handleOnClick = (id, status) => {
    props?.setShowApprove(true);
    props?.setStatus(status);

    let tl = data.find((item) => item.collab._id === id);

    console.log(tl);
    props.setTitle(tl.okr.title);
    props.setOwner(tl.okr.username);
    props.setHeader("Collab Request");

    // setOwner(tl.okr.username);
    setCollabId(id);
  };

  useEffect(() => {
    if (props.approve) {
      axios
        .post(
          collabortion.approveCollab,
          { requestID: collabId, status: props?.status },
          {
            headers: {
              Authorization: `${idToken}`,
            },
          }
        )
        .then((res) => {
          setCollabs(
            collabs.map((item) =>
              item._id === collabId ? { ...item, status: props?.status } : item
            )
          );
          props.setApprove(false);
        })
        .catch((err) => console.log(err));
    }
  }, [props.approve]);

  useEffect(() => {
    const temp = [];
    collabs?.forEach((item) => {
      departmentOkr.forEach((okr) => {
        if (okr._id === item.objID) temp.push({ okr: okr, collab: item });
      });
    });
    setData(temp);
  }, [collabs]);

  useEffect(() => {
    const temp = [...new Map(allCollab.map((v) => [v._id, v])).values()];
    const temp2 = temp.filter((item, index) => item.uuid === uid);
    setCollabs(temp2);
  }, []);

  console.log(allCollab);
  // console.log(props);

  //
  // console.log(temp);

  //

  useEffect(() => {
    if (data.length) {
      let temp = { request: 0, approved: 0, rejected: 0 };
      if (data.length) {
        data.forEach((item) => {
          item.collab.status === "NA"
            ? (temp.request += 1)
            : item.collab.status === "A"
            ? (temp.approved += 1)
            : (temp.rejected += 1);
        });
        setProgressBarData(() => {
          return { ...temp };
        });
      }
    }
  }, [data]);

  useEffect(() => {
    let temp = data;
    if (sortBy === "latest") {
      console.log(data);
      temp.sort((a, b) => {
        let name1, name2;

        name1 = new Date(a.timestamp);
        name2 = new Date(b.timestamp);

        if (name1 > name2) return -1;
        if (name1 < name2) return 1;

        return 0;
      });

      console.log(temp);
      setData([...temp]);
    }
  }, [sortBy]);

  const showDetails = (id) => {
    history.push(`/okr/${id}`);
  };
  console.log(data);
  const getData = (cnt) => {
    const { approved, rejected, request } = progressBarData;
    let total = approved + rejected + request;
    return (cnt / total) * 100;
  };
  return (
    <>
      <RequestHeaderComponent
        index={`${progressBarData.approved}/${
          progressBarData.approved +
          progressBarData.rejected +
          progressBarData.request
        }`}
        checkin
        request={getData(progressBarData?.request)}
        approved={getData(progressBarData?.approved)}
        rejected={getData(progressBarData?.rejected)}
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
      {data?.map((item) => {
        console.log(item);
        return (
          <NewRequestComponent
            handleOnClick={handleOnClick}
            okrID={item.okr._id}
            showDetails={showDetails}
            _id={item.collab._id}
            title={item.okr.title}
            name={item.okr.name}
            owner={item.collab.userdetails1.name}
            isRequestNew={item.collab.status === "NA"}
            approved={item.collab.status === "A"}
            IconName="CollaborationComponent"
          />
        );
      })}
      {/* {
        showApprove&&<ApproveModalComponent name={title} owner={owner} sendApproval={sendCollabApproval} title="New Checkin Request" buttonText={status==="A"?"Approve":"Rejected"} bgcolor={status==="A"?"045680":"F67575"} setModal={setShowApprove} />
      } */}
      {/* <NewRequestComponent  IconName="CollaborationComponent"/>
       */}
    </>
  );
};

export default CollaborationComponent;
