import React,{useEffect,useState} from 'react';
import { Line } from '../../Dashboard/Objective/Objective.style';
import StatusComponent from '../../Dashboard/Objective/Status.component';
import { Circle as Cir } from '../../Header/Header.style';
import { Header } from '../../Settings/SettingsContent/SettingContent.style';
// import ListDropdownComponent from '../../Svgs/ListDropdown.component';
import OrgDropdownComponent from '../../Svgs/OrgDropdown.component';

import UserComponent from '../../Svgs/User.component';
import { Circle } from '../Department/Department.style';

import {
  Chart,
  Detail,
  Dropdown,
  ImageContainer,
  ProfileContainer,
  Text,
} from './ChartView.style';

const ChartComponent = (props) => {
  const [status,setStatus]=useState("");
  const [okrStatus,setOkrStatus]=useState("")
  
  const{root,data,okrs,name,expandIcon,viewAllOkr}=props;
  console.log(data);
  const handleOkrStatus = (okrData) => {
    var tempSatus = [0, 0, 0, 0];
    okrData.forEach((okr) => {
      if (okr.createdby === data.id) {
        if (okr.status === "S")
          tempSatus[0] += 1;
        else if (okr.status === "R")
          tempSatus[1] += 1;
        else if (okr.status === "T")
          tempSatus[2] += 1;
        else
          tempSatus[3] += 1;
      }
    });
    console.log(tempSatus)
    const i = tempSatus.indexOf(Math.max(...tempSatus));
    if (i === 0) {
      setStatus("S");
    } else if (i === 1) {
      setStatus("R");
    } else if (i === 2) {
      setStatus("T");
    } else {
      setStatus("C");
    }
    setOkrStatus(tempSatus);
  }
console.log(okrs);
  useEffect(() => {
    if(okrs?.length)
    handleOkrStatus(okrs);
  }, [okrs,data])
// console.log(status);
// console.log(okrStatus);
  console.log(data);
  return (
    <Chart style={{maxWidth:"400px",margin:"0 auto"}} super={root}>
      <ProfileContainer className='mbb'>
        <ImageContainer>
          <Cir sm>
            <UserComponent />
          </Cir>
        </ImageContainer>
        <Detail>
          <Text bold>{name||"John"}</Text>
          <Text bold>{data?.role||"SuperAdmin"}</Text>
          <Text bold>
            {data?.children?.length} Directs
            <div style={{ marginLeft: '0.5rem' }}>
              <Circle bg='white'>
                <span onClick={()=>{expandIcon(data,root)}} style={{cursor:"pointer", color: 'black' }}>{root?"-":"+"}</span>
              </Circle>
            </div>
          </Text>
        </Detail>
      </ProfileContainer>
      <Line />
      <Header className='no-ms' style={{ minHeight: '3rem' }}>
        <StatusComponent text1={okrStatus[2]} text2={okrStatus[0]} text3={okrStatus[3]} text4={okrStatus[1]} white />
        <Dropdown onClick={()=>viewAllOkr(data.id)}>
          <OrgDropdownComponent
            fill='white'
            style={{ transform: 'rotate(-90deg)' }}
            
          />
        </Dropdown>
      </Header>
    </Chart>
  );
};

export default ChartComponent;
