import React from 'react';

const UploadComponent = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='13'
      viewBox='0 0 10 13'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.52515 3.59317C5.41405 3.48376 5.26438 3.42244 5.10846 3.42244C4.95253 3.42244 4.80286 3.48376 4.69176 3.59317L1.54232 6.80992C0.984089 7.37013 1.82143 8.20945 2.37966 7.64924L4.24637 5.7766C4.40077 5.62219 4.52746 5.67168 4.52746 5.88943V12.3764C4.52746 12.5339 4.59003 12.6849 4.7014 12.7963C4.81277 12.9077 4.96382 12.9702 5.12132 12.9702C5.27882 12.9702 5.42988 12.9077 5.54125 12.7963C5.65262 12.6849 5.71518 12.5339 5.71518 12.3764V5.88943C5.71518 5.67168 5.8399 5.62022 5.99628 5.7766L7.86298 7.64924C8.42121 8.20945 9.24866 7.37013 8.70033 6.80992L5.52515 3.59317ZM1.16027 1.43943H9.07842C9.23592 1.43943 9.38697 1.37686 9.49834 1.26549C9.60971 1.15412 9.67228 1.00307 9.67228 0.84557C9.67228 0.688069 9.60971 0.537017 9.49834 0.425647C9.38697 0.314276 9.23592 0.251709 9.07842 0.251709H1.16027C1.00277 0.251709 0.851715 0.314276 0.740344 0.425647C0.628974 0.537017 0.566406 0.688069 0.566406 0.84557C0.566406 1.00307 0.628974 1.15412 0.740344 1.26549C0.851715 1.37686 1.00277 1.43943 1.16027 1.43943Z'
        fill='#045680'
      />
    </svg>
  );
};

export default UploadComponent;
