export const dummyOkrData = [
  {
    okr: 'Improve Progress',
    owner: 'John',
    time: new Date().getDate(),
    progress: '50%',
  },
  {
    okr: 'Improve Progress',
    owner: 'Jonas',
    time: new Date().getDate(),
    progress: '20%',
  },
  {
    okr: 'Improve Progress',
    owner: 'Doe',
    time: new Date().getDate(),
    progress: '80%',
  },
];

export const dummyOkrAnalyticData = [
  {
    okr: 'Improve Progress',
    owner: 'John',
    time: new Date().getDate(),
    progress: '50%',
    status: 'In Review',
  },
  {
    okr: 'Improve Progress',
    owner: 'Jonas',
    time: new Date().getDate(),
    progress: '20%',
    status: 'In Review',
  },
  {
    okr: 'Improve Progress',
    owner: 'Doe',
    time: new Date().getDate(),
    progress: '80%',
    status: 'Complete',
  },
];

export const listStyle = (open) => ({
  margin: 0,
  transform: `rotate(${open ? '0deg' : '-90deg'})`,
});

export const dummyNotification = [
  {
    body: 'Lorem ipsum sample notify',
    time: '15 mins ago',
  },
  {
    body: 'Lorem ipsum sample notify',
    time: '15 mins ago',
  },
  {
    body: 'Lorem ipsum sample notify',
    time: '15 mins ago',
  },
];

export const dummyStatusGraph = [
  {
    red: '50%',
    blue: '30%',
    grey: '20%',
    green: '10%',
  },
  {
    red: '20%',
    blue: '70%',
    grey: '5%',
    green: '5%',
  },
  {
    red: '40%',
    blue: '30%',
    grey: '20%',
    green: '10%',
  },
  {
    red: '10%',
    blue: '50%',
    grey: '20%',
    green: '20%',
  },
  {
    red: '50%',
    blue: '30%',
    grey: '20%',
    green: '10%',
  },
];

export const dummyColloboratData = [
  {
    height: '70%',
  },
  {
    height: '30%',
  },
  {
    height: '20%',
  },
  {
    height: '50%',
  },
];

export const dummyComments = [
  {
    comment: 'Lorem ipsum',
    time: '15 mins ago',
  },
  {
    comment: 'Lorem ipsum',
    time: '15 mins ago',
  },
];

export const dummyDept = [{}];
