import React from 'react';
import { Meta } from '../../Header/Header.style';
import ModalComponent from '../../Modal/Modal.component';
import { dummyStatusGraph } from '../Okr/Tabs/helpers';
import { BarGraph } from './Objective.component';
import { PieChart, Pie, Cell, Label } from 'recharts';

import {

  ObjectiveCircle,
} from './Objective.style';
import StatusComponent from './Status.component';

/**
 * @param (boolean) props.modal
 *
 */
const COLORS = ["#099e60", "#b7b7b7", "#00b8e0", "#e95252"];
const ObjectiveModal = ({ modal, color = [], onClick, Department = [], notStarted = [], onTrack = [], completed = [], overAllData = [], atRisk = [] }) => {
  console.log(overAllData);
  const data = [
    { name: 'on Track', students: overAllData[1] },
    { name: 'not Started', students: overAllData[0] },
    { name: 'Completed', students: overAllData[3] },
    { name: 'at Risk', students: overAllData[2] }
  ];
  return (
    <ModalComponent
      modal={modal}
      headerText='Objective Status'
      placeholder='Show'
      onClick={onClick}
      OverallComponent={() => (
        <>
          <Meta>Overall</Meta>
          <PieChart cx="0%" margin={{ top: 0, left: 0, right: 0, bottom: 0 }} cy="0%" width={200} height={150}>
            <Pie data={data} dataKey="students" outerRadius="80%" innerRadius="60%" fill="grenn">
              {data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)}

              <Label width={50} style={{ fontWeight: "500", fontSize: "1.5rem" }} position="center">
                {`${overAllData[0] + overAllData[3] + overAllData[1] + overAllData[2]} ${`okr`}`}
              </Label>

            </Pie>
          </PieChart>
          <div style={{ width: '60%' }}>
            <StatusComponent />
          </div>
        </>
      )}
      DepartmentComponent={() => (
        <div style={{ flexGrow: "100", display: "flex", flexDirection: "column" }}>
          {/* Graph width of color percentage or pixel recommended percentage for accuracy */}
          <BarGraph style={{ style: "space-around" }} tempDepartmentName={Department} notStarted={notStarted} onTrack={onTrack} atRisk={atRisk} completed={completed} />
          {/* Seperator Line */}
          {/* <Line />
          <Department style={{style:"space-around"}}>
            <DepartmentText>Dept1</DepartmentText>
            <DepartmentText>Dept2</DepartmentText>
            <DepartmentText>Dept3</DepartmentText>
            <DepartmentText>Dept4</DepartmentText>
            <DepartmentText>Dept5</DepartmentText>
          </Department> */}
        </div>
      )}
    />
  );
};

export default ObjectiveModal;
