import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Logs from "../pages/Logs";
import MyOkr from "../pages/MyOkr";
import Objectives from "../pages/Objectives";
import Settings from "../pages/Settings";
import LogIn from "../pages/LogIn";
import { useAuth } from "../context/Auth";
// import SingleOkrComponent from '../components/MyOkr/SingleOkr/SingleOkr.component';
import SingleOkr from "../pages/SingleOkr";

const Routes = () => {
  const { uid, loading, idToken } = useAuth();

  return (
    <Switch>
      {!loading && (
        <>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/okr" component={MyOkr} />

          <Route exact path="/settings" component={Settings} />
          <Route exact path="/objective" component={Objectives} />
          <Route exact path="/logs" component={Logs} />
          <Route exact path="/okr/:id" component={SingleOkr} />
        </>
      )}
      <Route exact path="/login" component={LogIn} />
    </Switch>
  );
};

export default Routes;
