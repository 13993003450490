import React from 'react';
import {LoadingContainer} from './Loader.style';
import ReactLoading from 'react-loading';

function Loader({type,color}) {
    return (
        <LoadingContainer>
            <ReactLoading type={type} color={color} height={100} width={100}/>
        </LoadingContainer>
    )
}

export default Loader;
