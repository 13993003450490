import styled from 'styled-components';
import { myFlex } from '../../../utils/shared.style';

export const DashboardContent = styled.section`
  margin-top: 0.5rem;
  ${myFlex};
  /* flex-wrap: wrap; */
  padding: 14px;
 
  ${({chart})=>chart?`min-height:99vh`:""}

  @media (max-width: 915px) {
    flex-direction: column;
  }
  @media (max-width: 400px) {
    flex-direction: column;
    padding: 0.5rem;
  }
`;

export const FlexContainer = styled.div`
  
  
  
 
  ${myFlex};
  flex-wrap: wrap;
  // min-width: 70%;
  @media (max-width: 915px) {
    flex-direction: column;
    width: 100%;
  }
  ${({pin})=>pin?`flex:0.7;`:`flex:1;`}
 
`;
