import React from 'react';
import { Line } from '../Dashboard/Objective/Objective.style';
import { Detail } from '../Dashboard/Request/Request.style';
import UserComponent from '../Svgs/User.component';
import {
  Circle,
  Meta,
  Notification,
  NotificationBody,
  NotificationContainer,
  NotificationWrapper,
  ProfileHeader,
} from './Header.style';

/**
 *
 * @param {array} props.notifications array of notification
 *
 */

const NotificationListComponent = (props) => {
  console.log(props);
  const{ notifications}=props;
  // const [isRead,setIsRead]=React.useState(false);
  console.log(notifications);
  return (
    
    <NotificationContainer>
      <NotificationWrapper>
        <NotificationBody>
          <ProfileHeader>Notifications</ProfileHeader>
          <Detail style={{cursor:"pointer"}} onClick={()=>props.setIsRead(true)}>
            <em>Mark all as read</em>
          </Detail>
        </NotificationBody>
        <div>
          <Line />
        </div>
        {/* List Notifications  */}

        {notifications?.length
          ? notifications.map((notification, idx) => (
              <div key={idx}>
                <NotificationBody>
                  <Circle sm>
                    <UserComponent />
                  </Circle>
                  <Notification>{notification?.userdetails[0].name || 'No data'} Checked in </Notification>
                  <Meta>{new Date(notification?.timestamp).toLocaleDateString() || 'No data'}</Meta>
                </NotificationBody>
                <Line />
              </div>
            ))
          : ''}
      </NotificationWrapper>
    </NotificationContainer>
  );
};

export default NotificationListComponent;
