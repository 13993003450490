import styled from 'styled-components';
import { Progress } from '../../Dashboard/Request/Request.style';


export const SingleOkrContainer=styled.div`


`
export const SingleOKrHeader=styled.div`
.firstDiv{
    min-height:235px;
    background-color:#fff;
    margin:14px;
    padding:10px;
    box-sizing:border-box;
}
.firstDivChild{
    display:flex;
    // font-size:1.3rem;

    justify-content:space-between;
}
.objInfo{
    display:flex;
    justify-content:space-between;
    // height:100%;
}
.secondDiv{
    min-height:82px;
    box-sizing:border-box;
    background-color:#fff;
    margin:14px;
    padding:10px;
    display:flex;
    justify-content:space-between;
    >div{
        flex:1;
    }

}
`
export const Heading=styled.div`
width:${({width})=>width?"100%":"25%"};
// ${({progress})=>progress&&`padding-left:50px`}
display:flex;
flex-direction:column;
align-items:${({start})=>start?'flex-start':"center"};
justify-content:space-between;
height:100%;


`

export const SingleOkrContent=styled.div`


`