import React from 'react';

const EditComponent = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      {...props}
    >
      <path
        d='M16.7533 3.71384L13.7207 0.681195C13.6427 0.603088 13.55 0.541125 13.448 0.49885C13.3461 0.456574 13.2367 0.434814 13.1263 0.434814C13.0159 0.434814 12.9066 0.456574 12.8047 0.49885C12.7027 0.541125 12.61 0.603088 12.532 0.681195L1.51871 11.6945C1.39277 11.821 1.31038 11.9843 1.28349 12.1607L0.703849 15.941C0.685574 16.0614 0.693694 16.1844 0.727648 16.3013C0.761601 16.4183 0.820575 16.5264 0.900484 16.6183C0.980393 16.7102 1.07933 16.7836 1.19043 16.8335C1.30153 16.8834 1.42215 16.9085 1.54392 16.9071C1.60117 16.913 1.65888 16.913 1.71613 16.9071L5.32842 16.1468C5.48742 16.1134 5.63332 16.0347 5.74845 15.92L16.7533 4.90253C16.8314 4.82451 16.8934 4.73186 16.9357 4.62988C16.978 4.5279 16.9997 4.41858 16.9997 4.30819C16.9997 4.19779 16.978 4.08847 16.9357 3.98649C16.8934 3.88451 16.8314 3.79186 16.7533 3.71384ZM4.72777 14.5507L2.5478 15.0085L2.90062 12.69L3.40887 12.1859L5.24021 14.0299L4.72777 14.5507ZM6.43731 12.8412L4.59336 10.9972L10.6586 4.93194L12.5026 6.77588L6.43731 12.8412ZM13.6913 5.58299L11.8473 3.73904L13.1284 2.47894L14.9724 4.32289L13.6913 5.58299Z'
        fill='#045680'
      />
    </svg>
  );
};

export default EditComponent;
