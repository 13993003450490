import React from 'react';

const CheckInComponentSvg = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='25'
      viewBox='0 0 22 25'
      fill='none'
      {...props}
    >
      <path
        d='M21.8806 15.4621L18.1496 10.1136L21.8806 4.74621C21.9715 4.60429 22.0117 4.43701 21.9948 4.2705C21.9779 4.10399 21.905 3.9476 21.7873 3.82576C21.7159 3.75166 21.6298 3.69235 21.5343 3.65135C21.4389 3.61035 21.3359 3.58851 21.2316 3.58712H15.0599V1.95455C15.0599 1.75362 14.978 1.56093 14.8322 1.41886C14.6865 1.27679 14.4888 1.19697 14.2826 1.19697H3.82813V0.757576C3.82813 0.556654 3.74624 0.363962 3.60047 0.221889C3.4547 0.0798158 3.257 0 3.05085 0H0.777286C0.571137 0 0.373431 0.0798158 0.227662 0.221889C0.0818925 0.363962 0 0.556654 0 0.757576V24.2424C0 24.4433 0.0818925 24.636 0.227662 24.7781C0.373431 24.9202 0.571137 25 0.777286 25H3.05085C3.257 25 3.4547 24.9202 3.60047 24.7781C3.74624 24.636 3.82813 24.4433 3.82813 24.2424V13.2348H10.128V15.8864C10.128 16.0873 10.2099 16.28 10.3557 16.4221C10.5015 16.5641 10.6992 16.6439 10.9053 16.6439H21.2277C21.3696 16.6411 21.5081 16.6004 21.628 16.5263C21.7479 16.4521 21.8446 16.3474 21.9078 16.2235C21.9727 16.1055 22.0043 15.973 21.9995 15.8392C21.9947 15.7055 21.9537 15.5754 21.8806 15.4621ZM16.5873 10.5379L19.7741 15.1288H12.7358L14.8112 13.0303C14.89 12.96 14.9532 12.8746 14.9966 12.7794C15.0401 12.6843 15.063 12.5814 15.0638 12.4773V5.10227H19.7547L16.5678 9.68939C16.481 9.81467 16.4345 9.96242 16.4345 10.1136C16.4345 10.2648 16.481 10.4126 16.5678 10.5379H16.5873ZM11.6865 13.2348H12.4638L11.6865 13.9924V13.2348ZM13.5053 11.7197H3.82813V2.71212H13.5053V11.7197ZM2.27356 1.95455V23.4848H1.55457V1.51515H2.27356V1.95455Z'
        fill='#003D67'
      />
    </svg>
  );
};

export default CheckInComponentSvg;
