import React from 'react';
import { Text } from '../../../utils/shared.style';
import { Line } from '../../Dashboard/Objective/Objective.style';
import InputComponent from '../../Input/Input.component';
import { TextArea } from '../../MyOkr/MyOkrTab/MyOkrTab.style';
import { Browse, Button } from '../../MyOkr/NewObjective/NewObjective.style';
import CloseReqComponent from '../../Svgs/CloseReq.component';
import LinkComponent from '../../Svgs/Link.component';
import { ModalContainer, Modal, Header, Or } from './SettingContent.style';

const InvitePeople = ({ modal, onClick }) => {
  return (
    <Modal modal={modal}>
      <ModalContainer>
        <Header className='no-ms mbb'>
          <Text bold>Invite People</Text>
          <div onClick={onClick}>
            <CloseReqComponent />
          </div>
        </Header>
        <Text light className='mbb'>
          Invite Share Link
        </Text>
        <Header className='no-ms mbb'>
          <Header className='no-ms'>
            <InputComponent placeholder='https://' width='50%' />
            <LinkComponent className='mr-1' />
            <Text blue>Copy Link</Text>
          </Header>
          <div>&emsp;</div>
        </Header>
        <Or className='mbb'>
          <Line />
          OR
          <Line />
        </Or>
        <Text bold className='mbb'>
          Email
        </Text>
        <TextArea rows='10' className='mbb' />
        <Line className='mbb' />
        <Browse>
          <Button onClick={onClick}>Cancel</Button>
          <Button main>Invite</Button>
        </Browse>
      </ModalContainer>
    </Modal>
  );
};

export default InvitePeople;
