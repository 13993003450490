import React from 'react';
import InnerTabComponent from '../../Dashboard/Okr/Tabs/InnerTab.component';

function KeyResultsComponent(props) {
    console.log(props);   
    // props.setCheckin(true);
    return (
        <InnerTabComponent from="singleOkr" title="Title" showType  setCheckin={props?.setCheckin} onClicked={props?.setCheckin} okrData={props.criticalOkrData} />
    )
}

export default KeyResultsComponent
