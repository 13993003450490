import React, { useState, useEffect } from 'react';
import DashboardInputComponent from '../../Dashboard/DashboardHeader/DashboardInput.component';
import { Line } from '../../Dashboard/Objective/Objective.style';
import InputComponent from '../../Input/Input.component';
import FilterComponent from '../../Dashboard/DashboardHeader/Filter.component';
import { options } from '../../Dashboard/DashboardHeader/DashboardHeader.component';
import EditComponent from '../../Svgs/Edit.component';
import { Header, Tab, TabContainer } from './SettingContent.style';
import { UserEditModal } from './EditUser.component';

import axios from 'axios';
import { useAuth } from '../../../context/Auth';

import { User } from '../../../api/Api';

const UserComponent = (props) => {
  const { allUser, searchUser, filterByStatus, sortByField, allDepartment } = props;

  const { idToken } = useAuth();

  const [roles] = useState([
    { name: "CEO", value: "CEO" },
    //heads
    { name: "Sales Head", value: "Sales Head" },
    { name: "Plant Head", value: "Plant Head" },
    { name: "Quality Head", value: "Quality Head" },
    { name: "NDP Head", value: "NDP Head" },
    { name: "Assembly Head", value: "Assembly Head" },
    { name: "PU Head", value: "PU Head" },
    { name: "Procurement Head", value: "Procurement Head" },
    { name: "Weld & CNC Head", value: "Weld & CNC Head" },
    { name: "PPC Head", value: "PPC Head" },
    { name: "Maintenance Head", value: "Maintenance Head" },
    { name: "HR Head", value: "HR Head" },
    //managers
    { name: "Export Manager", value: "Export Manager" },
    { name: "BDM", value: "BDM" },
    { name: "Quality Incoming Manager", value: "Quality Incoming Manager" },
    { name: "Quality Process Manager", value: "Quality Process Manager" },
    { name: "Marketing Manager", value: "Marketing Manager" },
    { name: "Cust Service Manager", value: "Cust Service Manager" },
    { name: "IT Manager", value: "IT Manager" },
    { name: "Stores Manager", value: "Stores Manager" },
    //executives
    { name: "Sales Coordination Executive", value: "Sales Coordination Executive" },
    { name: "Finance Executive", value: "Finance Executive" },
    //assistant
    { name: "Executive Assistant", value: "Executive Assistant" },
  ]);

  const [allusers, setAllusers] = useState([]);
  //user to edit
  const [userToEdit, setUserToEdit] = useState(null);
  const [user, setUser] = useState(null);

  const [status, setStatus] = useState({ status: "" });
  const [formData, setFormData] = useState({ sortby: "" });

  //handle field update
  const handleOnChange = (event) => {
    if (!["role", "name", "email"].includes(event.target.name)) {
      user.data.department = event.target.name;
      user.data.departmentid = event.target.value;
      user.data.managerid = event.target.extraValue;
    } else {
      user.data[event.target.name] = event.target.value;
    }
    setUser({ ...user });
  }

  console.log(allDepartment);

  //update user
  //wating for api confirmation
  const updateUser = async () => {
    const data = {
      _id: user.data._id,
      access: user.data.accessright,
      collaborations: user.data.collaborations,
      company_id: user.data.companyid,
      department_id: user.data.departmentid,
      name: user.data.name,
      email: user.data.email,
      gender: user.data.gender,
      head: user.data.head,
      reportingto: user.data.managerid || "", //uuid of reporting user
      role: user.data.role,
      uuid: user.data.uuid
    };

    console.log(data);

    const res = await axios.put("https://s2e.onrender.com/api/v1/users", data, {
      headers: { Authorization: `${idToken}` }
    });

    //insert update user in users state
    allusers[user.index] = user.data;
    setAllusers(allusers);
    setUserToEdit(null);
  }

  //find user to edit
  useEffect(() => {
    //reset state
    if (!userToEdit) {
      setUser(null);
      return;
    }
    //add user in state
    const userIndex = allusers.findIndex(user => user._id === userToEdit);
    if (userIndex < 0) return;
    setUser({ index: userIndex, data: { ...allusers[userIndex] } });
    console.log(allusers[userIndex]);
  }, [userToEdit]);

  useEffect(() => {
    if (allUser.length) setAllusers(allUser);
  }, [allUser])

  useEffect(() => {
    if (formData.sortby) {
      let temp = allUser;
      if (formData.sortby === "manager") {
        temp.sort((a, b) => {
          if (a.reportingto > b.reportingto) return 1;
          return -1;
        })
      }
      else {
        temp.sort((a, b) => {
          if (a[formData.sortby] > b[formData.sortby]) return 1;
          return -1;
        })
      }
      setAllusers(() => [...temp])
    }
  }, [formData]);

  return (
    <div>
      <Header>
        <InputComponent pLeft="15px" search dropdownIcon={false} onChange={(e) => searchUser(e.target.value)} placeholder='Search' />
        <Header className='no-ms'>
          <DashboardInputComponent value={status.status} placeholder='Select Status' >
            <FilterComponent formData={status} setFormData={setStatus} name='status' options={["active", "Un Active"]} />
          </DashboardInputComponent>
          <DashboardInputComponent value={formData.sortby} placeholder='Sort By' >
            <FilterComponent name="sortby" formData={formData} setFormData={setFormData} options={["email", "name", "manager"]} />
          </DashboardInputComponent>
        </Header>
      </Header>

      <TabContainer>
        <Tab main>Name</Tab>
        <Tab main>Email</Tab>
        <Tab main>Manager</Tab>
        <Tab main>Department</Tab>
        <Tab main>Role</Tab>
        {/** 
        <Tab main>Status</Tab>
        */}
        <Tab main sm></Tab>
      </TabContainer>
      <Line />
      {
        allusers.map((item) => {
          return (
            <>
              <TabContainer>

                <Tab>{item.name}</Tab>
                <Tab>{item.email}</Tab>
                <Tab>{item?.reportingto || ""}</Tab>
                <Tab>{item?.department === "Company" ? "CEO":item?.department}</Tab>
                <Tab>{item?.role || "operator"}</Tab>
                {/**
        <Tab>Active</Tab>
         */}
                <Tab sm>
                  <EditComponent onClick={() => setUserToEdit(item._id)} />
                </Tab>
              </TabContainer>
              <Line />
            </>
          )
        })
      }

      <UserEditModal
        closeModal={() => setUserToEdit(null)}
        modal={userToEdit ? true : false}
        user={user?.data}
        handleOnChange={handleOnChange}
        updateUser={updateUser}
        departments={allDepartment?.map(dept => ({ name: "department", label: dept.name, value: dept._id, extraValue: dept.ownerId }))}
        roles={roles}
      >
      </UserEditModal>

    </div >
  );
};

export default UserComponent;
