import styled from 'styled-components';
import { myFlex } from '../../utils/shared.style';

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: ${(props) => (props?.lg ? '25rem' : props?.width || '13rem')};
  padding: 8px 15px;
  border-radius: 4px;
  border: solid 1px #dbdbdb;
  margin-right: 1rem;
  ${myFlex};
  ${(props)=>props.search?`padding-left:25px;`:""}
  flex-grow: 1;
  outline: none;
  color: #999;
  &::-webkit-input-placeholder {
    padding:${({pLeft})=>pLeft?pLeft:"2px"};
    font-style:italic;
  }
  ${({valid})=>valid&&`border-color:red;`}
  
  @media (max-width: 768px) {
    min-width: ${(props) => (props?.lg ? '90%' : props?.width || '13rem')};
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
`;
