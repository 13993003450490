import React from 'react';
import {
  ButtonContainer,
  InfoContainer,
  ObjectiveHeader,
  Text,
  TextContainer,
  PinFeature,PinText
} from './Objective.style';
import ExpandComponent from '../../Svgs/Expand.component';
import UnExpandComponent from '../../Svgs/UnExpand.component';
import DashboardInputComponent from '../DashboardHeader/DashboardInput.component';
import RequestHoverComponent from '../../Svgs/RequestHover.component';
import PinComponent from '../../Svgs/Pin.component';
import FilterComponent from '../DashboardHeader/Filter.component';
/**
 *
 * @param {string} props.headerText HeaderText
 * @param {string} props.placeholder Placeholder for sort by
 * @param {info} props.info Component info
 */

const CommonHeaderComponent = ({name="",options=[], headerText, placeholder,isExpand=true,filter=true, info,modal,setModal,setHover ,onClick,setShow,setPin,setFormData=()=>{},formData={} }) => {
  
  return (
    <ObjectiveHeader headerText={headerText} modal={modal}>
      <TextContainer>
        {(modal&&headerText==="Request")?<RequestHoverComponent disable={true}/>:<Text>{headerText}</Text>}
        
        <InfoContainer info={info}>
          <em>
            <b>i</b>
          </em>
        </InfoContainer>

      </TextContainer>
      {placeholder ? (
        <ButtonContainer>
          {filter&&
          <DashboardInputComponent value={formData[name]} placeholder={placeholder} >
            <FilterComponent name={name} formData={formData} setFormData={setFormData}  options={options}/>
            </DashboardInputComponent>
        }
          {isExpand?
          <span onClick={onClick} style={{ marginLeft: '1rem' }}>
            {!modal?<ExpandComponent className='no-mr' /> : <UnExpandComponent className='no-mr' />}
            
          </span>:<h1 style={{cursor:"pointer"}} onClick={()=>setModal(false)}>✖</h1>}
        </ButtonContainer>
      ) : (
        ''
      )}
      {(headerText==="Request"&&modal)?
      <PinFeature>
        <div onClick={()=>{setPin(true);setShow(true);setModal(true)}} style={{display:"flex",cursor:"pointer",alignItems:"center"}}>
        <PinComponent />
        Pin to Dashboard
        </div>
        <PinText> <span onClick={()=>{setShow(false);setModal();setHover(false)}} style={{fontSize:"22px",cursor:"pointer"}}>×</span> </PinText>
        
      </PinFeature>:""
      }
    </ObjectiveHeader>
  );
};

CommonHeaderComponent.defaultProps={
  setPin:()=>{},
  setShow:()=>{},
  setModal:()=>{},
  setHover:()=>{},
}

export default CommonHeaderComponent;
