import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import UseTab from "../../../hooks/useTab";
import { boxShadow, flexColumn } from "../../../utils/shared.style";
import AllLogsComponent from "../AllLogs/AllLogs.component";
import axios from "axios";
import { checkInHistory } from "../../../api/Api";
import { useAuth } from "../../../context/Auth";
import Loader from "../../Loading/Loader";

const Container = styled.div`
  ${flexColumn};
  padding: 1.5rem;
  ${boxShadow};
  margin: 2rem 0;
`;

const tabs = ["All Logs"];
const tabContent = [AllLogsComponent];

const LogdContainerComponent = (props) => {
  const [checkIn, setCheckIn] = useState([]);
  const departments = useRef([
    { _id: 1, name: "depart 1" },
    { _id: 2, name: "depart 2" },
    { _id: 3, name: "depart 3" },
    { _id: 4, name: "depart 4" },
  ]);
  const users = useRef([
    { _id: 1, name: "Abdullah Khan" },
    { _id: 2, name: "Shaswat" },
    { _id: 3, name: "Jayant" },
    { _id: 4, name: "Ajay" },
  ]);
  const [loading, setLoading] = useState(false);

  const { idToken } = useAuth();
  useEffect(() => {
    setLoading(true);
    axios
      .post(
        checkInHistory,
        { name: "rahul" },
        {
          headers: {
            Authorization: `${idToken}`,
          },
        }
      )
      .then((res) => {
        const checkInData = res.data;
        //create checkin data
        if (checkInData.data?.length) {
          let temp1 = [];
          setLoading(true);

          checkInData.data.forEach((item) => {
            let dt = item.checkin_data || item["checkin-data"];

            for (const [key, value] of Object.entries(dt)) {
              let okr = checkInData.okrs.filter((item) => item.id === key);
              if (okr.length) {
                temp1.push({
                  title: okr[0].objective,
                  checkin: value,
                  time:
                    (new Date().getTime() -
                      new Date(item.timestamp).getTime()) /
                    (1000 * 60 * 60 * 24),
                });
              }
            }
          });

          setCheckIn(temp1.reverse());
          setLoading(false);
        }
        loading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const { showTabs } = UseTab(0, tabs, tabContent);
  return (
    <Container>
      {loading ? (
        <Loader type="spin" color="cyan" />
      ) : (
        showTabs(checkIn,departments.current,users.current)
      )}
    </Container>
  );
};

export default LogdContainerComponent;
