import React, { useState } from "react";
import { DepartmentText } from "../Objective/Objective.style";
import { SlideIndicator, Slider } from "./Collaboration.style";
import PopUpCollaboration from "./PopupCollaboration";

const toolTipActive = {
  position: "absolute",
  backgroundColor: "#ffffff",
  whiteSpace: "nowrap",
  padding: "5px 10px",
  fontSize: "14px",
  display: "flex",
  boxShadow: "2px 2px 10px 2px rgba(0,0,0,0.08)",
  top: "-50px",
};

const hideToolTip = {
  display: "none",
};

export const Bar = ({
  index,
  collaboratorIndex,
  departmentName,
  collaborations,
  toolTip,
  updateToolTip,
}) => {
  return (
    <div
      onClick={() => updateToolTip(index)}
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Slider style={{ maxHeight: "90px", margin: "0px 25px" }} key={index}>
        <SlideIndicator height={`${collaboratorIndex * 100}%`} />
      </Slider>
      <DepartmentText
        style={{
          marginTop: "5px",
          marginLeft: `${index !== 0 ? "22px" : "0px"}`,
        }}
      >
        {departmentName}
      </DepartmentText>
      <div style={toolTip ? toolTipActive : hideToolTip}>
        <PopUpCollaboration
          department={departmentName}
          collaborations={collaborations}
        />
      </div>
    </div>
  );
};
