const theme = {
  backgroundColor: '#ffffff',

  greyLight1: '#f2f3f7',
  greyLight2: '#eaeaea',
  greyLight3: '#f2f2f2',

  greyDark1: '#dbdbdb',
  greyDark2: '#c4c4c4',
  greyDark3: '#b7b7b7',
  greyDark4: '#909090',
  greyLight5: '#cccccc',

  blackPrimary: '#000',
  blackSecondary: '#1a1a1a',
  blackTertiary: '#1f1f1f',

  blueDarkPrimary: '#003d67',
  blueDarkSecondary: '#045680',
  blueDarkTertiary: '#003550',
  blueDark2: '#003f5f',
  blueLightPrimary: '#44c1dc',
  blueLighhtSecondary: '#44c0dc',
  blueLightTertiary: '#00b8e0',

  redPrimary: '#f67575',
  redSecondary: '#e95252',

  orangePrimary: '#f7931e',

  brownPrimary: '#bc4e4e',

  greenPrimary: '#1db535',
  greenSecondary: '#048c1a',
  greenTertiary: '#099e60',
};

export default theme;
