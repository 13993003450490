import React from 'react';

const AddComponent = (props) => {
  return (
    <svg
      width='10'
      height='9'
      viewBox='0 0 10 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.3418 5.15039H0.84375V3.58594H4.3418V0H5.92383V3.58594H9.43066V5.15039H5.92383V8.71875H4.3418V5.15039Z'
        fill='white'
      />
    </svg>
  );
};

export default AddComponent;
