import React, { useState, useEffect } from 'react';

import ProgressBar from './ProgressBar';
import DropdownComponent from '../../../Svgs/Dropdown.component';
import GoComponent from '../../../Svgs/Go.component';
import ListDropdownComponent from '../../../Svgs/ListDropdown.component';
import WarningComponent from '../../../Svgs/Warning.component';
import { ProgressBarContainer } from './Tab.style';
import { ProgressText } from './Tab.style';
import { ProgressPercentage } from './Tab.style';
import { Link } from 'react-router-dom';
import KeyRes from '../../../Svgs/KeyRes.component';
import { KeyResults } from '../Okr.style';
import { Line } from '../../Objective/Objective.style';
import HoverList from './HoverList';
import {
  InnerTabContainer,
  Tab,
  PanelHeader,
  PanelHeaderv2,
  IconWrapper,
  DropDownContainer,
  WarningContainer,
  Icon,
} from './Tab.style';

/**
 *
 * @param {JSX.Element} props.children Inner Tab dropdown
 * @param {object} props.okr Array of Okr data/feedback
 */

const getColor = (status) => {
  if (status === "R")
    return '#e95252';
  else if (status === "C")
    return '#00b8e0'
  else if (status === "S")
    return '#b7b7b7'
  else if (status === "T")
    return '#099e60';
  else
    return '#b7b7b7';

}
const getProgress = (status) => {
  if (status === "R")
    return "At Risk";
  else if (status === "S")
    return "Not Started"
  else if (status === "T")
    return "on Track";
  else if (status === "C")
    return "Completed"
  else
    return 0;
}

const InnerTab = ({ editable, onEdit, children, type = "", okr, id = "", from = "", setCheckin = () => { }, onGoClick = () => { }, showType }) => {
  const [open, setOpen] = useState(false);

  const [startDate, startMonth, startYear] = [new Date(okr.timeframe ? okr.timeframe[0] : new Date())?.getDate(), new Date(okr.timeframe ? okr.timeframe[0] : new Date())?.getMonth() + 1, new Date(okr.timeframe ? okr.timeframe[0] : new Date())?.getFullYear()]
  const [endDate, endMonth, endYear] = [new Date(okr.timeframe ? okr.timeframe[1] : new Date())?.getDate(), new Date(okr.timeframe ? okr.timeframe[1] : new Date())?.getMonth() + 1, new Date(okr.timeframe ? okr.timeframe[1] : new Date())?.getFullYear()]

  const [hoverList, setHoverList] = useState(false);

  return (
    <Tab col>
      <Tab>
        <PanelHeader lg>
          <Link style={{
            wordBreak: "break-word",
            padding: "1rem 0",
            display: "flex",
            width: "95%",
            paddingRight: "5%",
            alignItems: "center", textDecoration: "none", color: "black"
          }} to={`/okr/${id}`}>
            <DropDownContainer onClick={() => setOpen((state) => !state)}>
              {/* Dropdown Icon */}
              <ListDropdownComponent
                style={{
                  margin: 0,
                  transform: `rotate(${open ? '0deg' : '-90deg'})`,
                }}
              />
            </DropDownContainer>
            {new Date(okr.timeframe ? okr.timeframe[1] : Date.now()) < new Date() ? (
              <WarningContainer>
                <WarningComponent className='no-mr' />
              </WarningContainer>) : ""}

            {/* OKR */}
            {okr?.okr || okr?.title || `No data`}
          </Link>
        </PanelHeader>

        {/* Owner */}
        {
          showType && <PanelHeader sm>{okr.type}</PanelHeader>
        }

        <PanelHeader sm>{okr?.owner || okr?.username || 'No data'}</PanelHeader>

        {/* Time */}
        <PanelHeader style={{ marginRight: "25px" }} >{okr?.time || `${startDate}-${startMonth}-${startYear.toString().slice(2, 4)}  to  ${endDate}-${endMonth}-${endYear.toString().slice(2, 4)}` || 'No data'}</PanelHeader>

        {/* Progress Status */}
        <PanelHeader lg>
          <ProgressBarContainer>
            <ProgressText>{getProgress(okr?.status) + `| ${okr?.progress || 0}%` || `On Track | ${okr?.progress || 0}%`}</ProgressText>
            <ProgressPercentage width={`${okr?.progress}%` || "0%"} status={okr?.status} bg={getColor(okr?.status)} />
          </ProgressBarContainer>
          {/* <ProgressBar width={okr?.progress||40} /> */}
        </PanelHeader>
        <PanelHeader sm>{getProgress(okr.status)}</PanelHeader>
        {/* {okr?.status ? <PanelHeader>{okr.status}</PanelHeader> : ''} */}

        {/* Icons */}
        <Icon>
          <div onClick={() => { onGoClick(type, okr._id); setCheckin(true) }}>
            <GoComponent style={{ marginRight: '0.5rem' }} />
          </div>
          {editable &&
            (<div style={{ position: 'relative' }} onClick={() => setHoverList(!hoverList)}>
              <DropdownComponent style={{ marginRight: '5px' }} />
              <DropdownComponent style={{ marginRight: '5px' }} />
              <DropdownComponent />
              {(editable && hoverList) && <HoverList okrId={okr._id} onEdit={onEdit} />}
            </div>)
          }
        </Icon>
      </Tab>
      {open ? children : ''}
    </Tab>
  );
};

/**
 * @param {Array} props.okrs array of okrs
 */
const InnerTabComponent = ({ editable, onEdit, type = "", okrs, okrData = [], setCheckin = () => { }, from = "", status = true, onClicked = () => { }, showType = false, title = "OKR" }) => {
  console.log("Logging OKR Data");
  return (
    <InnerTabContainer>
      {/* OKR HEADER  */}

      <Tab>
        <PanelHeader fontWeight="800" lg>{title}</PanelHeader>
        {
          showType && <PanelHeader fontWeight="800" sm>Type</PanelHeader>
        }
        <PanelHeader fontWeight="800" sm>Owner</PanelHeader>

        <PanelHeader style={{ marginRight: "25px" }} fontWeight="800" >Time</PanelHeader>
        <PanelHeader fontWeight="800" lg>Progress</PanelHeader>
        {status ? <PanelHeader fontWeight="800" sm>Status</PanelHeader> : ''}
        <PanelHeader sm></PanelHeader>
      </Tab>

      {/* OKR LIST */}
      {
        okrData?.length &&
        okrData.map((okr, id) => {
          return (
            <InnerTab editable={editable} onEdit={onEdit} type={type} showType={showType} okr={okr} id={okr._id} key={okr._id} setCheckin={setCheckin} onGoClick={onClicked}>
              {/* Dropdown */}
              {/* hello; */}
              <Tab style={{  marginLeft: "15px" }}>
                <PanelHeaderv2 fontWeight="800" >Title</PanelHeaderv2>
                {/* <PanelHeader fontWeight="800" sm>Owner</PanelHeader> */}
                <PanelHeader fontWeight="800" >Time</PanelHeader>
                {/* <PanelHeader fontWeight="800" lg>Progress</PanelHeader> */}
                <PanelHeader fontWeight="800" sm>Status</PanelHeader>
                {/* <PanelHeader sm></PanelHeader> */}
              </Tab>
              {from === "singleOkr" ? okr?.mileStones?.length && okr.mileStones.map(item => {
                return (
                  <Tab style={{ marginLeft: "15px" }}>

                    <PanelHeaderv2 style={{wordBreak:"keep-all"}}>
                      {/* OKR */}
                      <IconWrapper>
                      <KeyRes style={{marginRight:"10px"}} />
                      </IconWrapper><p>{item?.title || `No data`}</p>
                    </PanelHeaderv2>
                    <PanelHeader >{`${new Date(item?.timeframe[0]).getDate()}-${new Date(item?.timeframe[0]).getMonth() + 1}-${new Date(item?.timeframe[0]).getFullYear().toString().slice(2, 4)}  to  ${new Date(item?.timeframe[1]).getDate()}-${new Date(item?.timeframe[1]).getMonth() + 1}-${new Date(item?.timeframe[1]).getFullYear().toString().slice(2, 4)}`}</PanelHeader>
                    <PanelHeader style={{ color: `${getColor(item.status)}` }} sm>{getProgress(item.status)}</PanelHeader>
                    {/* {okr?.status ? <PanelHeader>{okr.status}</PanelHeader> : ''} */}
                    {/* Icons */}
                  </Tab>
                )
              }) : ""}
            </InnerTab>
          )
        })
      }
      {/* {okrs?.length &&
        okrs.map((okr) => (
          <InnerTab okr={okr} key={okr.owner} onGoClick={onClick}>
            Dropdown
          </InnerTab>
        ))} */}
    </InnerTabContainer>
  );
};

export default InnerTabComponent;
