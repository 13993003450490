import React from 'react';
import TickComponent from '../../Svgs/Tick.component';
import WarningComponent from '../../Svgs/Warning.component';
import { Circle } from './Department.style';

const StatusIconComponent = (props) => {
  return (
    <Circle bg={props?.bg} className={props?.className || ''}>
      {props.success ? <TickComponent /> : <WarningComponent />}
    </Circle>
  );
};

export default StatusIconComponent;
