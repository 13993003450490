import React, { useState, useEffect, useContext } from "react";

import DashboardContentComponent from "../components/Dashboard/DashboardContent/DashboardContent.component";
import DashboardHeader from "../components/Dashboard/DashboardHeader/DashboardHeader.component";
import HeaderComponent from "../components/Header/Header.component";
import MainContainerComponent from "../components/Layouts/MainContainer/MainContainer.component";
import NewObjectiveComponent from "../components/MyOkr/NewObjective/NewObjective.component";
import axios from "axios";
import { useAuth } from "../context/Auth";
import { ModalContext } from "../context/Modal.context";
import { company, okr, User, collabortion } from "../api/Api";
import PopUpObjectives from "../components/Dashboard/Objective/PopUpObjectives";
import Loader from "../components/Loading/Loader";
import { toast } from "react-toastify";
// import { User } from '../api/Api';
/**
 * Header Sidebar Dashboard Components
 *
 */
const Dashboard = () => {
  const [modal, setModal] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [render, setRender] = useState(false);
  // const [okr, setOkr] = useState([]);
  const [departments, setDepartments] = useState([]);
  const { datefilter } = useContext(ModalContext);
  const [collab, setCollab] = useState([]);
  const [alignments, setAlignments] = useState([]);
  const [quarter, setQuarter] = useState("");
  const [users, setUsers] = useState(0);
  const [objectives, setObjectives] = useState(0);
  const [keyResults, setKeyResults] = useState(0);
  const [mileStone, setMileStones] = useState(0);
  const [okrData, setOkrData] = useState([]);
  const [filterOkr, setFilterOkr] = useState([]);
  const [overAllChartData, setOverAllChartData] = useState({});

  const [remainingCharData, setRemainingChartData] = useState({});
  const [departmentChartData, setDepartmentChartData] = useState({});
  const [individualChartData, setIndividualChartData] = useState({});
  const [collabChartData, setCollabChartData] = useState({});
  const [collabIdx, setCollabIdx] = useState(0.0);
  const { idToken, uid, filterByDepartment } = useAuth();
  const [allUser, setAlluser] = useState([]);
  const [allUsersData, setAllusers] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [percent, setPercent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allDepartments, setAllDepartments] = useState([]);

  useEffect(() => {
    if (uid) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [uid]);

  //reset filter on modal close
  useEffect(() => {
    if (!modal) {
      filterUser("all");
    }
  }, [modal]);

  useEffect(() => {
    if (allUsersData.length && okrData?.okrs?.length) {
      let temp = 0;
      allUsersData.forEach((user) => {
        let isOkrs = okrData.okrs.find((okr) => okr.createdby === user.uuid);
        if (isOkrs) {
          temp++;
        }
      });
      let per = parseFloat((temp / allUsersData.length) * 100).toFixed(2);
      localStorage.setItem("companyPercent", per);
    }
  }, [allUsersData, okrData]);

  const handleOnClick = (id, filter, name) => {
    console.log(id, filter, name);
    let data = okrData.okrs.filter(
      (item) => item.departmentid === id && item.status === filter
    );
    setPopUpData({ data: data, filter, name: name || "No Name" });
    setPopUp(true);
  };

  const parseOverAllData = (okrdata) => {
    const ovrData = [0, 0, 0, 0];

    if (okrdata.okrs != null) {
      okrdata.okrs.forEach((okr) => {
        if (okr.status === "S") ovrData[0] += 1;
        else if (okr.status === "T") ovrData[1] += 1;
        else if (okr.status === "R") ovrData[2] += 1;
        else ovrData[3] += 1;
      });
    }
    return ovrData;
  };

  useEffect(() => {
    if (datefilter.startDate.length && datefilter.endDate.length) {
      console.log(datefilter);
      let startDate = new Date(
        datefilter.startDate[2],
        datefilter.startDate[1],
        datefilter.startDate[0]
      );
      let endDate = new Date(
        datefilter.endDate[2],
        datefilter.endDate[1],
        datefilter.endDate[0]
      );

      let okr = okrData.okrs.filter(
        (item) =>
          startDate <= new Date(item.createdon) &&
          endDate >= new Date(item.createdon)
      );
      let dep = okrData.departments;
      setFilterOkr({ departments: dep, okrs: [...okr] });
      dep.forEach((dept) => {
        dept.notStarted = 0;
        dept.Risk = 0;
        dept.onTrack = 0;
        dept.Completed = 0;
      });
      let okrs = { okrs: [...okr] };

      let data = findStatusArrays(okrs, dep);
      console.log(data);

      setDepartmentChartData({
        labels:
          data != null
            ? data.map((dept) => {
                return { name: dept.name, deptId: dept._id };
              })
            : [],
        datasets: [
          {
            label: "Not Started",
            data: data != null ? data.map((dept) => dept.notStarted) : [],
            backgroundColor: "#FFB200",
            hoverBorderWidth: 10,
          },

          {
            label: "On Track",
            data: data != null ? data.map((dept) => dept.onTrack) : [],
            backgroundColor: "#02A0FC",
            hoverBorderWidth: 10,
          },

          {
            label: "At Risk",
            data: data != null ? data.map((dept) => dept.Risk) : [],
            backgroundColor: "#FF3A29",
            hoverBorderWidth: 10,
          },

          {
            label: "Completed",
            data: data != null ? data.map((dept) => dept.Completed) : [],
            backgroundColor: "#34B53A",
            hoverBorderWidth: 10,
          },
        ],
      });

      let dt = parseOverAllData(okrs);

      setOverAllChartData({
        labels: ["Not Started", "On Track", "At Risk", "Completed"],
        datasets: [
          {
            label: "Over all OKR Progress",
            data: dt,
            backgroundColor: ["#FFB200", "#02A0FC", "#FF3A29", "#34B53A"],
          },
        ],
      });
    }
  }, [datefilter]);

  const findStatusArrays = (okrdata, depts) => {
    let deptMap = [...depts];
    console.log(okrdata);
    deptMap.forEach((dept) => {
      dept.totalCollabs = 0;
      dept.accCollabs = 0;
    });
    okrdata.okrs.forEach((okr) => {
      if (okr.status === "S") {
        deptMap.forEach((dept) => {
          if (dept._id === okr.departmentid) {
            dept.notStarted += 1;
          }
        });
      } else if (okr.status === "R") {
        deptMap.forEach((dept) => {
          if (dept._id === okr.departmentid) {
            dept.Risk += 1;
          }
        });
      } else if (okr.status === "T") {
        deptMap.forEach((dept) => {
          if (dept._id === okr.departmentid) {
            dept.onTrack += 1;
          }
        });
      } else {
        deptMap.forEach((dept) => {
          if (dept._id === okr.departmentid) dept.Completed += 1;
        });
      }
    });
    setDepartments(deptMap);
    return deptMap;
  };

  const chart = (overAllData, deptsMap, remArr) => {
    setOverAllChartData({
      labels: ["Not Started", "On Track", "At Risk", "Completed"],
      datasets: [
        {
          label: "Over all OKR Progress",
          data: overAllData,
          backgroundColor: ["#FFB200", "#02A0FC", "#FF3A29", "#34B53A"],
        },
      ],
    });
    setRemainingChartData({
      labels: ["Remaining", "Achieved"],
      datasets: [
        {
          label: "Remaining vs Achieved",
          data: remArr != null ? remArr : [],
          backgroundColor: ["#FF3A29", "#02A0FC"],
          borderWidth: 0,
          hoverBorderWidth: 0,
        },
      ],
    });
    setDepartmentChartData({
      labels:
        deptsMap != null
          ? deptsMap.map((dept) => {
              return { name: dept.name, deptId: dept._id };
            })
          : [],
      datasets: [
        {
          label: "Not Started",
          data: deptsMap != null ? deptsMap.map((dept) => dept.notStarted) : [],
          backgroundColor: "#FFB200",
          hoverBorderWidth: 10,
        },
        {
          label: "On Track",
          data: deptsMap != null ? deptsMap.map((dept) => dept.onTrack) : [],
          backgroundColor: "#02A0FC",
          hoverBorderWidth: 10,
        },

        {
          label: "At Risk",
          data: deptsMap != null ? deptsMap.map((dept) => dept.Risk) : [],
          backgroundColor: "#FF3A29",
          hoverBorderWidth: 10,
        },

        {
          label: "Completed",
          data: deptsMap != null ? deptsMap.map((dept) => dept.Completed) : [],
          backgroundColor: "#34B53A",
          hoverBorderWidth: 10,
        },
      ],
    });

    setIndividualChartData({
      labels: deptsMap != null ? deptsMap.map((dept) => dept.name) : [],
      datasets: [
        {
          label: "Number of Individual OKRs",
          data:
            deptsMap != null
              ? deptsMap.map(
                  (dept) =>
                    dept.notStarted + dept.Risk + dept.Completed + dept.onTrack
                )
              : [],
          backgroundColor: "#4339F2",
        },
      ],
    });
  };
  useEffect(() => {
    const getData = async () => {
      setLoadingData(true);
      console.log("GET COMPANY");
      await axios
        .get(company.getCompany, {
          headers: { Authorization: `${idToken}`, uuid: uid },
        })
        .then((res) => {
          setUsers(res.data.company.currentusers);
          setObjectives(res.data.company.totalokrs);
          setKeyResults(res.data.company.totalkrs);
          setMileStones(res.data.company.totalmilestones);
        })
        .catch((err) => console.log(err));
      let deptsMap, okrs;
      await axios
        .post(
          okr.viewAllOkr,
          {
            status: ["S", "R", "T", "C"],
          },
          {
            headers: {
              Authorization: `${idToken}`,
              uuid: uid,
            },
          }
        )
        .then(async (res) => {
          const okrdata = res.data;
          // window.location.reload();

          let depts = okrdata.departments;
          setAllDepartments(depts);
          deptsMap = okrdata.departments;
          okrs = okrdata.okrs;
          depts.forEach((dept) => {
            dept.notStarted = 0;
            dept.Risk = 0;
            dept.onTrack = 0;
            dept.Completed = 0;
          });
          let remArr = [0, 0];
          okrdata.okrs.forEach((okr) => {
            if (okr.status === "C") {
              remArr[1] += 1;
            } else {
              remArr[0] += 1;
            }
          });
          const overAllData = parseOverAllData(okrdata);
          setOkrData(okrdata);
          setFilterOkr(okrdata);
          let deptMap = findStatusArrays(okrdata, depts);
          chart(overAllData, deptMap, remArr);

          //
          await axios
            .post(
              collabortion.viewalignmentReq,
              { name: "rahul" },
              {
                headers: {
                  Authorization: `${idToken}`,
                  uuid: uid,
                },
              }
            )
            .then((res) => {
              // console.log(res);
              console.log(res.data);
              setAlignments(res.data.collabs);
            })
            .catch((err) => {
              console.log(err.message);
            });
          //

          await axios
            .post(
              collabortion.getCollaborations,
              { name: "rahul" },
              {
                headers: {
                  Authorization: `${idToken}`,
                  uuid: uid,
                },
              }
            )
            .then((res) => {
              // console.log(res);
              const collabs = res.data.collabs !== null ? res.data.collabs : [];
              setCollab(collabs);

              let accCollabs = 0;
              collabs.forEach((collab) => {
                if (collab.status === "A") {
                  accCollabs += 1;
                }
              });

              const colIdx = accCollabs / collabs.length;

              setCollabIdx(colIdx);
              deptsMap = setCollabDeptList(collabs, deptsMap, okrs || []);

              setCollabChartData({
                labels:
                  deptsMap != null ? deptsMap.map((dept) => dept.name) : [],
                datasets: [
                  {
                    label: "Collaboration Index",
                    data:
                      deptsMap !== null
                        ? deptsMap?.map(
                            (dept) => dept.accCollabs / dept.totalCollabs
                          )
                        : [],
                    fill: false,
                    backgroundColor: "#5F8B95",
                    borderColor: "#5F8B95",
                    radius: 5,
                  },
                ],
              });
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));

      await axios
        .get(User.getAllUser, {
          headers: {
            Authorization: `${idToken}`,
            uuid: `${uid}`,
          },
        })
        .then((res) => {
          let dt = res.data.users;
          setAllusers(dt);
          setAlluser(dt);
        })
        .catch((err) => console.log(err));
      setLoadingData(false);
    };
    getData()
      .then(() => {
        /*const deptsMap = setCollabDeptList(
        collab,
        okrData?.departments || [],
        okrData?.okrs || []
      );

      setDepartments([...deptsMap]);

      setCollabChartData({
        labels: deptsMap != null ? deptsMap.map((dept) => dept.name) : [],
        datasets: [
          {
            label: "Collaboration Index",
            data:
              deptsMap !== null
                ? deptsMap?.map((dept) => dept.accCollabs / dept.totalCollabs)
                : [],
            fill: false,
            backgroundColor: "#5F8B95",
            borderColor: "#5F8B95",
            radius: 5,
          },
        ],
      });*/
      })
      .catch((err) => console.log(err.message));
  }, [render]);

  const setCollabDeptList = (collabs, deptsMap, okrs) => {
    let deptMap = deptsMap;

    collabs.forEach((collab) => {
      const okr = okrs.find((okr) => okr._id === collab.objID);

      if (okr) {
        //find deparment from okr
        const deptIndex = deptMap.findIndex(
          (dept) => dept._id === collab.userdetails2.departmentid
        );
        if (deptIndex < 0) return;
        deptMap[deptIndex].totalCollabs += 1;
        if (collab.status === "A") {
          deptMap[deptIndex].accCollabs += 1;
        }
      }
    });
    setDepartments([...deptsMap]);
    return deptMap;
  };
  function filterUser(dptId) {
    if (dptId === "all") {
      setAlluser([...allUsersData]);
    } else
      setAlluser(allUsersData.filter((item) => item.departmentid === dptId));
  }

  const FilterData = (mon, year) => {
    let startDate = new Date(year, mon - 3, 1);
    let endDate = new Date(year, mon - 1, 30);

    let okr = isNaN(mon)
      ? okrData.okrs
      : okrData.okrs.filter(
          (item) =>
            startDate <= new Date(item.createdon) &&
            endDate >= new Date(item.createdon)
        );
    let dep = okrData.departments;
    setFilterOkr({ departments: dep, okrs: [...okr] });
    dep.forEach((dept) => {
      dept.notStarted = 0;
      dept.Risk = 0;
      dept.onTrack = 0;
      dept.Completed = 0;
    });
    let okrs = { okrs: [...okr] };

    let data = findStatusArrays(okrs, dep);

    setDepartmentChartData({
      labels:
        data != null
          ? data.map((dept) => {
              return { name: dept.name, deptId: dept._id };
            })
          : [],
      datasets: [
        {
          label: "Not Started",
          data: data != null ? data.map((dept) => dept.notStarted) : [],
          backgroundColor: "#FFB200",
          hoverBorderWidth: 10,
        },

        {
          label: "On Track",
          data: data != null ? data.map((dept) => dept.onTrack) : [],
          backgroundColor: "#02A0FC",
          hoverBorderWidth: 10,
        },

        {
          label: "At Risk",
          data: data != null ? data.map((dept) => dept.Risk) : [],
          backgroundColor: "#FF3A29",
          hoverBorderWidth: 10,
        },

        {
          label: "Completed",
          data: data != null ? data.map((dept) => dept.Completed) : [],
          backgroundColor: "#34B53A",
          hoverBorderWidth: 10,
        },
      ],
    });

    let dt = parseOverAllData(okrs);

    setOverAllChartData({
      labels: ["Not Started", "On Track", "At Risk", "Completed"],
      datasets: [
        {
          label: "Over all OKR Progress",
          data: dt,
          backgroundColor: ["#FFB200", "#02A0FC", "#FF3A29", "#34B53A"],
        },
      ],
    });
  };
  // console.log(startDate,endDate);
  useEffect(() => {
    if (quarter) {
      console.log("Updating Quarter");
      console.log(quarter);
      let mon = parseInt(quarter.ViewBy[1]);
      let year = parseInt(quarter.ViewBy.split(" ")[1]);
      console.log(mon);
      FilterData(3 * mon, year);
    }
  }, [quarter]);
  const trigger = () => {
    toast.success("Okr Created", { autoClose: 3000 });
  };

  return (
    <>
      <main>
        <HeaderComponent
          setDepartmentId={setDepartmentId}
          setCompanyId={setCompanyId}
        />

        <MainContainerComponent>
          <DashboardHeader
            headerText="Dashboard"
            showObjectiveModal={() => setModal((state) => !state)}
            departments={departments}
            allUser={allUser}
            quarter={quarter}
            setQuarter={setQuarter}
          />
          {loadingData ? (
            <Loader type="spin" color="cyan" />
          ) : (
            <DashboardContentComponent
              departmentChartData={departmentChartData}
              collabChartData={collabChartData}
              overAllChartData={overAllChartData}
              collabIdx={collabIdx}
              render={render}
              setRender={setRender}
              departmentId={departmentId}
              handleOnClick={handleOnClick}
              companyId={companyId}
              allCollab={collab}
              alignments={alignments}
              okrData={filterOkr}
              quarter={quarter}
              setQuarter={setQuarter}
              allUser={allUser} //pass in update objective form
              filterUser={filterUser} //pass in update objective form
            />
          )}
        </MainContainerComponent>
      </main>

      {modal ? (
        <NewObjectiveComponent
          allUser={allUser}
          filterUser={filterUser}
          departments={departments}
          setModal={() => setModal((state) => !state)}
          okrData={filterOkr}
          render={render}
          setRender={setRender}
          trigger={trigger}
        />
      ) : (
        ""
      )}
      {popUp ? (
        <PopUpObjectives setPopUp={setPopUp} objectiveData={popUpData} />
      ) : (
        ""
      )}
    </>
  );
};

export default Dashboard;
