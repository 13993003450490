import React from "react";
import { IndexText, Text } from "./Collaboration.style";
import { PieChart, Pie, Label, Cell } from "recharts";
import { useState } from "react";
import { useEffect } from "react";
import { okr } from "../../../api/Api";
import { useRef } from "react";

export const OverallCollabDetails = ({
  collabIdx,
  receivedCollab,
  acceptedCollab,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "auto 0",
        }}
        className="details"
      >
        <div className="detail">
          <IndexText>{parseFloat(collabIdx).toFixed(2)}</IndexText>
          <Text style={{ marginBottom: "0px", fontWeight: "bold" }}>
            Overall Index
          </Text>
        </div>
        <div className="detail">
          <IndexText>{receivedCollab}</IndexText>
          <Text style={{ marginBottom: "0px", fontWeight: "bold" }}>
            Received Collaborations
          </Text>
        </div>
        <div className="detail">
          <IndexText>{acceptedCollab}</IndexText>
          <Text style={{ marginBottom: "0px", fontWeight: "bold" }}>
            Accepted Collaborations
          </Text>
        </div>
      </div>
    </>
  );
};

const COLORS = ["#099e60", "#b7b7b7"];
export const DepartmentCollabDetails = ({ allCollab, okrs }) => {
  const [collabs, setCollabs] = useState([]);
  const [acceptedCollab, setAcceptedCollab] = useState(0);
  const [receivedCollab, setReceivedCollab] = useState(0);

  useEffect(() => {
    if(!okrs.length || !allCollab.length) return;
    var tempcollabs = [];
    okrs.forEach((okr) => {
      tempcollabs = [
        ...tempcollabs,
        ...allCollab.filter((clb) => clb.userdetails2.departmentid === okr.departmentid),
      ].filter((value, index, self) =>
        index === self.findIndex((t) => (
          t._id === value._id
        ))
      );
    });

    setCollabs([...tempcollabs]);
  }, [okrs, allCollab]);

  useEffect(() => {
    console.log("Updated collab - ", collabs);
    setReceivedCollab(collabs.length);
    setAcceptedCollab(
      collabs.filter((collab) => collab.status === "A").length
    );
  }, [collabs]);

  const data = [
    { type: "accepted", collaborations: acceptedCollab },
    { type: "pending", collaborations: receivedCollab - acceptedCollab },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "auto 0",
        }}
        className="details"
      >
        <div className="detail" style={{ margin: "auto" }}>
          <IndexText>{receivedCollab}</IndexText>
          <Text style={{ marginBottom: "0px", fontWeight: "bold" }}>
            Received Collaborations
          </Text>
        </div>

        <div className="details">
          <PieChart
            cx="0%"
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            cy="0%"
            width={200}
            height={150}
          >
            <Pie
              data={data}
              dataKey="collaborations"
              outerRadius={"80%"}
              innerRadius="60%"
              fill="grenn"
            >
              {data.map((data, index) => (
                <Cell fill={COLORS[index]} />
              ))}
              <Label
                width={50}
                style={{ fontWeight: "500", fontSize: "2.5rem" }}
                position="center"
              >
                {parseFloat(acceptedCollab / receivedCollab).toFixed(2) || 0}
              </Label>
            </Pie>
          </PieChart>
          <Text style={{ marginBottom: "0px", fontWeight: "bold" }}>
            Overall Index
          </Text>
        </div>

        <div className="detail" style={{ margin: "auto" }}>
          <IndexText>{acceptedCollab}</IndexText>
          <Text style={{ marginBottom: "0px", fontWeight: "bold" }}>
            Accepted Collaborations
          </Text>
        </div>
      </div>
    </>
  );
};
