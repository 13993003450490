import styled from 'styled-components';
import { flexCenter, flexColumn, myFlex } from '../../../utils/shared.style';
import { sharedBox } from '../Objective/Objective.style';

export const CollaborationContainer = styled.div`
  ${sharedBox};
  min-width: 30%;
  // max-width:663px;
  min-height:255px;
  max-height:255px;
  box-sizing:border-box;
  margin-right:14px;
  @media (max-width:768px)
  {
    margin-right:0px;
  }
`;

export const IndexText = styled.p`
  text-align: center;
  font-size: 3.2rem;
  margin: 0.5rem 0 0rem 0;
`;

export const Text = styled.p`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  color: ${(props) => props.theme.greyDark4};
`;

export const Slider = styled.div`
  height: 11rem;
  width: 1.5rem;
  border-radius: 25px;
  background-color: ${(props) => props.theme.greyLight2};
  ${myFlex};
  align-items: flex-end;
`;

export const SlideIndicator = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  position: relative;
  background-color: ${(props) => props.theme.blueDarkPrimary};

  &::after {
    content: '';
    position: absolute;
    top: -20px;
    left: -4px;
    border-radius: 50%;
    width: 19.6px;
    height: 18px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 2px #0d334e;
    background-color: #fff;
  }
`;

export const Circle = styled.div`
  position: relative;
  ${flexColumn};
  ${flexCenter};
  margin: 1rem 0;
  text-align: center;
  width: 10rem;
  height: 10rem;
  padding: 1rem;
  color: #5a5a5a;

  border-radius: 100%;
  background: ${(
    props
  ) => `radial-gradient(circle at center, white 55%, transparent 55.5%),
    linear-gradient(to right, ${props.theme.blueLightTertiary} ${
    props?.percentage || '70%'
  }, ${props.theme.greyLight2} 0)`};

  & h1 {
    font-size: 2rem;
  }
`;

export const CircleBorder = styled.div`
  position: relative;
  text-align: center;
  width: 11rem;
  height: 11rem;
  margin-left: 30%;
  border-radius: 100%;
  /* background-color: #e53b3b; */
  background: linear-gradient(270deg, black 50%, transparent 50%),
    linear-gradient(0deg, black 50%, lightgray 50%);
`;
