import React,{useContext,useState,createContext,useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import {auth} from '../firebase';

import {createUserWithEmailAndPassword,signInWithEmailAndPassword,signOut} from '@firebase/auth';
import { ToastContainer } from 'react-toastify';

const AuthContext=createContext();

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({children}){
    const [loading,setLoading]=useState(true);
    const history=useHistory();
    const [currentUser,setCurrentUser]=useState("");
    const [idToken,setIdToken]=useState("");
    const [admin,setAdmin]=useState(false);
    // const [loading,setLoading]=useState(true);
    const [filterByDepartment,setFilterByDepartment]=useState({department:""});
    const [filterByUser,setFilterByUser]=useState({user:""})
    const [filterBy,setFilterBy]=useState("");
    const [manager,setManager]=useState(false);
    const [departmentId,setDepartment_Id]=useState("");
    const [email,setEmail]=useState("");
    const [processing,setProcessing]=useState(false);
    
    
    const [uid,setUID]=useState("");
    useEffect(()=>{
        const unsubscribe=auth.onAuthStateChanged((user)=>{
            
            setCurrentUser(user);
            if(!user)
            {
               
            history.push('/login');
            
            }
            setUID(user?.uid);
            
            user?.getIdToken(false).then((idToken)=>{setIdToken(idToken)}).catch(err=>console.log(err));
           
            
        })
        
       
        return unsubscribe;
    },[])
    useEffect(()=>{

        if(uid&&idToken&&!processing)
        {
           
            setLoading(false);
            // history.push('/');
            console.log(uid);
        
           
        }
        
    },[uid,idToken,processing])
    
   

    

    function signOutUser(){
        return signOut(auth).then(()=>{
            window.location.reload();
            history.push('/login');
        //     setCurrentUser("");
        //    setUID("");
        //    setIdToken("");
          
        //    setLoading(true);

        }).catch((err)=>{
            console.log(err);
            alert("err occured");
        })

    }
    
    const signIn=(email,password)=>{
        
         signInWithEmailAndPassword(auth,email,password).then(user=>{
            
            setCurrentUser(user.user);
            
            user.user.getIdToken(false).then(idToken=>setIdToken(idToken))
            
            setUID(user?.user?.uid);
            setTimeout(history.push('/'),3000)
            
            

        }).catch(err=>{
            alert("email or Password is wrong")
            console.log(err);
        })
       
    }

    const value={
        currentUser,
        
        signIn,
        idToken,
        uid,
        admin,
        setAdmin,
        signOutUser,
        filterByDepartment,
        setFilterByDepartment,
        filterByUser,
        setFilterByUser,
        filterBy,
        setFilterBy,
        manager,
        setManager,
        departmentId,
        setDepartment_Id,
        setEmail,
        email,
        loading,
        setUID,
        processing,
        setProcessing,
        setIdToken
      
    }
    return(
        <AuthContext.Provider value={value}>
            {children}
     
        </AuthContext.Provider>
    )
    

}
