import React from 'react';
import CommonHeaderComponent from '../Dashboard/Objective/CommonHeader.component';
import {
  Line,
  ObjectiveContainer,
  Wrapper,
  ObjectiveModalWrap,
  OverallContainer,
  DepartmentContainer,
} from '../Dashboard/Objective/Objective.style';
import { Meta } from '../Header/Header.style';

const ModalComponent = ({
  modal,
  onClick,
  headerText,
  placeholder,
  OverallComponent,
  DepartmentComponent,
  collab=false
}) => {
  return (
    <>
      <Wrapper modal={modal} >
        <ObjectiveContainer maxWidth="1079px" modal={modal}>
          <CommonHeaderComponent
            headerText={headerText}
            placeholder={placeholder}
            onClick={onClick}
            modal={modal}
          />
          
          <Line />
          <ObjectiveModalWrap>
            <OverallContainer>
              <OverallComponent />
            </OverallContainer>
            <DepartmentContainer style={collab?{justifyContent:"flex-end",position:"relative"}:{}}>
              
              <Meta style={collab?{position:"absolute",top:"0"}:{}} >By Department</Meta>
             
                {/* Graph width of color percentage or pixel recommended percentage for accuracy */}
                <DepartmentComponent  />
              
            </DepartmentContainer>
          </ObjectiveModalWrap>
        </ObjectiveContainer>
      </Wrapper>
    </>
  );
};

export default ModalComponent;
