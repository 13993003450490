import styled from 'styled-components';
import { flexCenter, flexColumn, myFlex } from '../../../../utils/shared.style';

export const TabHeader = styled.div`
  ${myFlex};
  margin-top: 1.5rem;
`;
export const DropDownContainer = styled.div`
  width: 2.3rem;
  height: 2.3rem;
  background-color: ${(props) => props.theme.greyLight2};
  ${myFlex};
  ${flexCenter};
  margin-right: 1rem;
  border-radius: 50%;
`;

export const TabHeaderText = styled.h1`
  font-size: 1.6rem;
  font-weight: 600;
`;

export const TabChildren = styled.div`
  padding: 10px;
  margin-left: 1rem;
  &.node-appear {
    opacity: 0.01;
  }

  &.node-appear-active {
    opacity: 1;
    transition: opacity 500ms ease-out;
  }

  @media (max-width: 500px) {
    margin-left: 0;
    padding: 0;
  }
`;

export const InnerTabContainer = styled.div`
  ${flexColumn};
`;

export const Tab = styled.div`
  ${myFlex};
  flex-direction: ${(props) => (props.col ? 'column' : 'row')};
  justify-content: space-between;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.greyLight2};
  }
`;

export const Icon = styled.div`
  ${myFlex};
  width: 10%;
  ${flexCenter}
  @media (max-width: 400px) {
    ${flexColumn};
    width: fit-content;
  }
`;

export const PanelHeader = styled.div`
  font-size: 1.4rem;
  font-weight:${({fontWeight})=>fontWeight?fontWeight:"500"};
  width: ${(props) => (props.sm ? '10%' : props?.lg ? '30%' : '20%')};
  word-break: break-all;
  padding: 1rem 0;
  ${myFlex};
  align-items: center;
`;

export const IconWrapper = styled.div`
  width:30px;
`;

export const PanelHeaderv2 = styled.div`
  font-size: 1.4rem;
  font-weight:${({fontWeight})=>fontWeight?fontWeight:"500"};
  width:20%;
  word-break: break-all;
  padding: 1rem 0;
  display:flex;
  justify-content:start;
  align-items: center;
`;

export const ProgressBarContainer = styled.div`
  outline: none;
  border: none;
  width: 70%;
  border-radius: 25px;
  padding: 10px;
  color: white;
  font-size: 12px;
  text-align: left;
  background: ${(props) => props.theme.greyLight5};
  position: relative;
`;

export const ProgressText = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  padding: 2px 3px;
  z-index: 200;
`;

export const ProgressPercentage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) => props.width};
  background-color: ${(props) =>{ return props.bg}};

  

  z-index: 100;
  border-radius: 25px;
`;

export const WarningContainer = styled.div`
  border-radius: 50%;
  height: 1.8rem;
  width: 1.76rem;
  ${myFlex};
  ${flexCenter};
  background-color: ${(props) => props.theme.redSecondary};
  margin-right: 1rem;
  position: relative;
  &::before {
    content: 'Objective Past Due Date';
    color: ${(props) => props.theme.redSecondary};
    position: absolute;
    top: -5rem;
    display: none;
    left: 2%;
    padding: 5px 5px 6px 9px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    min-width: 17rem;
  }
  &:hover::before {
    display: inline-block;
  }

  @media (max-width: 400px) {
    height: 1.5rem;
    width: 2rem;
  }
`;

export const CheckInContent=styled.div`
// max-width:400px;
background-color:#fff;
min-height:100vh;
margin-left: auto; 
overflow:hidden;
margin-right: 0px;
padding:3rem 2rem;
position:fixed;
top:50%;
left:100%;

transform:translateX(-100%) translateY(-50%);
overflow-y:scroll;
max-height:95vh;


box-sizing:border-box;
display:flex;
flex-direction:column;

`;
export const CheckInHeader=styled.div`

margin-bottom:15px;

.firstChild{
 display:flex;
 justify-content:space-between;
 font-size:1.5rem;
 font-weight:800;
 align-items:center;

}
.secondChild{
  display:flex;
 justify-content:space-between;
 font-size:1.1rem;
 font-weight:800;

  margin-top:5px;
  padding:0px;
}

`;

export const CheckInBox=styled.div`
  min-height:100px;
  background-color:#045680;
  padding:10px 8px;
  border-radius:5px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  margin-top:1rem;
  div{
    color:#fff;
    font-weight:500;
    font-size:1.2rem;
  }
`
export const CheckInBody=styled.div`
.firstChild{
  display:flex;
  justify-content:space-between;
  margin-top:10px;
  margin-bottom:10px;
  p{
    font-weight:bold;
    margin:0px;
    margin-bottom:12px;
    
  }
  label{
    font-size:1.1rem;
    
  }
  .secondChild{
    margin-top:30px;
  }
}
`
export const CommentCheckInBox=styled.div`
margin-top:auto;
margin-bottom:0;
vertical-align: bottom;


`
export const MileStonesText=styled.div`
  
`
export const Slider=styled.div`
position:relative;

`

export const ShowProgress=styled.span`
position:absolute;
top:50%;
right:0;

`