import React from 'react';
import ActivityLog from '../MyOkrTab/ActivityLog.component';
import KeyResultComponent from './KeyResults.component';
import CommentComponent from './Comment.component';
import useTab from '../../../hooks/useTab';
import { MyOkrHeader } from '../MyOkrHeader/MyOkrHeader.style';
import { Button } from '../NewObjective/NewObjective.style';


const tabs = [KeyResultComponent, ActivityLog];
const tabContent = ['Key Results', 'Activity Log'];
function SingleOkrTabComponent({ allKrs, checkIns, setCheckin }) {

    const { showTabs } = useTab(0, tabContent, tabs,);
    return <MyOkrHeader>{showTabs(allKrs, [], [], [], () => { }, () => { }, [], [], [], { checkIn: checkIns }, setCheckin)}
        {/* <span><Button>+ New Key Result</Button></span> */}
    </MyOkrHeader>

}

export default SingleOkrTabComponent
