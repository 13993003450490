import React, { useState, useEffect } from "react";
import EditComponent from "../../Svgs/Edit.component";
import ListDropdownComponent from "../../Svgs/ListDropdown.component";
import { useAuth } from "../../../context/Auth";
import {
  Header,
  TableHeader,
  InnerTab,
  SmallLine,
} from "./SettingContent.style";
import axios from "axios";
const API = require("../../../api/Api");

const TabComponent = ({ department, type, owner, member, onClick }) => {
  const { idToken, uid } = useAuth();
  const [open, setOpen] = useState(false);
  //const [ownerName, setOwnerName] = useState(null);
  /*useEffect(() => {
    axios.get(`https://s2e.onrender.com/api/v1/department/${department}`, {
      headers: { Authorization: `${idToken}` }
    })
      .then(res => {
        console.log(res.data);
        //setOwnerName(res?.data?.user?.name || null);
      })
      .catch(err => console.log(err));
  }, []);*/
  return (
    <>
      <Header style={{ marginBottom: 0 }}>
        {/*onClick={() => setOpen((state) => !state)}>*/}
        <TableHeader light>
          {/*<ListDropdownComponent
            style={{
              margin: 0,
              transform: `rotate(${open ? '0deg' : '-90deg'})`,
            }}
          />*/}{" "}
          {(department === "Company" ? "CEO" : department) || "No data"}
        </TableHeader>
        {/*<TableHeader light>{type || 'No data'}</TableHeader>*/}
        <TableHeader light>{owner || "No data"}</TableHeader>
        <TableHeader light>{member || 0}</TableHeader>

        <TableHeader light>
          {/*removed temporary<EditComponent />*/}
        </TableHeader>
      </Header>
      {open && (
        <InnerTab>
          <TableHeader light>
            <SmallLine />
            Test
          </TableHeader>
          <TableHeader light>
            <SmallLine /> Test
          </TableHeader>
          <TableHeader light onClick={onClick} className="cursor">
            <SmallLine /> <em>Add new</em>
          </TableHeader>
        </InnerTab>
      )}
    </>
  );
};

export default TabComponent;
