import React, { useContext } from 'react';
import styled from 'styled-components';
import DashboardComponent from '../Svgs/Menu.component';
import { boxShadow, flexCenter, myFlex } from '../../utils/shared.style';
import { ModalContext } from '../../context/Modal.context';

const Cont = styled.div`
  width: 5rem;
  height: 5rem;
  ${myFlex};
  ${flexCenter};
  border-radius: 50%;
  ${boxShadow};
  background-color: #045680;
  position: fixed;
  bottom: 10px;
  right: 10px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MenuComponent = () => {
  const { toggle } = useContext(ModalContext);
  return (
    <Cont onClick={toggle}>
      <DashboardComponent />
    </Cont>
  );
};

export default MenuComponent;
