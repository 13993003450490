import React, { useState, useRef, useEffect } from "react";
import useFullScreen from "../../../hooks/useFullScreen";
import CommonHeaderComponent from "../Objective/CommonHeader.component";
import {
  BarGraphContainer,
  Department,
  DepartmentText,
  Line,
  Wrapper,
  ObjectiveLine,
} from "../Objective/Objective.style";
import { dummyColloboratData } from "../Okr/Tabs/helpers";
import {
  CollaborationContainer,
  IndexText,
  SlideIndicator,
  Slider,
  Text,
} from "./Collaboration.style";
import ColloborationModelComponent from "./ColloborationModel.component";
import {
  OverallCollabDetails,
  DepartmentCollabDetails,
} from "./CollaborationDetails.component";
import { useAuth } from "../../../context/Auth";
import { Bar } from "./Bar";

export const BarGraph = ({
  graphData = [],
  tempDepartmentName = [],
  allCollab = [],
  collaborations = [],
  graphs,
  style = "",
  ismodel = false,
}) => {
  const [activeToolTips, setActiveToolTip] = useState(
    graphData.map(() => false)
  ); //create array booleans
  const [previousActiveBar, setPreviousActiveBar] = useState(null); //store active bar index

  const updateToolTip = (index) => {
    if (previousActiveBar === index) {
      activeToolTips[previousActiveBar] = false;
      setPreviousActiveBar(null);
      return;
    }
    if (previousActiveBar !== null) {
      activeToolTips[previousActiveBar] = false;
    }
    activeToolTips[index] = true;
    setPreviousActiveBar(index);
  };

  return (
    <BarGraphContainer space={style ? style.style : ""}>
      {graphData?.length
        ? graphData.map((graph, index) => {
            return (
              <Bar
                collaboratorIndex={graph}
                departmentName={tempDepartmentName[index]}
                collaborations={collaborations[tempDepartmentName[index]] || {}}
                index={index}
                toolTip={activeToolTips[index]}
                updateToolTip={updateToolTip}
              />
            );
          })
        : ""}
      {!ismodel && graphData.length ? <ObjectiveLine></ObjectiveLine> : ""}
    </BarGraphContainer>
  );
};

const CollaborationComponent = ({
  collabChartData,
  allCollab,
  collabIdx,
  okrs,
  isFilter,
}) => {
  const [modal, setModal] = useFullScreen();
  const [collaborations, setCollaborations] = useState({});
  const { filterByDepartment } = useAuth();

  let tempDepartmentName = collabChartData?.labels;
  let graphData = collabChartData?.data;
  let depts = collabChartData?.depts;

  //group collaboration data
  useEffect(() => {
    const collabs = {};
    allCollab.forEach((collab) => {
      const okr = okrs.find((ok) => ok._id === collab.objID);
      if (!okr) return;
      const data = {
        _id: okr._id,
        deptarment: collabChartData.depts[collab.userdetails2.departmentid],
        title: okr.title,
        owner: collab.userdetails1.name,
        timeframe: okr.timeframe,
        collaborator: collab.userdetails2.name,
        status:
          collab.status === "NA"
            ? "NA"
            : collab.status === "A"
            ? "Accepted"
            : "Rejected",
      };
      const name = depts[collab.userdetails2.departmentid];
      if (!collabs[name]) {
        collabs[name] = [data];
      } else {
        collabs[name].push(data);
      }
    });
    setCollaborations(collabs);
  }, [collabChartData, allCollab, okrs]);

  return (
    <>
      {modal && (
        <ColloborationModelComponent
          collabIdx={collabIdx}
          tempDepartmentName={tempDepartmentName}
          graphData={graphData}
          modal={modal}
          onClick={setModal}
        />
      )}
      <Wrapper flex="0.5">
        <CollaborationContainer
          style={{ display: isFilter ? "flex" : "block" }}
        >
          <CommonHeaderComponent
            placeholder="Show"
            headerText="Collaboration Index"
            onClick={setModal}
            filter={false}
          />

          {isFilter ? (
            <DepartmentCollabDetails
              //collabIdx={isNaN(collabIdx) ? 0 : collabIdx}
              //receivedCollab={allCollab.length}
              /*acceptedCollab={
                allCollab.filter((collab) => collab.status === "A").length
              }*/
              okrs={okrs.filter(okr => okr.departmentid === filterByDepartment.id)}
              allCollab={allCollab}
            />
          ) : (
            <OverallCollabDetails
              collabIdx={isNaN(collabIdx) ? 0 : collabIdx}
              receivedCollab={allCollab.length}
              acceptedCollab={
                allCollab.filter((collab) => collab.status === "A").length
              }
            />
          )}

          {/* Line-Seperator */}
          <Line
            style={{
              marginTop: "15px",
              height: "1px",
              borderTop: "1px solid #DBDBDB",
            }}
          />

          {/* Graph Indicator height-percentage */}
          {isFilter ? (
            ""
          ) : (
            <>
              <BarGraph
                tempDepartmentName={tempDepartmentName}
                allCollab={allCollab}
                graphData={graphData}
                graphs={dummyColloboratData}
                collaborations={collaborations}
              />

              {graphData?.length ? (
                <Line
                  style={{ height: "2px", borderBottom: "1px solid #DBDBDB" }}
                />
              ) : (
                ""
              )}
            </>
          )}
        </CollaborationContainer>
      </Wrapper>
    </>
  );
};

export default CollaborationComponent;
